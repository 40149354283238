import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";
import { OfflineError } from "@/utils/errors";

const {
  innerFetch,
  innerUpdate,
  innerBulkCreate,
  innerBulkDelete,
  innerBulkUpdate,
} = crudGenerator(db.models.SampleFlask);

const SET_IS_LOADING = "SET_IS_LOADING";
const SET_SAMPLE_FLASKS_LIST = "SET_SAMPLE_FLASKS_LIST";
const UPDATE_SAMPLE_FLASK = "UPDATE_SAMPLE_FLASK";
const RESET_SAMPLE_FLASKS = "RESET_SAMPLE_FLASKS";
const BULK_UPDATE_SAMPLE_FLASKS_LIST = "BULK_UPDATE_SAMPLE_FLASKS_LIST";
const SET_CURRENT_INSTALLATIONS_SAMPLE_FLASK =
  "SET_CURRENT_INSTALLATIONS_SAMPLE_FLASK";
const REMOVE_CURRENT_INSTALLATION_FLASKS = "REMOVE_CURRENT_INSTALLATION_FLASKS";
const ADD_CURRENT_INSTALLATIONS_FLASKS = "ADD_CURRENT_INSTALLATIONS_FLASKS";

const state = {
  sampleFlasksList: [],
  sampleFlaskIsLoading: false,
  sampleFlaskForCurrentInstallations: [],
};

const getters = {};

const actions = {
  async fetchSampleFlasks({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },
  async getSampleFlasksByInstallations({ commit }, installationUuids) {
    while (state.sampleFlaskIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    const sampleFlaskList = await db.sampleFlasks
      .where("installation")
      .anyOf(installationUuids)
      .toArray();
    for (let sampleFlask of sampleFlaskList) {
      await sampleFlask.setRelationships();
    }
    commit(SET_CURRENT_INSTALLATIONS_SAMPLE_FLASK, sampleFlaskList);
    return sampleFlaskList;
  },
  async setSampleFlasks({ commit, state }, installationUuid) {
    while (state.sampleFlaskIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit(SET_IS_LOADING, true);
    const sampleFlaskList = await db.sampleFlasks
      .where("installation")
      .equals(installationUuid)
      .toArray();
    for (let sampleFlask of sampleFlaskList) {
      await sampleFlask.setRelationships();
      for (let sample of sampleFlask.samples) {
        await sample.setRelationships();
      }
    }
    commit(SET_SAMPLE_FLASKS_LIST, sampleFlaskList);
    commit(SET_IS_LOADING, false);
    return sampleFlaskList;
  },
  async updateSampleFlask({ commit, rootGetters }, form) {
    commit(SET_IS_LOADING, true);
    const sampleFlask = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(UPDATE_SAMPLE_FLASK, sampleFlask);
    commit(SET_IS_LOADING, false);

    return sampleFlask;
  },
  async bulkCreateSampleFlasks({ rootGetters }, form) {
    if (!window.navigator.onLine) {
      throw new OfflineError();
    }
    return await innerBulkCreate(form, rootGetters["projects/isOfflineState"]);
  },

  async deleteInstallationSampleFlasks({ rootGetters }, installationUuid) {
    if (!window.navigator.onLine) {
      throw new OfflineError();
    }
    const uuids = await db.sampleFlasks
      .where("installation")
      .equals(installationUuid)
      .primaryKeys();
    await innerBulkDelete(
      uuids,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
  },

  async bulkUpdateSampleFlasks({ commit, rootGetters }, form) {
    const sampleFlasks = await innerBulkUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(BULK_UPDATE_SAMPLE_FLASKS_LIST, sampleFlasks);
    return sampleFlasks;
  },
  removeSampleFlaskToCurrentInstallations({ commit }, sampleFlasks) {
    commit(REMOVE_CURRENT_INSTALLATION_FLASKS, sampleFlasks);
  },
  addSampleFlaskToCurrentInstallations({ commit }, sampleFlasks) {
    commit(ADD_CURRENT_INSTALLATIONS_FLASKS, sampleFlasks);
  },
};
const mutations = {
  [SET_SAMPLE_FLASKS_LIST]: (state, samples) => {
    state.sampleFlasksList = samples;
  },
  [UPDATE_SAMPLE_FLASK]: (state, sampleFlask) => {
    const sampleFlaskIndex = state.sampleFlasksList.findIndex(
      ({ uuid }) => sampleFlask.uuid === uuid
    );

    if (sampleFlaskIndex < 0) return;

    state.sampleFlasksList[sampleFlaskIndex] = sampleFlask;
    state.sampleFlasksList = [...state.sampleFlasksList];
  },
  [SET_IS_LOADING]: (state, sampleFlaskIsLoading) => {
    state.sampleFlaskIsLoading = sampleFlaskIsLoading;
  },
  [RESET_SAMPLE_FLASKS]: (state) => {
    state.sampleFlasksList = [];
  },
  [BULK_UPDATE_SAMPLE_FLASKS_LIST]: (state, sampleFlasks) => {
    for (const sampleFlask of sampleFlasks) {
      const sampleFlaskIndex = state.sampleFlasksList.findIndex(
        ({ uuid }) => sampleFlask.uuid === uuid
      );
      if (sampleFlaskIndex < 0) return;
      state.sampleFlaskList[sampleFlaskIndex] = sampleFlask;
    }
    state.sampleFlaskList = [...state.sampleFlaskList];
  },
  [SET_CURRENT_INSTALLATIONS_SAMPLE_FLASK]: (state, sampleFlasksList) => {
    state.sampleFlaskForCurrentInstallations = [...sampleFlasksList];
  },
  [ADD_CURRENT_INSTALLATIONS_FLASKS]: (state, sampleFlaskList) => {
    const toAddSamples = sampleFlaskList.filter(
      (sample) =>
        !state.sampleFlaskForCurrentInstallations.some(
          ({ uuid }) => uuid === sample.uuid
        )
    );
    const flaskList =
      state.sampleFlaskForCurrentInstallations.concat(toAddSamples);
    state.sampleFlaskForCurrentInstallations = [...flaskList];
  },
  [REMOVE_CURRENT_INSTALLATION_FLASKS]: (state, installationUuid) => {
    const flaskList = state.sampleFlaskForCurrentInstallations.filter(
      ({ installation }) => installation !== installationUuid
    );
    state.sampleFlaskForCurrentInstallations = [...flaskList];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
