import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";
import { listAllObjectWithNone } from "@/api/utils/listUtils";
import _ from "lodash";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.VleControllerPromiseClient;

const changeValueFrom0ToNull = (response, result) => {
  if (!response.hasConcentration()) result.concentration = null;
  if (!response.hasConcentrationExpected()) result.concentrationExpected = null;
  if (!response.hasFlow()) result.flow = null;
  if (!response.hasFlowTonProduced()) result.flowTonProduced = null;
  if (!response.hasFlow1()) result.flow1 = null;
  if (!response.hasFlowTonProduced1()) result.flowTonProduced1 = null;
  if (!response.hasFlow2()) result.flow2 = null;
  if (!response.hasFlowTonProduced2()) result.flowTonProduced2 = null;
  if (!response.hasFlow3()) result.flow3 = null;
  if (!response.hasFlowTonProduced3()) result.flowTonProduced3 = null;
  if (!response.hasFlow4()) result.flow4 = null;
  if (!response.hasFlowTonProduced4()) result.flowTonProduced3 = null;
  if (!response.hasFlowExpected()) result.flowExpected = null;
  if (!response.hasSpecificCorrection()) result.specificCorrection = null;
  if (!response.hasComplementary()) result.complementary = null;
  return result;
};

export const VleClient = {
  list: async (filters) => {
    console.log("Called list of VleClient");
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.VleListRequest();
    const response = await listAllObjectWithNone(
      grpcClient,
      request,
      metadata,
      changeValueFrom0ToNull
    );

    // INFO - B.L - listAllObjectWithNone duplicates some vle, the code is too complex to be fix RN.
    return _.uniqBy(response, "uuid");
  },
  create: async (vle) => {
    console.log("Called create of VleClient");
    const request = socioGrpcClient.javascriptToRequest(
      requests.VleRequest,
      vle
    );
    const response = await grpcClient.create(request, {});
    let resultObject = response.toObject();
    resultObject = changeValueFrom0ToNull(response, resultObject);

    return resultObject;
  },
  bulkCreate: async (vles) => {
    console.log("Called list bulkCreate VleClient");
    const request = new requests.VleBulkCreateRequest();

    for (const vle of vles) {
      const vleRequest = socioGrpcClient.javascriptToRequest(
        requests.VleRequest,
        vle
      );
      request.addVles(vleRequest);
    }

    const response = await grpcClient.bulkCreate(request, {});
    const resultsList = response.getResultsList().map((res) => {
      let resultObject;
      resultObject = { ...res.toObject() };
      resultObject = changeValueFrom0ToNull(res, resultObject);
      return resultObject;
    });

    return resultsList;
  },
  update: async (vle) => {
    console.log("Called update of VleClient");
    const request = socioGrpcClient.javascriptToRequest(
      requests.VleRequest,
      vle
    );
    const response = await grpcClient.update(request, {});
    let resultObject = response.toObject();
    resultObject = changeValueFrom0ToNull(response, resultObject);

    return resultObject;
  },
  bulkUpdate: async (vles) => {
    console.log("Called bulkUpdate of VleClient");
    const request = new requests.VleBulkUpdateRequest();

    for (const vle of vles) {
      const vleRequest = socioGrpcClient.javascriptToRequest(
        requests.VleRequest,
        vle
      );
      request.addVles(vleRequest);
    }

    const response = await grpcClient.bulkUpdate(request, {});
    const resultsList = response.getResultsList().map((res) => {
      let resultObject;
      resultObject = { ...res.toObject() };
      resultObject = changeValueFrom0ToNull(res, resultObject);
      return resultObject;
    });

    return resultsList;
  },
};
