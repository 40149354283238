import BaseSchema from "./base";

class Installation extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "installations";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "configurationLines",

    "name",
    "project",
    "parameters",
    "resultsExpression",

    "sampleBaseName",
    "rejectType",
    "businessTypeIcpeField",
    "shortDescription",
    "smokeProcessing",
    "productsType",
    "additionalInfo",
    "process",
    "hasNoCombustion",
    "processCycleValue",
    "poweringType",
    "poweringCharge",
    "workingConditionDuringTest",
    "operatingHoursPerDay",
    "operatingDaysPerYear",

    "homogeneityEvaluationType",
    "tracerGas",
    "homogeneityCheckDate",
    "homogeneityCheckBy",
    "rapportReference",
    "homogeneityCheckResult",
    "homogeneityCheckResultComments",
    "homogenousSectionType",
    "noVerificationPossibleDescription",
    "evaluationGaseousEffluentHomogeneity",
    "particlesSamplingStrategy",
    "gasSamplingStrategy",
    "commentsSamplingStrategy",
    "customSentenceEffluentHomogeneity",
    "customSentenceParticlesSamplingStrategy",
    "customSentenceGasSamplingStrategy",
    "packagingType",

    "oxygenOrCarbonDioxideCorrections",
    "correctionType",
    "referenceLevel",
    "correctionMode",
    "sameOutletShape",
    "incinerationRegulation",
    "samplingPointShape",
    "samplingPointInternalDiameter",
    "samplingPointWidth",
    "samplingPointHeight",
    "outletShape",
    "outletInternalDiameter",
    "outletWidth",
    "outletHeight",
    "chimneyHeight",
    "chimneyOrientation",
    "installationPicturesList",

    "platformDistanceFromGround",
    "platformDistanceFromSamplingPort",
    "accessType",
    "explorableAxes",
    "axesNumber",
    "clearanceZone",
    "upstreamLengthWithoutAccident",
    "downstreamLengthWithoutAccident",
    "baseboards",
    "standardizedHatches",
    "pitotProbe",
    "flowCalculated",
    "workSurface",
    "handrails",
    "transportForMaterial",

    "highDynamicPressure",
    "noGyration",
    "speedRationGreaterThanThree",
    "commentsFlow",

    "easyAccess",
    "rainAndHeatProtection",
    "workZoneProtection",
    "gasEmittingZonesSealed",
    "commentsSafety",
    "o2ExpectedContent",
    "co2ExpectedContent",
    "h2oExpectedContent",
    "isActive",
  ];

  static relatedFields = {
    configurationLines: {
      many: true,
      reversedKey: "installation",
      tableName: "configurationLines",
      cascade: true,
    },
    phases: {
      many: true,
      reversedKey: "installation",
      tableName: "phases",
      cascade: true,
    },
    sums: {
      many: true,
      reversedKey: "installation",
      tableName: "sums",
      cascade: true,
    },
    sampleFlasks: {
      many: true,
      reversedKey: "installation",
      tableName: "sampleFlasks",
      cascade: true,
    },
    recordBooks: {
      many: true,
      reversedKey: "installation",
      tableName: "recordBooks",
      cascade: true,
    },
  };

  static dateFields = ["createdAt", "updatedAt"];
}

export default Installation;
