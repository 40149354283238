export default {
  GENERATING: "GENERATING",
  READY: "READY",
  SENT: "SENT",
  ERROR: "ERROR",
  TEMPORARY: "TEMPORARY",
  NOT_VALIDATED: "NOT_VALIDATED",
  INVALID_DATA: "INVALID_DATA",
  GENERATION_FAILED: "GENERATION_FAILED",
  UPLOAD_FAILED: "UPLOAD_FAILED",
  SUCCESS: "SUCCESS",
};
