import IdentityChildren from "@/router/atmo/projects/project/identity/children";
import InstallationChildren from "@/router/atmo/projects/project/installations/children";
import ReportChildren from "@/router/atmo/projects/project/reports/children";
import DocumentChildren from "@/router/atmo/projects/project/documents/children";
import ContributorChildren from "@/router/atmo/projects/project/contributors/children";
import SamplesChildren from "@/router/atmo/projects/project/samples/children";
import MeasurementPlan from "@/router/atmo/projects/project/measurement-plan/children";
import RecordBooksChildren from "@/router/atmo/projects/project/recordbooks/children";
import ConfigChildren from "@/router/atmo/projects/project/config/children";

const ProjectIdentity = () =>
  import(
    /* webpackChunkName: "ProjectIdentity" */ "@/components/atmo/projects/project/ProjectIdentity"
  );

const ProjectInstallations = () =>
  import(
    /* webpackChunkName "ProjectInstallations" */ "@/components/atmo/projects/project/ProjectInstallations"
  );

const ProjectMeasurementPlan = () =>
  import(
    /* webpackChunkName: "ProjectMeasurementPlan" */ "@/components/atmo/projects/project/ProjectMeasurementPlan"
  );

const ProjectSamples = () =>
  import(
    /* webpackChunkName: "ProjectSamples" */ "@/components/atmo/projects/project/ProjectSamples"
  );

const ProjectReports = () =>
  import(
    /* webpackChunkName: "ProjectReports" */ "@/components/atmo/projects/project/ProjectReports"
  );

const ProjectDocuments = () =>
  import(
    /* webpackChunkName: "ProjectDocuments" */ "@/components/atmo/projects/project/ProjectDocuments"
  );

const ProjectContributors = () =>
  import(
    /* webpackChunkName: "ProjectContributors" */ "@/components/atmo/projects/project/ProjectContributors"
  );

const ProjectConfig = () =>
  import(
    /* webpackChunkName: "ProjectConfig" */ "@/components/atmo/projects/project/ProjectConfig"
  );
const ProjectRecordBooks = () =>
  import(
    /* webpackChunkName: "ProjectConfig" */ "@/components/atmo/projects/project/ProjectRecordBooks"
  );

export default [
  {
    path: "",
    name: "project",
    redirect: { name: "project-identity" },
  },
  {
    path: "identity",
    component: ProjectIdentity,
    children: IdentityChildren,
    meta: {
      subNavs: "identity",
    },
  },
  {
    path: "installations",
    component: ProjectInstallations,
    children: InstallationChildren,
  },
  {
    path: "measurement-plan",
    component: ProjectMeasurementPlan,
    children: MeasurementPlan,
  },
  {
    path: "samples",
    component: ProjectSamples,
    children: SamplesChildren,
  },
  {
    path: "reports",
    component: ProjectReports,
    children: ReportChildren,
  },
  {
    path: "documents",
    component: ProjectDocuments,
    children: DocumentChildren,
  },
  {
    path: "contributors",
    component: ProjectContributors,
    children: ContributorChildren,
  },
  {
    path: "config",
    component: ProjectConfig,
    children: ConfigChildren,
  },
  {
    path: "recordbooks",
    children: RecordBooksChildren,
    component: ProjectRecordBooks,
  },
];
