import BaseSchema from "./base";

class Parameter extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "parameters";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "name",
    "measurement",
    "line",
    "underAccreditation",
    "makingRinse",
    "makingMock",
    "makingYield",
    "sampleCount",
    "bubblerQuantificationLimit",
    "filterQuantificationLimit",
    "rinseQuantificationLimit",
    "molarMassCorrection",
    "sampleDuration",
    "bubblerQuantificationLimitUnitFactor",
    "filterQuantificationLimitUnitFactor",
    "iteqOms2005",
    "iteqOtan",
    "iteqOms2006",
    "categoriesList",
    "mutualisationsList",
    "preselectionsList",
    "isMutualised",
    "hasMarker",
    "constantFlow",
    "support",
    "sumPreselectionsList",
    "glassFilteringTemperature",
    "titaniumFilteringTemperature",
    "sampleShortName",
    "order",
    "eurofinsCodes",
    "eurofinsUnit",
    "isQal2AstParameter",
  ];

  static relatedFields = {
    configurationLines: {
      many: true,
      reversedKey: "parameter",
      tableName: "configurationLines",
    },
  };

  static dateFields = ["createdAt", "updatedAt"];

  getFilteringTemperature(pitotMaterial, dewPoint) {
    let temp = "0";
    if (pitotMaterial == "GLASS") {
      temp = this.glassFilteringTemperature;
    } else {
      temp = this.titaniumFilteringTemperature;
    }
    temp = temp.replaceAll(" ", "");
    if (!temp) return 0;
    if (temp == "R") {
      return dewPoint;
    }
    if (temp.includes("R+")) {
      const surplus = parseInt(temp.replace("R+", ""));
      return dewPoint + surplus;
    }

    return parseInt(temp);
  }
}

export default Parameter;
