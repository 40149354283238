import google_protobuf_struct_pb from "google-protobuf/google/protobuf/struct_pb";
import { camelCase } from "lodash";

export const structToJsObj = (response, flatten = false) => {
  let responseObject = response.toObject();
  for (const key in responseObject) {
    if (
      responseObject[key] instanceof Object &&
      !Array.isArray(responseObject[key])
    ) {
      const methodName = key.charAt(0).toUpperCase() + key.slice(1);
      const fieldToJsObj = response["get" + methodName]().toJavaScript();
      responseObject[key] = fieldToJsObj;
    }
  }

  if (flatten === true) {
    responseObject = flattenObject(responseObject);
  }

  return responseObject;
};

// INFO - M.S - 20/05/2021 - flatten the responseObject
// example :
//
// {             {
//   a:2,         a:2,
//   b: {   ==>   c:3
//     c:3       }
//   }
// }
const flattenObject = (obj) => {
  const flattened = {};
  Object.keys(obj).forEach((key) => {
    if (!Array.isArray(obj[key]) && obj[key] instanceof Object) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const snakeToCamel = (data, flatten) => {
  if (!data) {
    return;
  }
  if (flatten === "flatten") {
    data = flattenObject(data);
  }

  Object.keys(data).forEach((key) => {
    if (key.includes("_")) {
      let newKey = key.replace(/([_][a-z])/g, (match) =>
        match.toUpperCase().replace("_", "")
      );
      Object.assign(data, { [newKey]: data[key] });
      delete data[key];
    }
  });
  return data;
};

export const renameKeys = (keysMap, obj) => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );
};

export const removeUndefinedFields = (object) => {
  Object.keys(object).forEach((key) =>
    object[key] === undefined ? delete object[key] : {}
  );
  return object;
};

export const responseTojavascript = (
  response,
  keysMap = {},
  resultField = "resultsList"
) => {
  const jsData = response.toObject() || {};
  if (Object.prototype.hasOwnProperty.call(jsData, resultField)) {
    const data = jsData[resultField].map((item) => renameKeys(keysMap, item));
    const count = jsData.count;
    return { data, count };
  }
  return { data: renameKeys(keysMap, jsData) };
};

export const buildProtoMessageFromList = (
  objectList,
  protoBuilderFunc,
  objectClass,
  fieldsToExlude
) => {
  const requestList = objectList?.map((item) =>
    protoBuilderFunc(objectClass, item, fieldsToExlude)
  );
  return requestList;
};

export const objToProtoStruct = (obj) => {
  return new google_protobuf_struct_pb.Struct.fromJavaScript(obj);
};

export const camelizeKeys = (obj, deep = true) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v, deep));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      if (deep)
        return {
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        };
      else
        return {
          ...result,
          [camelCase(key)]: obj[key],
        };
    }, {});
  }
  return obj;
};
