const nozzleDiameterRecipe = {
  type: "nozzle_diameter",
  recordBlocks: [
    {
      label: "NOZZLE_DIAMETER_CALCULATIONS_INFORMATION",
      type: "nozzle_diameter_calculations_information_form",
      recordForm: {
        atmosphericPressure: null,
        temperatureEffluent: null,
        vitesseGaz: null,
        temperatureLignePrincipale: null,
        temperatureLigneSecondaire: null,
        pStatique: null,
        pCompteurLignePrincipale: null,
        debitLignePrincipale: null,
        debitLigneSecondaire: null,
        isValidated: false,
      },
      recordEvents: [],
    },
    {
      label: "NOZZLE_DIAMETER_CALCULATION",
      type: "nozzle_diameter_calculation_form",
      recordForm: {
        nozzleDiameter: null,
        isValidated: false,
      },
      recordEvents: [],
    },
  ],
  premix: [],
};

const nozzleDiameterRecipeMessages = {
  fr: {
    "nozzle_diameter.nozzle_diameter_calculations_information_form":
      "Informations préalables aux calcul du diamètre de buse",
    "nozzle_diameter.block.nozzle_diameter_calculations_information_form":
      "Informations préalables aux calcul du diamètre de buse",
    "nozzle_diameter.nozzle_diameter_calculation_form":
      "Calcul du diamètre de buse",
    "nozzle_diameter.block.nozzle_diameter_calculation_form":
      "Calcul du diamètre de buse",
  },
  en: {
    "nozzle_diameter.nozzle_diameter_calculations_information_form":
      "Information for nozzle diameter calculations",
    "nozzle_diameter.block.nozzle_diameter_calculations_information_form":
      "Information for nozzle diameter calculations",
    "nozzle_diameter.nozzle_diameter_calculation_form":
      "Nozzle diameter calculation",
    "nozzle_diameter.block.nozzle_diameter_calculation_form":
      "Nozzle diameter calculation",
  },
};

export { nozzleDiameterRecipe, nozzleDiameterRecipeMessages };
