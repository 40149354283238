import BaseSchema from "./base";

class Role extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "roles";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "name",
    "roleType",
    "serviceId",
  ];

  static relatedFields = {};

  static dateFields = ["createdAt", "updatedAt"];
}

export default Role;
