import snakecaseKeys from "snakecase-keys";

import { filtersToGet } from "@/utils/metadata";
import { camelizeKeys } from "@/utils/utilsGrpcRest";
import { atmoClient } from "@/setup/atmoClient";

const MeasurementFormClient = {
  list: async (filters) => {
    let url = "measurementforms/";
    if (Object.keys(filters)) {
      url += filtersToGet(filters);
    }
    const response = await atmoClient.get(url);
    return camelizeKeys(response.data, false);
  },
  create: async (meadurementForm) => {
    const response = await atmoClient.post(
      "measurementforms/",
      snakecaseKeys(meadurementForm, { deep: false })
    );
    return camelizeKeys(response.data, false);
  },

  update: async (meadurementForm) => {
    const response = await atmoClient.put(
      `measurementforms/${meadurementForm.uuid}/`,
      snakecaseKeys(meadurementForm, { deep: false })
    );
    return camelizeKeys(response.data, false);
  },

  destroy: async (uuid) => {
    console.log("delete " + uuid);
  },
};

export default MeasurementFormClient;
