import db from "@/db";
import store from "@/store";
import crudGenerator from "@/api/utils/offlineSync";

const { innerFetch, innerCreate, innerUpdate, innerDelete } = crudGenerator(
  db.models.Sum
);

const SET_SUMS = "SET_SUMS";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_TOTAL_SUMS = "SET_TOTAL_SUMS";
const UPDATE_TOTAL_SUMS = "UPDATE_TOTAL_SUMS";
const ADD_SUM = "ADD_SUM";
const UPDATE_SUM = "UPDATE_SUM";
const DELETE_SUM = "DELETE_SUM";

const UPDATE_CURRENT_SUM = "UPDATE_CURRENT_SUM";
const ADD_PARAMETER_TO_CURRENT_SUM = "ADD_PARAMETER_TO_CURRENT_SUM";
const DELETE_PARAMETER_FROM_CURRENT_SUM = "DELETE_PARAMETER_FROM_CURRENT_SUM";

const state = {
  sumList: [],
  totalSum: 0,
  sumIsLoading: false,
  currentSum: {}, // Object  { param1Id: param1, param2Id: param2 ....}
};

const getters = {};

const actions = {
  async fetchSums({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },

  async setSums({ commit, state }, installationUuid) {
    while (state.sumIsLoading) await new Promise((r) => setTimeout(r, 200));
    commit(SET_IS_LOADING, true);
    const sumList = await db.sums
      .where("installation")
      .equals(installationUuid)
      .sortBy("createdAt");
    for (let sum of sumList) {
      await sum.setRelationships();
    }
    commit(SET_SUMS, sumList);
    commit(SET_TOTAL_SUMS, sumList.length);
    commit(SET_IS_LOADING, false);
  },

  async createSum({ commit, rootGetters }, form) {
    const sum = await innerCreate(form, rootGetters["projects/isOfflineState"]);
    await store.dispatch("installations/refreshLastUpdated");
    commit(ADD_SUM, sum);
    commit(UPDATE_TOTAL_SUMS, 1);
    return sum;
  },

  async updateSum({ commit, rootGetters }, form) {
    const sum = await innerUpdate(form, rootGetters["projects/isOfflineState"]);
    await store.dispatch("installations/refreshLastUpdated");
    commit(UPDATE_SUM, sum);
    return sum;
  },

  async deleteSum({ commit, rootGetters }, sum) {
    await innerDelete(
      sum.uuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    await store.dispatch("installations/refreshLastUpdated");

    commit(DELETE_SUM, sum);
  },
  updateCurrentSum({ commit }, currentSum) {
    commit(UPDATE_CURRENT_SUM, currentSum);
  },
  addParameterToCurrentSum({ commit }, parameter) {
    commit(ADD_PARAMETER_TO_CURRENT_SUM, parameter);
  },
  deleteParameterFromCurrentSum({ commit }, parameter) {
    commit(DELETE_PARAMETER_FROM_CURRENT_SUM, parameter);
  },
};

const mutations = {
  [SET_SUMS]: (state, sumList) => {
    state.sumList = sumList;
  },
  [SET_IS_LOADING]: (state, sumIsLoading) => {
    state.sumIsLoading = sumIsLoading;
  },
  [SET_TOTAL_SUMS](state, totalSums) {
    state.totalSums = totalSums;
  },
  [UPDATE_TOTAL_SUMS](state, amount) {
    state.totalSums += amount;
  },
  [ADD_SUM]: (state, sum) => {
    state.sumList.push(sum);
  },
  [UPDATE_SUM]: (state, sum) => {
    const sumIndex = state.sumList.findIndex(({ uuid }) => sum.uuid == uuid);
    if (sumIndex < 0) return;

    state.sumList[sumIndex] = sum;
  },
  [DELETE_SUM]: (state, sumToDelete) => {
    state.sumList = state.sumList.filter(
      (sum) => sum.uuid !== sumToDelete.uuid
    );
  },
  [UPDATE_CURRENT_SUM]: (state, currentSum) => {
    state.currentSum = currentSum;
  },
  [ADD_PARAMETER_TO_CURRENT_SUM]: (state, parameter) => {
    state.currentSum[parameter.uuid] = parameter;
    state.currentSum = {
      ...state.currentSum,
    };
  },
  [DELETE_PARAMETER_FROM_CURRENT_SUM]: (state, parameter) => {
    delete state.currentSum[parameter.uuid];
    state.currentSum = {
      ...state.currentSum,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
