import store from "@/store";
import { VUE_APP_ATMO_SERVICE_ID } from "@/env";
import { multiGuard } from "@/utils/router";

const SampleFlasks = () =>
  import(
    /* webpackChunkName: "Samples" */ "@/components/atmo/projects/project/samples/SampleFlasks"
  );

const fetchReportTemplates = async (to, from, next) => {
  store.dispatch(
    "reportTemplates/fetchReportTemplates",
    VUE_APP_ATMO_SERVICE_ID
  );
  next();
};

export default [
  {
    path: "",
    name: "project-samples",
    component: SampleFlasks,
    beforeEnter: multiGuard([fetchReportTemplates]),
  },
];
