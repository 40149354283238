import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

import atmoRoutes from "@/router/atmo";
import atmoMobileRoutes from "@/router/atmo-mobile";

Vue.use(VueRouter);

const routes =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? atmoMobileRoutes
    : atmoRoutes;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    const middleware = await vuexOidcCreateRouterMiddleware(store, "oidc");
    return middleware(to, from, next);
  }
  next();
});

export default router;
