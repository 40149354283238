import BaseSchema from "./base";

class CompanyProjectRole extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "companyProjectRoles";
  static fields = [
    "uuid",
    "projectId",
    "batchCompanyProjectRoleList",
    "batchesList",
    "batches",
    "project",
    "roleId",
    "role",
    "roleAlias",
    "workDone",
    "projectedStaffing",
    "isArchived",
  ];

  static relatedFields = {
    role: {
      field: "roleId",
      dataName: "role",
      tableName: "roles",
    },
    project: {
      field: "projectId",
      dataName: "project",
      tableName: "projects",
    },
  };

  static dateFields = ["createdAt", "updatedAt"];
}

export default CompanyProjectRole;
