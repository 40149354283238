import _ from "lodash";
import db from "@/db";

import useInstallationVle from "@/composables/installations/vles/useInstallationVle";

const { getDewPoint } = useInstallationVle();

const findParametersUsed = async (phase) => {
  const samplesUsed = await db.samples.where("phase").equals(phase).toArray();
  const parameterUsedUuids = _.flatten(
    samplesUsed.map((p) => [...p.mainParametersList, p.secondParameter])
  ).filter((uuid) => !!uuid);

  return await db.parameters.where("uuid").anyOf(parameterUsedUuids).toArray();
};

const findVleUsed = async (
  installation,
  { phase = null, parametersUsed = null }
) => {
  if (!parametersUsed) {
    parametersUsed = await findParametersUsed(phase);
  }
  const parameterUsedUuids = parametersUsed.map(({ uuid }) => uuid);

  const mtxIsUsed = parametersUsed.some((p) =>
    ["MtxP", "MtxG"].includes(p.sampleShortName)
  );

  const configurationLineUuids = await db.configurationLines
    .where("parameter")
    .anyOf(parameterUsedUuids)
    .and((cl) => cl.installation == installation)
    .primaryKeys();

  let vlesConfig = await db.vles
    .where("configurationLine")
    .anyOf(configurationLineUuids)
    .toArray();

  let vlesSums = [];
  if (mtxIsUsed) {
    const sums = await db.sums
      .where("installation")
      .equals(installation)
      .primaryKeys();

    vlesSums = await db.vles.where("sum").anyOf(sums).toArray();
  }

  return [...vlesConfig, ...vlesSums];
};

const findFlows = async (installation, phase) => {
  const vles = await findVleUsed(installation, { phase });
  const vleUuids = vles.map(({ uuid }) => uuid);
  return await db.flows.where("vle").anyOf(vleUuids).toArray();
};

const findMaxDuration = async (installation, phase) => {
  const parametersUsed = await findParametersUsed(phase);
  const vles = await findVleUsed(installation, { parametersUsed });
  const vleMaxDuration = _.maxBy(vles, "samplingDuration")?.samplingDuration;
  const parameterMaxDuration = _.maxBy(
    parametersUsed,
    "sampleDuration"
  )?.sampleDuration;

  return vleMaxDuration > parameterMaxDuration
    ? vleMaxDuration
    : parameterMaxDuration;
};

const getFilterTemperature = async (installationUuid, phase, tubeMaterial) => {
  const installation = await db.installations.get(installationUuid);
  const h2oExpectedContent = installation.h2oExpectedContent;
  let dewPoint = 0;
  if (h2oExpectedContent) dewPoint = getDewPoint(h2oExpectedContent);
  const parameters = await findParametersUsed(phase);

  return Math.max(
    ...parameters.map((p) => p.getFilteringTemperature(tubeMaterial, dewPoint))
  );
};

const manualSamplingRecipe = {
  type: "manual_sampling",
  recordBlocks: [
    {
      label: "INIT",
      recordEvents: [],
      type: "manual_sampling_general_informations_form",
      recordForm: {
        samplingDuration: null,
        amoniumSalt: false,
        tubeMaterial: "GLASS",
        realTemperature: 1,
        theoricTemperature: 0,
        isValidated: false,
        filtrationMode: false,
      },
    },
    {
      label: "MATERIAL",
      recordEvents: [],
      type: "manual_sampling_material_form",
      recordForm: {
        meters: null,
        thermocouple: null,
        pitotTube: null,
        buse: null,
        realBuseDiameter: 0,
        realRateOfFlow: 0,
        isValidated: false,
      },
    },
    {
      label: "SAMPLING",
      recordEvents: [],
      type: "manual_sampling_notebook_form",
      recordForm: {
        initialSample: null,
        initialSampleMass: 0,
        finalSample: null,
        finalSampleMass: 0,
        isValidated: false,
      },
    },
  ],
  formFieldToEvent: {
    interventionDate: { type: "INTERVENTION_DATE", unique: true },
    samplingDuration: { type: "DURATION", unique: true },
    amoniumSalt: { type: "AMONIUM_SALT", unique: true },
    tubeMaterial: { type: "TUBE", unique: true },
    realTemperature: { type: "TEMPERATURE", unique: true },
  },
  premix: [
    {
      label: "INIT",
      keys: [
        "recordEvents",
        "recordForm.samplingDuration",
        "recordForm.amoniumSalt",
        "recordForm.tubeMaterial",
        "recordForm.realTemperature",
        "recordForm.isValidated",
      ],
    },
    {
      label: "MATERIAL",
      keys: [
        "recordEvents",
        "recordForm.pumpOrMeter",
        "recordForm.thermocouple",
        "recordForm.pitotTube",
        "recordForm.realBuseDiameter",
        "recordForm.realRateOfFlow",
        "recordForm.isValidated",
      ],
    },
  ],
};

const manualSamplingRecipeMessages = {
  fr: {
    "manual_sampling.manual_sampling_general_informations_form":
      "Informations générales",
    "manual_sampling.manual_sampling_material_form": "Matériels",
    "manual_sampling.manual_sampling_notebook_form": "Recueil des essais",
    "manual_sampling.block.manual_sampling_general_informations_form":
      "Initialisation",
    "manual_sampling.block.manual_sampling_material_form": "Choix du matériel",
    "manual_sampling.block.manual_sampling_notebook_form": "Mesures",
  },
  en: {
    "manual_sampling.manual_sampling_general_informations_form":
      "General informations",
    "manual_sampling.manual_sampling_material_form": "Materials",
    "manual_sampling.manual_sampling_notebook_form": "Sampling's notebook",
    "manual_sampling.block.manual_sampling_general_informations_form":
      "Initialisation",
    "manual_sampling.block.manual_sampling_material_form": "Materials pick",
    "manual_sampling.block.manual_sampling_notebook_form": "Samplings",
  },
};

export {
  manualSamplingRecipe,
  manualSamplingRecipeMessages,
  getFilterTemperature,
  findMaxDuration,
  findVleUsed,
  findFlows,
};
