import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.SampleFlaskControllerPromiseClient;

export const SampleFlaskClient = {
  create: async (sampleFlask) => {
    console.log("Called create on Sample Flask Client");
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleFlaskRequest,
      sampleFlask
    );
    const response = await grpcClient.create(request, {});

    return response.toObject();
  },
  list: async (filters) => {
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.SampleFlaskListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);

    return response;
  },
  update: async (sampleFlask) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleFlaskRequest,
      sampleFlask
    );
    const response = await grpcClient.update(request, {});

    return response.toObject();
  },
  bulkCreate: async (sampleFlasks) => {
    const request = new requests.SampleFlaskBulkCreateRequest();

    for (const sampleFlask of sampleFlasks) {
      const sampleFlaskRequest = socioGrpcClient.javascriptToRequest(
        requests.SampleFlaskRequest,
        sampleFlask
      );

      request.addSampleFlasks(sampleFlaskRequest);
    }

    const response = await grpcClient.bulkCreate(request, {});

    return response.toObject().resultsList;
  },
  deleteInstallationSampleFlasks: async (installationUuid) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleFlaskDeleteInstallationSampleFlasksRequest,
      {
        installation: installationUuid,
      }
    );

    await grpcClient.deleteInstallationSampleFlasks(request, {});
  },
  bulkDestroy: async (uuids) => {
    const data = {
      uuids,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleFlaskBulkDestroyRequest,
      data
    );
    const response = await grpcClient.bulkDestroy(request, {});
    return response.toObject();
  },
  bulkUpdate: async (sampleFlasks) => {
    const request = new requests.SampleFlaskBulkUpdateRequest();

    for (const sampleFlask of sampleFlasks) {
      const sampleFlaskRequest = socioGrpcClient.javascriptToRequest(
        requests.SampleFlaskRequest,
        sampleFlask
      );
      request.addSampleFlasks(sampleFlaskRequest);
    }

    const response = await grpcClient.bulkUpdate(request, {});

    return response.toObject().resultsList;
  },
  destroy: async (sampleUuid) => {
    const data = {
      uuid: sampleUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleFlaskDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});

    return response.toObject();
  },
};
