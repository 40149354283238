import BaseSchema from "./base";

class AnalysisResult extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "analysisResult";
  static fields = [
    "uuid",
    "orderForm",
    "parameter",
    "sampleFlask",
    "sampleFlaskObj",
    "testCode",
    "parameterCode",
    "limitOfQuantification",
    "value",
    "limitOfDetection",
    "uncertainty",
    "unit",
    "volumeReceivedByLaboratory",
  ];

  static relatedFields = {
    sampleFlaskObj: {
      field: "sampleFlask",
      tableName: "sampleFlasks",
    },
  };
}

export default AnalysisResult;
