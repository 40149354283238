import Vue from "vue";
import App from "./App.vue";
import PackageJson from "../package.json";
import SocioManifest from "../socio-manifest.json";
import { initializeTracingAndLogging } from "@socotec.io/socio-vue-components";
initializeTracingAndLogging(SocioManifest, PackageJson, {
  useDocumentLoadInstrumentation: false,
});
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import SocioVueComponents from "@socotec.io/socio-vue-components";
import VueSignaturePad from "vue-signature-pad";
import workbox from "./registerServiceWorker";
import db from "./db";
import VueOffline from "vue-offline";
import "dayjs/locale/fr";

Vue.config.productionTip = false;

// Global declarations
Vue.prototype.$workbox = workbox;
Vue.prototype.$db = db;
window.db = db;
console.log("----DEXIE DB-------");
console.log(db);
console.log("-------------------");
Vue.prototype.$isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

store.setInitialStates();

Vue.use(SocioVueComponents);
Vue.use(VueSignaturePad);
Vue.use(VueOffline, {
  storage: false,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
