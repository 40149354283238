export const buildQueryset = async (
  dexieTable,
  queryAsObject,
  ordering,
  filterMapping,
  postFetchSorting = false
) => {
  const pageSize = queryAsObject.page_size || 10;
  const page = (queryAsObject.page ? queryAsObject.page : 1) - 1;
  let offset = 0;
  const reverse = ordering.startsWith("-");
  const newOrdering = ordering.replace("-", "");
  let queryset = dexieTable.orderBy(newOrdering);

  if (page && !postFetchSorting) offset = page * pageSize;
  if (reverse) queryset = queryset.reverse();

  // INFO - B.L - 04/09/2022 - filtering
  Object.entries(queryAsObject).forEach(([key, value]) => {
    if (filterMapping[key]) {
      queryset = filterMapping[key](queryset, value);
    }
  });

  // INFO - B.L - 06/09/2022 - Call count before we do the offset to have the real total count
  const count = await queryset.count();
  if (offset && !postFetchSorting) queryset = queryset.offset(offset);

  if (pageSize && !postFetchSorting) queryset = queryset.limit(pageSize);

  return { queryset, count };
};
