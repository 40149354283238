import store from "@/store";
import AdminChildren from "@/router/atmo/admin/children";
import ProjectsChildren from "@/router/atmo/projects/children";
import CasesChildren from "@/router/atmo/cases/children";
import MaterialsChildren from "@/router/atmo/materials/children";

const Cases = () =>
  import(/* webpackChunkName: "Cases" */ "@/components/atmo/Cases");

const Projects = () =>
  import(/* webpackChunkName: "Projects" */ "@/components/atmo/Projects");

const Materials = () =>
  import(/* webpackChunkName: "Materials" */ "@/components/atmo/Materials");

const EurofinsCatalog = () =>
  import(
    /* webpackChunkName: "EurofinsCatalog" */ "@/components/atmo/EurofinsCatalog"
  );

const Admin = () =>
  import(/* webpackChunkName: "Admin" */ "@/components/atmo/Admin");

const MyProfile = () =>
  import(/* webpackChunkName: "MyProfile" */ "@/components/atmo/MyProfile");

const fetchAdminInformation = async (to, from, next) => {
  await store.dispatch("adminInformations/fetchAdminInformation");
  next();
};

export default [
  {
    path: "",
    name: "home",
    redirect: { name: "cases" },
  },
  {
    path: "cases",
    component: Cases,
    children: CasesChildren,
  },
  {
    path: "projects",
    component: Projects,
    children: ProjectsChildren,
    beforeEnter: async (to, from, next) => {
      // INFO - B.L - 02/09/2022 - avoid fetching for children
      if (to.name === "projects") {
        store.dispatch("projects/fetchProjects");
      }
      next();
    },
  },
  {
    path: "materials",
    component: Materials,
    children: MaterialsChildren,
  },
  {
    path: "eurofins-catalog",
    name: "eurofins-catalog",
    component: EurofinsCatalog,
  },
  {
    path: "admin",
    component: Admin,
    children: AdminChildren,
    beforeEnter: fetchAdminInformation,
  },
  {
    path: "edit-profile",
    name: "edit-profile",
    component: MyProfile,
  },
];
