import store from "@/store";
import dayjs from "dayjs";
import db from "@/db";

const initOfflineMode = async () => {
  const user = await db.users.toCollection().first();
  const weeks_validity =
    parseInt(process.env.VUE_APP_OFFLINE_WEEKS_VALIDITY) || 6;
  const endValidityDate = dayjs().subtract(weeks_validity, "week");
  const lastLoginDate = dayjs(user?.lastSsoSync);
  if (
    lastLoginDate > endValidityDate &&
    user &&
    (await user.hasOfflinePermission())
  ) {
    await db.models.User.createOrUpdate(user);
    // await insertUser(user);
    console.log("Add store action insert user");
  } else {
    throw Error("Unauthorized access to offline mode");
  }
};

const isLoggedOrOffline = async (to, from, next) => {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    await store.dispatch("users/initializeCurrentUser");
    await db.models.User.createOrUpdate(
      await store.getters["users/currentUser"]
    );
  } else {
    try {
      await initOfflineMode();
    } catch {
      return next({ name: "unauthorized-access-offline-mode" });
    }
  }
  next();
};

const isOffline = (from, to, next) => {
  if (!window.navigator.onLine) {
    next();
  } else {
    next("/");
  }
};

export { isLoggedOrOffline, isOffline };
