import {
  ProjectClient,
  ListerClient,
  SampleClient,
  modelToApiMapping,
} from "@/api";

import db from "@/db";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { buildQueryset } from "@/utils/queryset";
dayjs.extend(utc);

import crudGenerator from "@/api/utils/offlineSync";
import { OfflineError } from "@/utils/errors";

import { VUE_APP_ATMO_SERVICE_ID } from "@/env";
import _ from "lodash";

const { innerUpdate, resyncToBack: syncProject } = crudGenerator(
  db.models.Project
);
const { resyncToBack: syncClient } = crudGenerator(db.models.Client);
const { resyncToBack: syncInstallations } = crudGenerator(
  db.models.Installation
);
const { resyncToBack: syncConfigurationLines } = crudGenerator(
  db.models.ConfigurationLine
);
const { resyncToBack: syncSums } = crudGenerator(db.models.Sum);
const { resyncToBack: syncVles } = crudGenerator(db.models.Vle);
const { resyncToBack: syncFlows } = crudGenerator(db.models.Flow);
const { resyncToBack: syncFlasks } = crudGenerator(db.models.SampleFlask);
const { resyncToBack: syncPhases } = crudGenerator(db.models.Phase);
const { resyncToBack: syncRecordBooks } = crudGenerator(db.models.RecordBook);
const { resyncToBack: syncMeasurementForms } = crudGenerator(
  db.models.MeasurementForm
);

const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_CURRENT_IS_LOADING = "SET_CURRENT_IS_LOADING";
const SET_PROJECTS = "SET_PROJECTS";
const SET_TOTAL_PROJECTS = "SET_TOTAL_PROJECTS";
const UPDATE_PROJECT = "UPDATE_PROJECT";

const state = {
  currentProject: null,
  currentProjectIsLoading: false,
  projectIsLoading: false,
  projectList: [],
  totalProjects: 0,
};

const getters = {
  currentProjectUuid(state) {
    return state.currentProject?.uuid;
  },
  currentProjectManagerUuid(state) {
    return state.currentProject?.managerUuid;
  },
  currentProjectManagerEmail(state) {
    return state.currentProject?.managerEmail;
  },
  isOfflineState(state) {
    return (
      !!state.currentProject?.isStoredOffline ||
      process.env.VUE_APP_FORCE_OFFLINE == "true"
    );
  },
};

const actions = {
  async setCurrentProject({ commit }, uuid) {
    commit(SET_CURRENT_IS_LOADING, true);
    const project = await db.projects.get(uuid);
    if (!project) throw Error("404");
    if (!window.navigator.onLine && !project?.isStoredOffline)
      throw Error("404");
    await project.setRelationships();
    commit(SET_CURRENT_PROJECT, project);
    commit(SET_CURRENT_IS_LOADING, false);

    return project;
  },

  async updateProject({ commit, rootGetters }, form, setAsCurrent = true) {
    const project = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    if (setAsCurrent) commit(SET_CURRENT_PROJECT, project);
  },

  async fetchProject(_, uuid) {
    let project = await db.projects.get(uuid);
    if (project && project.isStoredOffline) {
      return project;
    }
    // if (!project && !window.navigator.onLine) {
    //   // TODO - B.L - 05/05/2023 - redirect to homepage
    // }
    const projectData = await ProjectClient.retrieve(uuid);
    const { pb_case, client } = projectData;

    if (pb_case) await db.models.Case.createOrUpdate(pb_case);

    if (client) await db.models.Client.createOrUpdate(client);

    project = await db.models.Project.createOrUpdate(projectData);

    return project;
  },

  async sendInvitationRequestEmail(_, projectId) {
    return await ProjectClient.sendInvitationRequestEmail(projectId);
  },

  async syncFullProject({ dispatch }, uuid) {
    const project = await dispatch("fetchProject", uuid);
    if (!project.isStoredOffline) {
      dispatch(
        "roles/fetchRoles",
        {
          filters: {
            service_id: VUE_APP_ATMO_SERVICE_ID,
          },
        },
        { root: true }
      );
      dispatch("installations/fetchInstallations", uuid, { root: true });
      dispatch("samples/fetchSamples", uuid, { root: true });
      dispatch("phases/fetchPhases", uuid, { root: true });
      dispatch("sum/fetchSums", uuid, { root: true });
      dispatch("vles/fetchVles", uuid, { root: true });
      dispatch("flows/fetchFlows", uuid, { root: true });
      dispatch("documents/fetchDocumentTypes", null, { root: true });
      dispatch("configurationLines/fetchConfigurationLines", uuid, {
        root: true,
      });
      dispatch("contributors/fetchContributors", uuid, { root: true });
      dispatch("sampleFlasks/fetchSampleFlasks", uuid, { root: true });
      dispatch("orderForms/fetchOrderForms", uuid, { root: true });
      dispatch("recordBooks/fetchRecordBooks", uuid, { root: true });
      dispatch("recordBooks/fetchMeasurementForms", uuid, { root: true });
      dispatch(
        "reportTemplates/fetchReportTemplates",
        VUE_APP_ATMO_SERVICE_ID,
        { root: true }
      );
      dispatch("analysisResult/fetchAnalysisResult", uuid, { root: true });
      dispatch("acquisitionRecordBooks/fetchAcquisitionRecordBook", uuid, {
        root: true,
      });
    }
    return await db.projects.get(uuid);
  },

  async updateFullProject(_, uuid) {
    if (!window.navigator.onLine) return;
    let project = null;
    try {
      const garbages = await db.garbages
        .where({ installationUuid: uuid })
        .toArray();
      await Promise.all(
        garbages.map(async (garbage) => {
          try {
            console.log(
              `DELETING table ${garbage.table}, uuid: ${garbage.uuid}`
            );
            await modelToApiMapping[garbage.table].destroy(garbage.uuid);
          } catch (err) {
            console.log(
              `Error during delete table ${garbage.table}, uuid: ${garbage.uuid}`
            );
            console.error(err);
          }
        })
      );
      project = await db.projects.get(uuid);
      [project] = await syncProject(project);
      await project.setRelationships();
      const { client } = project;
      if (client) {
        await syncClient(client);
      }
      await syncInstallations(project.installations);
      for (const installation of project.installations) {
        await installation.setRelationships();

        await syncConfigurationLines(installation.configurationLines);

        await syncSums(installation.sums);

        const sumsUuids = installation.sums.map(({ uuid }) => uuid);
        const configurationLinesUuids = installation.configurationLines.map(
          ({ uuid }) => uuid
        );
        const vles = await db.vles
          .filter(
            ({ configurationLine, sum }) =>
              sumsUuids.includes(sum) ||
              configurationLinesUuids.includes(configurationLine)
          )
          .toArray();
        await syncVles(vles);

        const vlesUuids = vles.map(({ uuid }) => uuid);
        const flows = await db.flows.where("vle").anyOf(vlesUuids).toArray();

        await syncFlows(flows);

        await syncFlasks(installation.sampleFlasks);

        await syncPhases(installation.phases);
        await Promise.all(
          installation.phases.map(async (phase) => {
            await phase.setRelationships();
            const forms = phase.samples.filter(
              ({ isCreatedOffline }) => isCreatedOffline
            );
            await SampleClient.bulkCreate(forms);
            await SampleClient.bulkUpdate(forms);
          })
        );
      }

      // INFO - B.L - 17/07/2023 - record books needs to be save in a certain order for fk integrity
      const batches = [
        ["environmental_condition"],
        ["speed", "flow", "analyser", "humidity"],
        ["nozzle_diameter"],
        ["manual_sampling"],
      ];
      for (const batch of batches) {
        const recordBooks = project.recordBooks.filter((rb) =>
          batch.includes(rb.type)
        );
        await syncRecordBooks(recordBooks);
      }
      await syncMeasurementForms(project.measurementForms);
    } catch (err) {
      console.error(err);
      db.projects.update(project.uuid, { isStoredOffline: true });
      return project;
    }
    project.isStoredOffline = false;
    return project;
  },

  async fetchProjects({ commit }) {
    if (!window.navigator.onLine) return;

    commit(SET_IS_LOADING, true);
    const lastProject = await db.projects
      .orderBy("listerUpdatedAt")
      .reverse()
      .first();
    let query = null;

    if (lastProject) {
      query = {
        project_updated_after: dayjs
          .utc(lastProject.listerUpdatedAt)
          .format("YYYY-MM-DD:hh-mm-ss"),
      };
    }

    let results = await ListerClient.list(query);
    console.log(`received ${results.length} projects`);
    const uniqueResult = _.uniqBy(results, "uuid");
    console.log(`unique projects ${uniqueResult.length}`);
    db.transaction("rw", db.projects, async () => {
      for (const result of results) {
        db.models.Project.createOrUpdateFromLister(result);
      }
    })
      .then(() => {
        commit(SET_IS_LOADING, false);
      })
      .catch((err) => {
        commit(SET_IS_LOADING, false);
        console.error(err.stack);
      });
  },

  async filterProjects(
    { state, commit },
    { queryAsObject, ordering, filterMapping }
  ) {
    // INFO - B.L - 06/09/2022 - Lock call till app finished loading all projects
    while (state.projectIsLoading) await new Promise((r) => setTimeout(r, 200));

    if (!window.navigator.onLine) {
      queryAsObject = {
        isStoredOffline: true,
        ...queryAsObject,
      };
    }

    commit(SET_IS_LOADING, true);

    const { queryset, count } = await buildQueryset(
      db.projects,
      queryAsObject,
      ordering,
      filterMapping
    );

    commit(SET_PROJECTS, await queryset.toArray());
    commit(SET_TOTAL_PROJECTS, count);
    commit(SET_IS_LOADING, false);
  },

  async reloadProject({ state, dispatch }) {
    await dispatch("setCurrentProject", state.currentProject.uuid);
  },

  async searchProject(context, searchText) {
    if (!window.navigator.onLine) {
      throw new OfflineError();
    }
    const projectsList = await db.projects
      .filter((item) => {
        return (
          item.projectName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.chronorapso.toLowerCase().includes(searchText.toLowerCase())
        );
      })
      .toArray();
    return projectsList;
  },
  async archiveOrUnarchiveProject({ commit, rootGetters }, project) {
    if (!window.navigator.onLine) {
      throw new OfflineError();
    }
    const response = await ProjectClient.archiveOrUnarchiveProject(project);
    project.isArchived = response.isArchived;
    commit(UPDATE_PROJECT, project);
    await innerUpdate(project, rootGetters["projects/isOfflineState"]);
    return project;
  },
};

const mutations = {
  [SET_CURRENT_PROJECT]: (state, project) => (state.currentProject = project),
  [SET_IS_LOADING]: (state, loading) => (state.projectIsLoading = loading),
  [SET_CURRENT_IS_LOADING]: (state, loading) =>
    (state.currentProjectIsLoading = loading),
  [SET_PROJECTS]: (state, projects) => (state.projectList = projects),
  [SET_TOTAL_PROJECTS]: (state, totalProjects) =>
    (state.totalProjects = totalProjects),
  [UPDATE_PROJECT]: (state, project) => {
    let copy = [...state.projectList];
    const index = copy.findIndex((item) => project.uuid == item.uuid);
    copy[index] = project;
    state.projectList = [...copy];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
