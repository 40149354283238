const flowRecipe = {
  type: "flow",
  recordForm: {
    debit: null,
    temperature: null,
    isValidated: false,
  },
  recordBlocks: [
    {
      label: "INIT",
      recordEvents: [],
      type: "flow_general_informations_form",
      recordForm: {
        debit: null,
        temperature: null,
        isValidated: false,
      },
    },
  ],
};

const flowRecipeMessages = {
  fr: {
    "flow.flow_general_informations_form": "Informations générales",
    "flow.block.flow_general_informations_form": "Initalisation",
  },
  en: {
    "flow.flow_general_informations_form": "General information",
    "flow.block.flow_general_informations_form": "Initialization",
  },
};

export { flowRecipe, flowRecipeMessages };
