import db from "@/db";
import store from "@/store";
import crudGenerator from "@/api/utils/offlineSync";

const { innerFetch, innerCreate, innerUpdate, innerBulkDelete, innerDelete } =
  crudGenerator(db.models.Phase);

const state = {
  phaseList: [],
  phaseIsLoading: false,
};

const getters = {};

const actions = {
  async fetchPhases({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
      pageSize: 500,
    };
    const setIsLoading = (isLoading) => commit("SET_IS_LOADING", isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },

  async filterPhasesByInstallation({ commit }, installationUuid) {
    while (state.phaseIsLoading) await new Promise((r) => setTimeout(r, 200));

    commit("SET_IS_LOADING", true);

    const phases = await db.phases
      .where({
        installation: installationUuid,
      })
      .sortBy("order");

    for (const phase of phases) {
      await phase.setRelationships();
    }

    commit("SET_LAST_PHASES", phases);
    commit("SET_IS_LOADING", false);

    return phases;
  },

  async createPhase(
    { commit, rootGetters },
    { form, buildSampleChain = true }
  ) {
    const phase = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    if (buildSampleChain) {
      await store.dispatch("samples/buildSampleChain", phase.uuid);
    }
    await store.dispatch("installations/refreshLastUpdated");
    commit("ADD_PHASE", phase);
    return phase;
  },

  async updatePhase({ commit, rootGetters }, form) {
    const phase = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    await store.dispatch("installations/refreshLastUpdated");
    commit("UPDATE_PHASE", phase);
    return phase;
  },

  async removePhase({ commit, rootGetters }, phase) {
    await innerDelete(
      phase.uuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    await store.dispatch("installations/refreshLastUpdated");
    commit("DELETE_PHASE", phase);
  },

  async bulkDeletePhases({ commit, rootGetters }, uuids) {
    await innerBulkDelete(
      uuids,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    await store.dispatch("installations/refreshLastUpdated");
    commit("RESET_PHASES");
  },

  async retrievePhaseByUuid(context, phaseUuid) {
    return await db.phases.get(phaseUuid);
  },
};

const mutations = {
  SET_LAST_PHASES: (state, phaseList) => {
    state.phaseList = phaseList;
  },
  SET_IS_LOADING: (state, phaseIsLoading) => {
    state.phaseIsLoading = phaseIsLoading;
  },
  ADD_PHASE(state, phase) {
    state.phaseList.push(phase);
  },
  UPDATE_PHASE(state, phase) {
    const phaseIndex = state.phaseList.findIndex(
      ({ uuid }) => phase.uuid === uuid
    );

    if (phaseIndex < 0) return;

    state.phaseList[phaseIndex] = phase;
    state.phaseList = [...state.phaseList];
  },
  DELETE_PHASE(state, phase) {
    state.phaseList = [
      ...state.phaseList.filter(({ uuid }) => uuid !== phase.uuid),
    ];
  },
  RESET_PHASES(state) {
    state.phaseList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
