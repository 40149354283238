export const SET_CURRENT_USER = "SET_CURRENT_USER";

const state = {
  currentUser: null,
};

const getters = {
  currentUser: (state) => {
    return state.currentUser;
  },
};

const actions = {
  setCurrentUser({ commit }, user) {
    commit(SET_CURRENT_USER, user);
  },
};

const mutations = {
  [SET_CURRENT_USER]: (state, user) => {
    state.currentUser = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
