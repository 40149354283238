import { buildMetadata } from "@/utils/metadata";
import { structToJsObj } from "@/utils/utilsGrpcRest";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.document.document;
const grpcClient =
  socioGrpcClient.document.document.DocumentCustomControllerPromiseClient;

const grpcClientDocument =
  socioGrpcClient.document.document.DocumentControllerPromiseClient;
const excludeFields = [
  "createdAt",
  "updatedAt",
  "docTypeData",
  "docStatusData",
];
export const DocumentClient = {
  retrieve: async (documentUuid) => {
    const data = {
      uuid: documentUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.DocumentCustomRetrieveRequest,
      data
    );
    const response = await grpcClient.documentCustomExtendRetrieve(request, {});
    return response.toObject();
  },
  list: async (filters) => {
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.DocumentCustomListRequest();
    const response = await grpcClient.documentCustomExtendList(
      request,
      metadata
    );
    return response.toObject();
  },
  retrieveDocumentUrl: async (documentUuid) => {
    const requestData = {
      uuid: documentUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.DocumentCustomRetrieveRequest,
      requestData
    );
    const response = await grpcClient.retrieveDocumentCustomUrl(request, {});
    return response.toObject();
  },
  retrieveDocumentInObjectStorageUrl: async (documentUuid) => {
    const requestData = {
      uuid: documentUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.DocumentRetrieveRequest,
      requestData
    );
    const response =
      await grpcClientDocument.retrieveDocumentInObjectStorageUrl(request, {});
    return response.toObject();
  },
  destroy: async (documentUuid) => {
    const request = new requests.DocumentCustomDestroyRequest();
    request.setUuid(documentUuid);
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
  create: async (file, fileName, document, documentCustom, callBack) => {
    await socioGrpcClient.document.document.StandbyUploadControllerPromiseClient.utils.uploadDocument(
      document,
      documentCustom,
      file,
      fileName,
      callBack
    );
  },
  update: async (data) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.DocumentFlatRequest,
      data,
      excludeFields
    );
    const response = await grpcClient.updateDocumentCustomExtend(request, {});
    return structToJsObj(response);
  },
  bulkUpdate: async (data, documentUuids) => {
    const request = new requests.DocumentCustomBulkUpdateDocumentsRequest();
    for (const documentUuid of documentUuids) {
      const documentData = {
        uuid: documentUuid,
        datetime: data.datetime,
        source: data.source,
        tagsList: data.tagsList || [],
      };
      const document = socioGrpcClient.javascriptToRequest(
        requests.DocumentFlatRequest,
        documentData
      );
      request.addDocuments(document);
    }
    const response = await grpcClient.bulkUpdateDocuments(request, {});
    return response.toObject().resultsList;
  },
  createWithoutFile: async (data) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.DocumentFlatRequest,
      data
    );
    const response = await grpcClient.createWithDocument(request, {});
    return response.toObject();
  },
  listTags: async (filters) => {
    const requestMetadata = {
      ...buildMetadata({ filters }),
    };
    const request = new requests.TagListRequest();
    const response =
      await socioGrpcClient.document.document.TagControllerPromiseClient.list(
        request,
        requestMetadata
      );
    return response.toObject().resultsList;
  },
  listDocumentTypes: async () => {
    const request = new requests.DocumentTypeListRequest();
    const response =
      await socioGrpcClient.document.document.DocumentTypeControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );
    return response;
  },
};
