import db from "@/db";
import { RoleClient } from "@/api";

const state = {
  roleList: [],
  totalRoles: 0,
  roleIsLoading: false,
};

const getters = {};

const actions = {
  async fetchRoles({ commit }, metadata) {
    if (!window.navigator.onLine) return;
    commit("SET_IS_LOADING", true);

    const { resultsList, count } = await RoleClient.list(metadata);
    const roles = [];

    for (const roleResponse of resultsList) {
      const dexieRoleUuid = await db.models.Role.createOrUpdate(roleResponse);
      const role = await db.roles.get(dexieRoleUuid);

      roles.push(role);
    }

    commit("SET_ROLE_LIST", roles);
    commit("SET_TOTAL_ROLES", count);
    commit("SET_IS_LOADING", false);

    return roles;
  },
  async getRoleByName(context, { name }) {
    const role = await db.roles.where("name").equalsIgnoreCase(name);

    return role;
  },
};

const mutations = {
  SET_ROLE_LIST(state, roles) {
    state.roleList = roles;
  },
  SET_IS_LOADING(state, isLoading) {
    state.roleIsLoading = isLoading;
  },
  SET_TOTAL_ROLES(state, totalRoles) {
    state.totalRoles = totalRoles;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
