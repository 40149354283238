import db from "@/db";

import { ContributorClient } from "@/api";
import { buildQueryset } from "@/utils/queryset";

const Contributor = db.models.Contributor;
const CompanyProjectRole = db.models.CompanyProjectRole;

const SET_CONTRIBUTORS = "SET_CONTRIBUTORS";
const ADD_CONTRIBUTOR = "ADD_CONTRIBUTOR";
const DELETE_CONTRIBUTOR = "DELETE_CONTRIBUTOR";
const SET_IS_LOADING = "SET_IS_LOADING";
const UPDATE_CONTRIBUTOR = "UPDATE_CONTRIBUTOR";
const SET_USER_CAN_CHANGE = "SET_USER_CAN_CHANGE";

const state = {
  contributorIsLoading: false,
  contributors: [],
  isCurrentUserCanChange: false,
};

const getters = {
  contributors: (state) => {
    return state.contributors;
  },
};

const actions = {
  async getContributorByUuid(context, contributorUuid) {
    return await db.contributors.get(contributorUuid);
  },

  async getContributorByEmail(context, contributorEmail) {
    return await db.contributors.where("email").equals(contributorEmail);
  },

  async setUserCanChange({ commit, dispatch, rootGetters }, projectUuid) {
    const currentUserEmail = rootGetters["users/currentUserEmail"];
    const isCurrentUserProjectManagerOrDeveloper =
      rootGetters["users/isCurrentUserProjectManagerOrDeveloper"];
    await dispatch("fetchContributors", projectUuid);

    const companyProjectRoleIds = await db.companyProjectRoles
      .where({ projectId: projectUuid })
      .primaryKeys();
    const projectContributors = await db.contributors
      .where("companyProjectRoleId")
      .anyOf(companyProjectRoleIds)

      .toArray();

    const contributorEmails = projectContributors.map(
      ({ userEmail }) => userEmail
    );

    commit(SET_CONTRIBUTORS, projectContributors);

    commit(
      SET_USER_CAN_CHANGE,
      contributorEmails.includes(currentUserEmail) ||
        isCurrentUserProjectManagerOrDeveloper
    );
  },

  async fetchContributors({ commit, rootGetters }, projectUuid) {
    if (!window.navigator.onLine || rootGetters["projects/isOfflineState"])
      return;

    const filters = {
      project_ids: projectUuid,
      is_internal: true,
    };
    commit(SET_IS_LOADING, true);
    const { resultsList } = await ContributorClient.list(filters);
    for (const contributor of resultsList) {
      await db.models.Contributor.createOrUpdate(contributor);
      await db.models.CompanyProjectRole.createOrUpdate(
        contributor.companyProjectRole
      );
    }
    commit(SET_IS_LOADING, false);
  },

  async filterContributors(
    { commit },
    { queryAsObject, ordering, filterMapping } = {
      queryAsObject: {},
      ordering: "",
      filterMapping: {},
    }
  ) {
    if (queryAsObject?.roleId) {
      const companyProjectRoleIds = await db.companyProjectRoles
        .where({ roleId: queryAsObject.roleId })
        .primaryKeys();
      delete queryAsObject.roleId;

      queryAsObject.companyProjectRoleId = companyProjectRoleIds;
    }

    if (queryAsObject?.projectId) {
      const companyProjectRoleIds = await db.companyProjectRoles
        .where({ projectId: queryAsObject.projectId })
        .primaryKeys();
      delete queryAsObject.projectId;

      if (queryAsObject.companyProjectRoleId)
        queryAsObject.companyProjectRoleId =
          queryAsObject.companyProjectRoleId.filter((e) =>
            companyProjectRoleIds.includes(e)
          );
      else {
        queryAsObject.companyProjectRoleId = companyProjectRoleIds;
      }
    }

    while (state.contributorIsLoading)
      await new Promise((r) => setTimeout(r, 200));

    commit(SET_IS_LOADING, true);
    const { queryset } = await buildQueryset(
      db.contributors,
      queryAsObject,
      ordering,
      filterMapping
    );

    commit(SET_CONTRIBUTORS, await queryset.toArray());
    commit(SET_IS_LOADING, false);
  },
  async createContributor({ commit }, contributor) {
    const contributorReponse = await ContributorClient.create(contributor);
    const dexieUuid = await Contributor.createOrUpdate(contributorReponse);
    await CompanyProjectRole.createOrUpdate(
      contributorReponse.companyProjectRole
    );

    const newContributor = await db.contributors.get(dexieUuid);

    commit(ADD_CONTRIBUTOR, newContributor);
    return newContributor;
  },
  async updateContributor({ commit }, contributorForm) {
    const { uuid } = contributorForm;
    const localContributor = await db.contributors.get(uuid);
    const contributorResponse = await ContributorClient.update({
      usermanagementUuid: localContributor.usermanagementUuid,
      ...contributorForm,
    });
    const success = await db.contributors.update(uuid, contributorResponse);

    if (!success) console.warn(`Could not update contributor ${uuid}`);

    const contributor = await db.contributors.get(uuid);
    await contributor.setRelationships();

    commit(UPDATE_CONTRIBUTOR, contributor);

    return contributor;
  },
  async destroyContributor({ commit }, contributorUuid) {
    await ContributorClient.destroy(contributorUuid);
    const success = await db.contributors.delete(contributorUuid);

    if (!success)
      console.warn(`Could not delete contributor ${contributorUuid}`);

    commit(DELETE_CONTRIBUTOR, contributorUuid);
  },
};

const mutations = {
  [SET_USER_CAN_CHANGE]: (state, isContributor) => {
    state.isCurrentUserCanChange = isContributor;
  },
  [SET_CONTRIBUTORS]: (state, contributors) => {
    state.contributors = contributors;
  },
  [ADD_CONTRIBUTOR]: (state, newContributor) => {
    state.contributors.push(newContributor);
  },
  [DELETE_CONTRIBUTOR]: (state, contributorUuid) => {
    state.contributors = state.contributors.filter(
      ({ uuid }) => uuid !== contributorUuid
    );
  },
  [SET_IS_LOADING]: (state, isLoading) => {
    state.contributorIsLoading = isLoading;
  },
  [UPDATE_CONTRIBUTOR]: (state, newContributor) => {
    const contributorIndex = state.contributors.findIndex(
      ({ uuid }) => newContributor.uuid == uuid
    );

    if (contributorIndex < 0) return;

    state.contributors[contributorIndex] = newContributor;
    state.contributors = [...state.contributors];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
