export const multiGuard = (guards) => async (to, from, next) => {
  let redirect = false;

  const redirectNext = (location) => {
    if (location) {
      redirect = true;
      next(location);
    }
  };

  for (const guard of guards) {
    await guard(to, from, redirectNext);

    if (redirect) break;
  }

  if (!redirect) next();
};
