export const choices = {
  continuously: "EN_CONTINU",
  cycle: "EN_CYCLE",
  o2: "O2",
  co2: "CO2",
  instantaneousValues: "VALEURS_INSTANTANEES",
  averageValues: "VALEURS_MOYENNES_SUR_CHAQUE_ESSAI",
  circular: "CIRCULAIRE",
  rectangular: "RECTANGULAIRE",
  vertical: "VERTICALE",
  horizontal: "HORIZONTALE",
  others: "AUTRES",
  greaterDh: "SUPERIEURE_A_5_DH",
  lessDh: "INFERIEURE_A_5_DH",
  corniceLadder: "ECHELLE_A_CORNICHE",
  scaffolding: "ECHAFAUDAGE",
  stairs: "ESCALIER",
  elevator: "ASCENCEUR",
  carryCot: "NACELLE",
  groundMeasurement: "MESURE_AU_SOL",
  satisfying: "SATISFAISANT",
  notSatisfying: "NON_SATISFAISANT",
  tracerGas: "GAZ_TRACEUR",
  verified: "DEJA_VERIFIEE",
  homogeneous: "HOMOGENE",
  verifImpossible: "VERIFICATION_IMPOSSIBLE",
  oxygen: "OXYGENE",
  dioxygen: "DIOXYDE_DE_CARBONE",
  azote: "OXYDES_AZOTE",
  onePointSampling: "PRELEVEMENT_EN_UN_POINT",
  sampleByGrid: "PRELEVEMENT_PAR_QUADRILLAGE",
  oneEmitter: "UN_EMETTEUR",
  severalEmitters: "PLUSIEURS_EMETTEURS",

  homogeneousOneEmitter: "HOMOGENE_UN_EMETTEUR",
  homogeneousManyEmitter: "HOMOGENE_PLUSIEURS_EMETTEURS",
  homogeneityO2: "HOMOGENEITE_O2",
  homogeneityCo2: "HOMOGENEITE_CO2",
  homogeneityNox: "HOMOGENEITE_NOx",
  homogeneityOthers: "HOMOGENEITE_AUTRE",
  homogeneityVerifHomogeneous: "HOMOGENEITE_VERIF_HOMOGENE",
  homogeneityVerifHeterogeneousPoint: "HOMOGENEITE_VERIF_HETEROGENE_POINT",
  homogeneityVerifHeterogeneousGrid: "HOMOGENEITE_VERIF_HETEROGENE_QUADRILLAGE",
  customSentence: "PHRASE_PERSONNALISEE",

  isokineticSamplingGrid: "ISOCINETIQUE_QUADRILLAGE",
  noIsokineticSamplingGrid: "NON_ISOCINETIQUE_QUADRILLAGE",
  noIsokineticPoint: "NON_ISOCINETIQUE_POINT",
  isokineticPoint: "ISOCINETIQUE_POINT",
  samplingOutlet: "DEBOUCHE_CONDUIT",
  withoutObject: "SANS_OBJET",

  homogeneousEffluent: "HOMOGENE_EFFLUENT",
  heterogenityPoint: "HETEROGENE_POINT",
  heterogenityGrid: "HETEROGENE_QUADRILLAGE",

  condensation: "CONDENSATION",
  permeation: "PERMEATION",
  hotConverter: "CONVERTISSEUR_A_CHAUD",
};
