export default {
  METAUX: "METAUX",
  MTX: "MTX",
  "DIOX/HAP": "DIOX/HAP",
  "PRLVT_MANU.": "PRLVT_MANU.",
  "AN._III": "AN._III",
  "AN._IV": "AN._IV",
  "PRLVT_AUTO.": "PRLVT_AUTO.",
  "MESURE_PERIPH.": "MESURE_PERIPH.",
  "COMPOSES_SPE.": "COMPOSES_SPE.",
};
