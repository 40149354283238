import BaseSchema from "./base";

class OrderForm extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "orderForms";
  static fields = [
    "uuid",
    "project",
    "createdAt",
    "updatedAt",
    "label",
    "isSentToEurofins",
    "isResultAvailable",
  ];

  static relatedFields = {};

  static dateFields = [];
}

export default OrderForm;
