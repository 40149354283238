import { buildMetadata } from "@/utils/metadata";
import { renameKeys } from "@/utils/utilsGrpcRest";

import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.user_management.user;
const grpcClient =
  socioGrpcClient.user_management.user.UserControllerPromiseClient;

export const UserManagementClient = {
  list: async (metadata) => {
    const request = new requests.UserListRequest();
    const response = await grpcClient.list(request, buildMetadata(metadata));

    return response.toObject();
  },
  retrieve: async ({ uuid }) => {
    const request = new requests.UserRetrieveRequest();
    request.setUuid(uuid);

    const response = await grpcClient.retrieve(request, {});
    if (!response.groupsList) response.groupsList = [];

    const keysMap = { groupsList: "groups" };
    const user = renameKeys(keysMap, response.toObject());

    return user;
  },
};
