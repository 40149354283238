export default function useInstallationVle() {
  const concentrationUnits = {
    NGNM3: "NGNM3",
    MGNM3: "MGNM3",
    SPEED: "MS",
  };

  const concentrationParameters = {
    CARBONNE: "CARBONNE",
    C3H8: "C3H8",
    CH4: "CH4",
  };

  const humidityLevelToDewPoint = {
    0.61: 0,
    0.65: 1,
    0.7: 2,
    0.75: 3,
    0.81: 4,
    0.86: 5,
    0.93: 6,
    0.99: 7,
    1.06: 8,
    1.14: 9,
    1.22: 10,
    1.3: 11,
    1.39: 12,
    1.48: 13,
    1.58: 14,
    1.69: 15,
    1.8: 16,
    1.92: 17,
    2.05: 18,
    2.18: 19,
    2.32: 20,
    2.47: 21,
    2.62: 22,
    2.79: 23,
    2.96: 24,
    3.14: 25,
    3.34: 26,
    3.54: 27,
    3.75: 28,
    3.98: 29,
    4.21: 30,
    4.46: 31,
    4.72: 32,
    4.99: 33,
    5.28: 34,
    5.58: 35,
    5.9: 36,
    6.23: 37,
    6.58: 38,
    6.94: 39,
    7.33: 40,
    7.73: 41,
    8.14: 42,
    8.58: 43,
    9.04: 44,
    9.52: 45,
    10.02: 46,
    10.55: 47,
    11.09: 48,
    11.66: 49,
    12.26: 50,
    12.88: 51,
    13.53: 52,
    14.21: 53,
    14.91: 54,
    15.65: 55,
    16.41: 56,
    17.21: 57,
    18.04: 58,
    18.91: 59,
    19.81: 60,
    20.74: 61,
    21.71: 62,
    22.73: 63,
    23.78: 64,
    24.87: 65,
    26: 66,
    27.18: 67,
    28.4: 68,
    29.67: 69,
    30.99: 70,
    32.35: 71,
    33.77: 72,
    35.23: 73,
    36.75: 74,
    38.33: 75,
    39.96: 76,
    41.65: 77,
    43.39: 78,
    45.2: 79,
    47.07: 80,
    49.01: 81,
    51.01: 82,
    53.07: 83,
    55.21: 84,
    57.41: 85,
    59.69: 86,
    62.04: 87,
    64.47: 88,
    66.97: 89,
    69.56: 90,
    72.22: 91,
    74.97: 92,
    77.8: 93,
    80.71: 94,
    83.71: 95,
    86.81: 96,
    89.99: 97,
    93.26: 98,
    96.63: 99,
  };

  const dewPointToHumidityLevel = Object.fromEntries(
    Object.entries(humidityLevelToDewPoint).map(([key, value]) => [value, key])
  );

  const getDewPoint = (humidityLevel) => {
    if (humidityLevel > 96.63) return 100;

    const humidityReferential = Object.keys(humidityLevelToDewPoint).sort(
      (a, b) => parseFloat(a) - parseFloat(b)
    );

    const closestHumidityLevel = humidityReferential.find(
      (key) => parseFloat(key) >= humidityLevel
    );

    return humidityLevelToDewPoint[closestHumidityLevel];
  };

  const getHumidityLevel = (dewPoint) => {
    if (dewPoint > 96) return 100;

    const dewPointReferential = Object.keys(dewPointToHumidityLevel).sort(
      (a, b) => parseFloat(a) - parseFloat(b)
    );

    const closestDewPoint = dewPointReferential.find(
      (key) => parseFloat(key) >= dewPoint
    );

    return dewPointToHumidityLevel[closestDewPoint];
  };

  const getConcentrationParameter = ({
    configurationLine = null,
    sum = null,
  }) => {
    if (configurationLine) {
      const parameterName = configurationLine.parameterObject.name;
      const isCOV = ["COVNM", "COVT"].includes(parameterName);

      if (isCOV) return concentrationParameters.CARBONNE;
    }

    if (sum) {
      const isCOV = sum.parameters.some(({ name }) =>
        ["COVNM", "COVT"].includes(name)
      );

      if (isCOV) return concentrationParameters.CARBONNE;
    }

    return null;
  };

  return {
    getDewPoint,
    getConcentrationParameter,
    concentrationUnits,
    concentrationParameters,
    getHumidityLevel,
  };
}
