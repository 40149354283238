import _ from "lodash";

class MaxSentRequestsError extends Error {
  constructor(message) {
    super(message);
    this.name = "MaxSentRequestsError";
  }
}

const MAX_REQUESTS_NB = 10000;

const listAllObjects = async (
  socioGrpcApiPromiseClient,
  request,
  metadata,
  method
) => {
  if (!method) {
    method = "list";
  }
  const inputPagination =
    "pagination" in metadata ? JSON.parse(metadata.pagination) : {};
  inputPagination.page = 1;
  inputPagination.page_size = 5000;
  const paginatedMetadata = {
    ...metadata,
    pagination: JSON.stringify(inputPagination),
  };
  // First request will determine numbers of page to fetch
  const firstResponse = await socioGrpcApiPromiseClient[method](
    request,
    paginatedMetadata
  );
  const firstResponseObj = firstResponse.toObject();
  const firstResults = firstResponse.getResultsList();
  if (!("count" in firstResponseObj) || isNaN(firstResponseObj.count)) {
    throw Error(
      "Expected a 'count' field with integer value from 'list' response"
    );
  }
  const expectedObjectNb = firstResponseObj.count;
  if (expectedObjectNb === firstResults.length) {
    return firstResults;
  }
  // Compute number of page to fetch from 'count' field
  const nbOfPageExpected = Math.ceil(expectedObjectNb / firstResults.length);
  if (nbOfPageExpected >= MAX_REQUESTS_NB) {
    throw MaxSentRequestsError(
      `Cannot exceed ${MAX_REQUESTS_NB} requests on 'listAllObjects' method.`
    );
  }
  // Build promise requests from page two to computed end of page
  const grpcPromiseList = [];
  for (let index = 2; index <= nbOfPageExpected; index++) {
    inputPagination.page = index;
    const paginatedMetadata = {
      ...metadata,
      pagination: JSON.stringify(inputPagination),
    };
    grpcPromiseList.push(
      socioGrpcApiPromiseClient[method](request, paginatedMetadata)
    );
  }
  let results = firstResults;
  let testResults = firstResults.map((i) => i.toObject());
  for (let index = 2; index <= nbOfPageExpected; index++) {
    inputPagination.page = index;
    const paginatedMetadata = {
      ...metadata,
      pagination: JSON.stringify(inputPagination),
    };
    const response = await socioGrpcApiPromiseClient[method](
      request,
      paginatedMetadata
    );
    const result = response.getResultsList();
    results = results.concat(result);
    testResults = testResults.concat(result.map((i) => i.toObject()));
    const uniqueResult = _.uniqBy(testResults, "uuid");
    console.log(
      `Result at iteration ${index}, total: ${results.length}, unique: ${uniqueResult.length}`
    );
  }
  return results;

  // const promiseResults = await Promise.all(grpcPromiseList);
  // const promiseResultsContent = promiseResults.map((response) =>
  //   response.getResultsList()
  // );
  // // const concatArray = (current, next) => current.concat(next);
  // let concatArray = firstResults;
  // let testArray = firstResults.map((i) => i.toObject());
  // let count = 1;
  // for (const res of promiseResultsContent) {
  //   concatArray = concatArray.concat(res);
  //   testArray = testArray.concat(res.map((i) => i.toObject()));
  //   const uniqueResult = _.uniqBy(testArray, "uuid");
  //   console.log(
  //     `Result at iteration ${count}, total: ${concatArray.length}, unique: ${uniqueResult.length}`
  //   );
  //   count++;
  // }
  // // return promiseResultsContent.reduce(concatArray, firstResults);
  // return concatArray;
};

const listAllObjectWithNone = async (
  socioGrpcApiPromiseClient,
  request,
  metadata,
  changeValueFrom0ToNull
) => {
  const inputPagination =
    "pagination" in metadata ? JSON.parse(metadata.pagination) : {};
  inputPagination.page = 1;
  const paginatedMetadata = {
    ...metadata,
    pagination: JSON.stringify(inputPagination),
  };
  // First request will determine numbers of page to fetch
  const firstResponse = await socioGrpcApiPromiseClient.list(
    request,
    paginatedMetadata
  );
  const firstResponseObj = firstResponse.toObject();
  const firstResults = firstResponse.getResultsList().map((res) => {
    let resultObject;
    resultObject = { ...res.toObject() };
    resultObject = changeValueFrom0ToNull(res, resultObject);
    return resultObject;
  });
  if (!("count" in firstResponseObj) || isNaN(firstResponseObj.count)) {
    throw Error(
      "Expected a 'count' field with integer value from 'list' response"
    );
  }
  const expectedObjectNb = firstResponseObj.count;
  if (expectedObjectNb === firstResults.length) {
    return firstResults;
  }
  // Compute number of page to fetch from 'count' field
  const nbOfPageExpected = Math.ceil(expectedObjectNb / firstResults.length);
  if (nbOfPageExpected >= MAX_REQUESTS_NB) {
    throw MaxSentRequestsError(
      `Cannot exceed ${MAX_REQUESTS_NB} requests on 'listAllObjects' method.`
    );
  }
  const grpcPromiseList = [];
  for (let index = 1; index <= nbOfPageExpected; index++) {
    inputPagination.page = index;
    const paginatedMetadata = {
      ...metadata,
      pagination: JSON.stringify(inputPagination),
    };
    grpcPromiseList.push(
      socioGrpcApiPromiseClient.list(request, paginatedMetadata)
    );
  }
  const promiseResults = await Promise.all(grpcPromiseList);
  const promiseResultsContent = promiseResults.map((response) =>
    response.getResultsList().map((res) => {
      let resultObject;
      resultObject = { ...res.toObject() };
      resultObject = changeValueFrom0ToNull(res, resultObject);
      return resultObject;
    })
  );
  const concatArray = (current, next) => current.concat(next);
  return promiseResultsContent.reduce(concatArray, firstResults);
};

export { listAllObjects, listAllObjectWithNone };
