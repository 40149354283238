import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";
import { buildQueryset } from "@/utils/queryset";
import { ChainManager } from "@/store/utils/sampleUtils";
import RecordBookSchema from "@/db/schemas/recordBook";

const { innerFetch, innerUpdate, innerCreate } = crudGenerator(
  db.models.RecordBook
);
const {
  innerFetch: innerFetchMF,
  innerUpdate: innerUpdateMF,
  innerCreate: innerCreateMF,
} = crudGenerator(db.models.MeasurementForm);
import {
  cookRecordBookRecipe,
  buildMeasurementForm,
} from "@/store/utils/recordbooks";
import dayjs from "dayjs";

const SET_CURRENT_RECORD_BOOK = "SET_CURRENT_RECORD_BOOK";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_RECORD_BOOKS = "SET_RECORD_BOOKS";
const SET_TOTAL_RECORD_BOOKS = "SET_TOTAL_RECORD_BOOKS";
const UPDATE_RECORD_BOOK = "UPDATE_RECORD_BOOK";
const ADD_RECORD_BOOK = "ADD_RECORD_BOOK";

const state = {
  currentRecordBook: null,
  currentRecordBookIsLoading: false,
  recordBookIsLoading: false,
  recordBookList: [],
  totalRecordBooks: 0,
};

const getters = {};
const actions = {
  async setCurrentRecordBook({ commit, state }, uuid) {
    while (state.recordBookIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit(SET_IS_LOADING, true);

    const recordBook = await db.recordBooks.get(uuid);
    await recordBook.setRelationships();

    commit(SET_CURRENT_RECORD_BOOK, recordBook);
    commit(SET_IS_LOADING, false);
    return recordBook;
  },
  async fetchRecordBooks({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },
  async fetchMeasurementForms({ rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    await innerFetchMF(filters, rootGetters["projects/isOfflineState"]);
  },

  async getRecordBook(context, recordBookUuid) {
    return await db.recordBooks.get(recordBookUuid);
  },

  async setProjectRecordBook({ commit }, projectUuid) {
    const records = await db.recordBooks
      .where({
        project: projectUuid,
      })
      .toArray();
    commit(SET_RECORD_BOOKS, records);
    commit(SET_TOTAL_RECORD_BOOKS, records.length);
  },
  async getRecorkBooksForInstallation(
    context,
    { projectUuid, installationUuid }
  ) {
    return await db.recordBooks
      .where({
        project: projectUuid,
        installation: installationUuid,
      })
      .filter(({ isArchived }) => !isArchived)

      .toArray();
  },

  async createRecordBook(
    { commit, rootGetters },
    { projectUuid, form, sourceToDuplicate = null }
  ) {
    if (form.phase) {
      const chain = await ChainManager.initialize({ phaseUuid: form.phase });
      form.phaseSignature = await chain.getPhaseSignature();
    }
    form = {
      project: projectUuid,
      ...form,
      ...cookRecordBookRecipe(form.type, sourceToDuplicate),
    };
    const recordBook = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    await db.models.RecordBook.createOrUpdate(form);

    // INFO - B.L - 20/06/2023 - Dirty but is the easiest implementation I found with the time I had
    if (form.type == "manual_sampling") {
      await recordBook.setFilterTemperature();
      await recordBook.setDurations();
      await innerUpdate(recordBook, rootGetters["projects/isOfflineState"]);
      try {
        const measurementFormForm = await buildMeasurementForm(recordBook);
        const measurementForm = await innerCreateMF(
          measurementFormForm,
          rootGetters["projects/isOfflineState"]
        );
        await measurementForm.buildSetup();
        await measurementForm.save();
        recordBook.measurementFormObj = measurementForm;
        await innerUpdate(recordBook, rootGetters["projects/isOfflineState"]);
      } catch (error) {
        console.error(error);
      }
    }
    commit(ADD_RECORD_BOOK, recordBook);
    return recordBook;
  },

  async attemptFixMeasurementForm({ rootGetters }, recordBook) {
    let RecordBookSchema = copyPrototype(recordBook);
    await RecordBookSchema.setFilterTemperature();
    await RecordBookSchema.setDurations();
    await innerUpdate(RecordBookSchema, rootGetters["projects/isOfflineState"]);
    try {
      const measurementFormForm = await buildMeasurementForm(RecordBookSchema);
      const measurementForm = await innerCreateMF(
        measurementFormForm,
        rootGetters["projects/isOfflineState"]
      );
      await measurementForm.buildSetup();
      await measurementForm.save();
      RecordBookSchema.measurementFormObj = measurementForm;
      await innerUpdate(
        RecordBookSchema,
        rootGetters["projects/isOfflineState"]
      );
    } catch (error) {
      console.error(error);
    }
  },

  async updateRecordBook({ rootGetters }, form) {
    const recordBook = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    return recordBook;
  },

  async updateMeasurementForm({ rootGetters }, form) {
    const measurementForm = await innerUpdateMF(
      form,
      rootGetters["projects/isOfflineState"]
    );
    return measurementForm;
  },

  async getRecordBookForInstallationByType(
    context,
    { installationUuid, type }
  ) {
    return await db.recordBooks
      .where({
        installation: installationUuid,
        type: type,
      })
      .filter(({ isArchived }) => !isArchived)

      .toArray();
  },

  async filterRecordBooks(
    { commit, state },
    {
      queryAsObject,
      ordering,
      filterMapping,
      filterByDate = "",
      filterByInstallation = [],
    }
  ) {
    const recordBookOrder = [
      "environmental_condition",
      "speed",
      "flow",
      "analyser",
      "nozzle_diameter",
      "manual_sampling",
      "humidity",
    ];

    while (state.recordBookIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit(SET_IS_LOADING, true);
    const initialOrderingRequest = ordering;
    ordering = "";
    const postFetchSorting = true;
    // Because RecordBook sorting request are weird, we cannot use the generic QuerySet methods. They use OrderBy which is not suitable for our use cases
    const { queryset, count } = await buildQueryset(
      db.recordBooks,
      queryAsObject,
      ordering,
      filterMapping,
      postFetchSorting
    );
    let recordBooks = await queryset.toArray();

    const orderDesc = initialOrderingRequest[0] === "-" ? -1 : 1;
    for (const rb of recordBooks) {
      await rb.setRelationships();
      await rb.setDate();
    }

    // sorting intervention date
    if (initialOrderingRequest.includes("recordBlocks")) {
      recordBooks.sort((a, b) => {
        const dateA = dayjs(a.date, "DD/MM/YYYY HH:mm");
        const dateB = dayjs(b.date, "DD/MM/YYYY HH:mm");
        if (dateA === dateB) {
          return 0;
        } else if (dateA < dateB) {
          return -1 * orderDesc;
        } else return 1 * orderDesc;
      });
    }

    // sorting updated date
    if (initialOrderingRequest.includes("updatedAt")) {
      recordBooks.sort((a, b) => {
        if (dayjs(a.updatedAt) === dayjs(b.updatedAt)) return 0;
        else if (dayjs(a.updatedAt) < dayjs(b.updatedAt)) return -1 * orderDesc;
        else return 1 * orderDesc;
      });
    }

    // sorting installation Name
    if (initialOrderingRequest.includes("installation")) {
      recordBooks.sort((a, b) => {
        const installationA = a.installationObj ? a.installationObj.name : null;
        const installationB = b.installationObj ? b.installationObj.name : null;
        const alphaNameA = installationA
          ? installationA.replace(/\d/g, "").toLocaleLowerCase()
          : null;
        const alphaNameB = installationB
          ? installationB.replace(/\d/g, "").toLocaleLowerCase()
          : null;
        const numA = installationA ? parseInt(installationA) : null;
        const numB = installationB ? parseInt(installationB) : null;

        if (
          (!installationA && installationB) ||
          (alphaNameA > alphaNameB && !numA && !numB) ||
          (alphaNameA === alphaNameB && numA && numB && numA < numB)
        )
          return 1 * orderDesc;
        if (
          (installationA && !installationB) ||
          (alphaNameA < alphaNameB && !numA && !numB) ||
          (alphaNameA === alphaNameB && numA && numB && numA > numB)
        )
          return -1 * orderDesc;
        return 0;
      });
    }
    // sorting type
    if (initialOrderingRequest.includes("type")) {
      recordBooks.sort((a, b) => {
        if (recordBookOrder.indexOf(a.type) === recordBookOrder.indexOf(b.type))
          return 0;
        else if (
          recordBookOrder.indexOf(a.type) < recordBookOrder.indexOf(b.type)
        )
          return 1 * orderDesc;
        else return -1 * orderDesc;
      });
    }
    // Sorting archibed record book at the end
    recordBooks.sort((a, b) => {
      if ((a.isArchived && b.isArchived) || (!a.isArchived && !b.isArchived))
        return 0;
      if (a.isArchived && !b.isArchived) return 1;
      if (!a.isArchived && b.isArchived) return -1;
    });

    if (filterByDate !== "") {
      recordBooks = recordBooks.filter(({ date }) =>
        date.includes(filterByDate)
      );
    }

    if (filterByInstallation.length) {
      recordBooks = recordBooks.filter(({ installation }) =>
        filterByInstallation.includes(installation)
      );
    }

    const offset = queryAsObject.page_size * (queryAsObject.page - 1);
    const size = offset + queryAsObject.page_size;
    const recordBookSet = recordBooks.slice(offset, size);

    commit(SET_RECORD_BOOKS, recordBookSet);
    commit(SET_TOTAL_RECORD_BOOKS, count);
    commit(SET_IS_LOADING, false);
  },
};

const mutations = {
  [SET_CURRENT_RECORD_BOOK]: (state, recordBook) =>
    (state.currentRecordBook = recordBook),
  [SET_IS_LOADING]: (state, loading) => (state.recordBookIsLoading = loading),
  [SET_RECORD_BOOKS]: (state, recordBooks) =>
    (state.recordBookList = recordBooks),
  [SET_TOTAL_RECORD_BOOKS]: (state, totalRecordBooks) =>
    (state.totalRecordBooks = totalRecordBooks),
  [UPDATE_RECORD_BOOK]: (state, recordBook) => {
    const copy = [...state.recordBookList];
    const index = copy.findIndex((item) => recordBook.uuid == item.uuid);
    copy[index] = recordBook;
    state.recordBookList = [...copy];
  },
  [ADD_RECORD_BOOK]: (state, recordBook) => {
    state.recordBookList = [recordBook, ...state.recordBookList];
  },
};
function copyPrototype(form) {
  const sourcePrototype = new RecordBookSchema();
  let rb = Object.setPrototypeOf(form, sourcePrototype);
  return rb;
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
