import { buildQueryset } from "@/utils/queryset";
import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";

const state = {
  acquisitionRecordBookList: [],
  acquisitionIsLoading: "",
  totalAcquisitionCount: 0,
};

const SET_IS_LOADING = "SET_IS_LOADING";
const SET_ACQUISITION_RECORD_BOOKS = "SET_ACQUISITION_RECORD_BOOKS";
const SET_TOTAL_ACQUISITION_RECORD_BOOKS = "SET_TOTAL_ACQUISITION_RECORD_BOOKS";
const ADD_ACQUISITION_RECORD_BOOK = "ADD_ACQUISITION_RECORD_BOOK";
const DELETE_ACQUISITION_RECORD_BOOK = "DELETE_ACQUISITION_RECORD_BOOK";

const { innerCreate, innerUpdate, innerFetch, innerDelete } = crudGenerator(
  db.models.AcquisitionRecordBook
);

const actions = {
  async createAcquisitionRecordBook({ commit, rootGetters }, form) {
    commit(SET_IS_LOADING, true);

    const acquisitionrecordBook = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(ADD_ACQUISITION_RECORD_BOOK, acquisitionrecordBook);
    commit(SET_IS_LOADING, false);
  },

  async updateAcquisitionRecordBook({ commit, rootGetters }, form) {
    commit(SET_IS_LOADING, true);

    const acquisitionRecordBook = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(SET_IS_LOADING, false);

    return acquisitionRecordBook;
  },

  async fetchAcquisitionRecordBook({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },
  async deleteAcquisitionRecordBook({ rootGetters, commit }, uuid) {
    await innerDelete(
      uuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    commit(DELETE_ACQUISITION_RECORD_BOOK, uuid);
  },
  async filterAcquisitionRecordBooks(
    { commit, state },
    { queryAsObject, ordering, filterMapping }
  ) {
    while (state.acquisitionIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit(SET_IS_LOADING, true);
    const { queryset, count } = await buildQueryset(
      db.acquisitionRecordBooks,
      queryAsObject,
      ordering,
      filterMapping
    );
    const acquisitionrecordBooks = await queryset.toArray();
    for (const rb of acquisitionrecordBooks) {
      await rb.setRelationships();
    }
    commit(SET_ACQUISITION_RECORD_BOOKS, acquisitionrecordBooks);
    commit(SET_TOTAL_ACQUISITION_RECORD_BOOKS, count);
    commit(SET_IS_LOADING, false);
  },
};

const mutations = {
  [SET_IS_LOADING]: (state, loading) => {
    state.acquisitionIsLoading = loading;
  },
  [SET_ACQUISITION_RECORD_BOOKS]: (state, list) => {
    state.acquisitionRecordBookList = list;
  },
  [SET_TOTAL_ACQUISITION_RECORD_BOOKS]: (state, count) => {
    state.totalAcquisitionCount = count;
  },
  [ADD_ACQUISITION_RECORD_BOOK]: (state, recordBook) => {
    state.acquisitionRecordBookList = [
      recordBook,
      ...state.acquisitionRecordBookList,
    ];
    state.totalAcquisitionCount = state.totalAcquisitionCount + 1;
  },
  [DELETE_ACQUISITION_RECORD_BOOK]: (state, uuid) => {
    state.acquisitionRecordBookList = state.acquisitionRecordBookList.filter(
      (recordBook) => recordBook.uuid !== uuid
    );
    state.totalAcquisitionCount = state.totalAcquisitionCount - 1;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
