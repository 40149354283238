import { AgencyClient } from "@/api";
import db from "@/db";
import store from "@/store";

import crudGenerator from "@/api/utils/offlineSync";
const { innerFetch } = crudGenerator(db.models.Agency);
import { VUE_APP_ATMO_SERVICE_ID } from "@/env";

const SET_IS_LOADING = "SET_IS_LOADING";
const SET_CURRENT_PROJECT_AGENCY = "SET_CURRENT_PROJECT_AGENCY";

const state = {
  currentProjectAgency: null,
  agenciesIsLoading: false,
};

const getters = {};

const actions = {
  async setCurrentProjectAgency({ commit }, agencyCode) {
    commit(SET_IS_LOADING, true);
    const agency = await store.dispatch(
      "agencies/fetchAgencyByCode",
      agencyCode
    );
    commit(SET_CURRENT_PROJECT_AGENCY, agency);
    commit(SET_IS_LOADING, false);

    return agency;
  },
  fetchAgencies: async ({ commit, rootGetters }, metadata = {}) => {
    const filters = {
      ...metadata,
      application_name: VUE_APP_ATMO_SERVICE_ID,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);

    const response = await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );

    return response.results;
  },
  fetchAgencyByUuid: async (context, agencyUuid = "") => {
    const dexieAgency = await db.agencies
      .where("uuid")
      .equals(agencyUuid)
      .first();

    if (dexieAgency?.uuid) {
      return dexieAgency;
    }

    const agency = await AgencyClient.retrieve({ uuid: agencyUuid });

    await db.models.Agency.createOrUpdate(agency);
    return agency;
  },
  fetchAgencyByCode: async (context, agencyCode = "") => {
    const dexieAgency = await db.agencies
      .where("code")
      .equals(agencyCode)
      .first();

    if (dexieAgency?.uuid) {
      return dexieAgency;
    }

    const agency = await AgencyClient.retrieveByCode({
      code: agencyCode,
    });
    await db.models.Agency.createOrUpdate(agency);
    return agency;
  },
  fetchAgencyByUserUuid: async (context, userUuid) => {
    const agency = AgencyClient.retrieveByUserUuid({ uuid: userUuid });

    return agency;
  },
};

const mutations = {
  [SET_CURRENT_PROJECT_AGENCY]: (state, agency) =>
    (state.currentProjectAgency = agency),

  [SET_IS_LOADING]: (state, agenciesIsLoading) => {
    state.agenciesIsLoading = agenciesIsLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
