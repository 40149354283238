import BaseSchema from "./base";

class Flow extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "flows";
  static fields = [
    "uuid",
    "parameter",
    "vle",
    "createdAt",
    "updatedAt",
    "flowRate",
    "volumeSolution",
    "bubblerQuantificationLimit",
    "filterQuantificationLimit",
    "rinseQuantificationLimit",
  ];

  static dateFields = ["createdAt", "updatedAt"];

  static relatedFields = {
    parameterObject: {
      field: "parameter",
      tableName: "parameters",
    },
    vleObject: {
      field: "vle",
      tableName: "vles",
    },
  };
}

export default Flow;
