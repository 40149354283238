import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.FlowControllerPromiseClient;

const changeValueFrom0ToNull = (response, result) => {
  if (!response.hasRinseQuantificationLimit())
    result.rinseQuantificationLimit = null;
  if (!response.hasFilterQuantificationLimit())
    result.filterQuantificationLimit = null;
  if (!response.hasBubblerQuantificationLimit())
    result.bubblerQuantificationLimit = null;
  if (!response.hasFlowRate()) result.flowRate = null;
  if (!response.hasVolumeSolution()) result.volumeSolution = null;
  return result;
};
export const FlowClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.FlowListRequest();
    const response = await grpcClient.list(request, metadata);
    const resultsList = response.getResultsList().map((res) => {
      let resultObject;
      resultObject = { ...res.toObject() };
      resultObject = changeValueFrom0ToNull(res, resultObject);
      return resultObject;
    });

    return resultsList;
  },
  create: async (flow) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.FlowRequest,
      flow
    );
    const response = await grpcClient.create(request, {});
    let resultObject = response.toObject();
    resultObject = changeValueFrom0ToNull(response, resultObject);

    return resultObject;
  },
  bulkCreate: async (flows) => {
    const request = new requests.FlowBulkCreateRequest();

    for (const flow of flows) {
      const flowRequest = socioGrpcClient.javascriptToRequest(
        requests.FlowRequest,
        flow
      );
      request.addFlows(flowRequest);
    }

    const response = await grpcClient.bulkCreate(request, {});
    const resultsList = response.getResultsList().map((res) => {
      let resultObject;
      resultObject = { ...res.toObject() };
      resultObject = changeValueFrom0ToNull(res, resultObject);
      return resultObject;
    });

    return resultsList;
  },

  update: async (flow) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.FlowRequest,
      flow
    );
    const response = await grpcClient.update(request, {});
    let resultObject = response.toObject();
    resultObject = changeValueFrom0ToNull(response, resultObject);

    return resultObject;
  },
  bulkUpdate: async (flows) => {
    const request = new requests.FlowBulkUpdateRequest();

    for (const flow of flows) {
      const flowRequest = socioGrpcClient.javascriptToRequest(
        requests.FlowRequest,
        flow
      );
      request.addFlows(flowRequest);
    }

    const response = await grpcClient.bulkUpdate(request, {});
    const resultsList = response.getResultsList().map((res) => {
      let resultObject;
      resultObject = { ...res.toObject() };
      resultObject = changeValueFrom0ToNull(res, resultObject);
      return resultObject;
    });

    return resultsList;
  },
  bulkDestroy: async (flowsUuids) => {
    const data = {
      flowsUuids,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.FlowBulkDestroyRequest,
      data
    );
    const response = await grpcClient.bulkDestroy(request, {});
    return response.toObject();
  },
};
