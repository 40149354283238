import BaseSchema from "./base";
import dayjs from "dayjs";
import _ from "lodash";
import LINES from "@/db/enums/lines";
import { ChainManager } from "@/store/utils/sampleUtils";
import usePipeSamplingPoints from "@/composables/recordbooks/usePipeSamplingPoints";

class MeasurementForm extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "measurementForms";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "setup",
    "started",
    "measurements",
    "events",
    "samples",
    "recordBook",
    "startingTime",
    "theoricTimeline",
    "pauses",
    "leakTests",
    "currentSampleUuid",
    "currentAxeIndex",
    "project",
    "finished",
  ];

  static dateFields = ["createdAt", "updatedAt"];
  static relatedFields = {
    recordBookObj: {
      field: "recordBook",
      tableName: "recordBooks",
    },
  };

  castStringToDatetime() {
    this.measurements = [
      ...this.measurements.map((m) => ({
        ...m,
        start: dayjs(m.start).toDate(),
      })),
    ];
    this.pauses = [
      ...this.pauses.map((p) => ({
        ...p,
        start: dayjs(p.start).toDate(),
        end: dayjs(p.end).toDate(),
      })),
    ];
    this.leakTests = [
      ...this.leakTests.map((l) => ({
        ...l,
        start: dayjs(l.start).toDate(),
      })),
    ];
    this.setup.startingDatetime = dayjs(this.setup.startingDatetime).toDate();
  }

  async buildSetup() {
    // if (this.started) return;
    await this.setRelationships();
    const { computeSamplingPoints } = usePipeSamplingPoints();
    const { phase } = this.recordBookObj;
    const chain = await ChainManager.initialize({ phaseUuid: phase });
    const samples = await chain.groupByLineType();
    await this.recordBookObj.setRelationships();
    const measuringSide = this.recordBookObj.speedObj.getForm(
      "speed_general_informations_form"
    ).measuringSide;
    const axes = await computeSamplingPoints(
      this.recordBookObj.installationObj,
      measuringSide
    );

    // TODO - B.L - 01/07/2023 - MOVE IT SOMEWHERE ELSE
    if (!this.leakTests) this.leakTests = [];

    if (!this.currentSampleUuid) {
      if (samples[LINES.LIGNE_PRINCIPALE]) {
        this.currentSampleUuid = samples[LINES.LIGNE_PRINCIPALE][1].uuid;
      } else {
        this.currentSampleUuid = samples[LINES.LIGNE_SECONDAIRE][1][1].uuid;
      }
    }
    if (!this.currentAxeIndex) this.currentAxeIndex = 0;
    const interventionDate =
      this.recordBookObj.environmentalConditionObj.getForm(
        "environmental_condition_form"
      ).interventionDate;
    const startingDatetime = dayjs(interventionDate).startOf("date");

    const totalDuration = await this.recordBookObj.getDurationAsInt();

    const keyToskip = ["name", "number"];

    const totalAxes = Object.keys(axes).length;
    const pointPerAxe = Object.keys(axes[0]).filter(
      (n) => !keyToskip.includes(n)
    ).length;
    const totalPoints = totalAxes * pointPerAxe;

    const stepperAxes = {};

    for (const [key, value] of Object.entries(axes)) {
      const innerObject = { valid: false };

      const points = {};
      for (const [innerKey, innerValue] of Object.entries(value)) {
        if (keyToskip.includes(innerKey)) {
          innerObject[innerKey] = innerValue;
          continue;
        }
        innerObject[innerKey] = {
          distance: innerValue,
          valid: false,
        };
        points[innerKey] = {
          distance: innerValue,
          valid: false,
        };
      }
      innerObject.points = points;
      stepperAxes[key] = innerObject;
    }
    let axesBySample = {};
    let isDioxOrHap = false;
    let samplesCount = 1;
    if (samples[LINES.LIGNE_PRINCIPALE]) {
      axesBySample = Object.fromEntries(
        Object.values(samples[LINES.LIGNE_PRINCIPALE]).map((sample) => {
          return [sample.uuid, _.cloneDeep(stepperAxes)];
        })
      );
      samplesCount = Object.keys(samples[LINES.LIGNE_PRINCIPALE]).length;

      if (samples[LINES.LIGNE_PRINCIPALE][1]) {
        const s = await this.db.samples.get(samples[LINES.LIGNE_PRINCIPALE][1]);
        isDioxOrHap = await s.isDioxOrHap();
      }
    }

    let durationPerPoint = Math.floor(
      totalDuration / (totalPoints * samplesCount - 1)
    );

    if (durationPerPoint === null) durationPerPoint = 0;
    if (isNaN(durationPerPoint)) durationPerPoint = 0;
    if (durationPerPoint === Infinity) durationPerPoint = 0;

    this.setup = {
      phaseUuid: phase,
      axes: axes,
      axesBySample,
      startingDatetime: startingDatetime.toDate(),
      duration: {
        total: totalDuration,
        perPoint: durationPerPoint,
      },
      samples: samples,
      isDioxOrHap: isDioxOrHap,
    };
  }

  expandAxe(sampleUuid, axeIndex) {
    axeIndex = parseInt(axeIndex);
    const axe = this.setup.axesBySample[sampleUuid][axeIndex];
    if (axe.expand === undefined) axe.expand = false;
    else axe.expand = !axe.expand;
    return this.setup;
  }

  getAxes(sampleUuid) {
    try {
      const axes = this.setup.axesBySample[sampleUuid];
      return axes ? axes : [];
    } catch {
      return [];
    }
  }

  lastAxeIsValid(sampleUuid, axeIndex) {
    axeIndex = parseInt(axeIndex);
    if (axeIndex === 0) return true;
    return this.setup.axesBySample[sampleUuid][axeIndex - 1].valid;
  }

  validatePoint(pointNumber, sampleUuid, axeIndex) {
    pointNumber = parseInt(pointNumber);
    let axe = this.setup.axesBySample[sampleUuid][axeIndex];
    axe.points[pointNumber].valid = true;
    this.setup.axesBySample[sampleUuid][axeIndex] = axe;
    return this.setup;
  }

  makeDate(time) {
    const [hour, minute, second] = time.split(":");
    let date = dayjs(this.setup.startingDatetime);
    if (!hour) return date;
    date = date.add(parseInt(hour), "hour").add(parseInt(minute), "minute");
    if (second) {
      date = date.add(parseInt(second), "second");
    }
    return date;
  }

  addLeakTest(time) {
    if (!this.leakTests) this.leakTests = [];
    this.leakTests.push({
      type: "leakTest",
      valid: true,
      start: this.makeDate(time).toDate(),
    });
  }

  addPause({ start, end, volumeStart, volumeEnd }) {
    const startDatetime = this.makeDate(start);
    const endDatetime = this.makeDate(end);
    this.pauses.push({
      type: "pause",
      duration: Math.abs(startDatetime.diff(endDatetime, "second")),
      start: startDatetime.toDate(),
      end: endDatetime.toDate(),
      volumeStart,
      volumeEnd,
    });
  }
  addAxeSwap({ start, end }) {
    const startDatetime = this.makeDate(start);
    const endDatetime = this.makeDate(end);
    this.pauses.push({
      type: "axeSwap",
      duration: Math.abs(startDatetime.diff(endDatetime, "second")),
      start: startDatetime.toDate(),
      end: endDatetime.toDate(),
    });
  }

  flattenPoints() {
    const points = [];
    let sampleCount = 0;
    for (const [sampleUuid, sample] of Object.entries(
      this.setup.axesBySample
    )) {
      sampleCount++;
      let axeCount = 0;
      for (const axe of Object.values(sample)) {
        axeCount++;
        let pointCount = 0;
        for (const pointNumber of Object.keys(axe.points)) {
          pointCount++;
          points.push({
            path: `${sampleCount}.${axeCount}.${pointCount}`,
            axeNumber: axe.number,
            pointNumber,
            sampleUuid,
            type: "theoricMeasure",
          });
        }
      }
    }
    return points;
  }

  getNextMeasurementTiming(lastDateTime, interval, regulatory = false) {
    if (!lastDateTime) return this.makeDate(this.startingTime);
    let newDate = lastDateTime.add(interval, "second");
    const isNotUsed = (pause) => {
      if (regulatory) return pause.used < 2;
      return !pause.used;
    };
    const pauseIndex = this.pauses.findIndex(
      (p) => p.start >= lastDateTime && p.start < newDate && isNotUsed(p)
    );
    if (pauseIndex >= 0) {
      const pause = this.pauses[pauseIndex];
      // INFO - B.L - 14/06/2023 - Mark pause as used to avoid counting them twice with the delay
      this.pauses[pauseIndex].used++;
      const offsetTime = lastDateTime.add(pause.duration, "second");
      return this.getNextMeasurementTiming(offsetTime, interval, regulatory);
    }

    return newDate;
  }

  resetPauses() {
    this.pauses = [
      ...this.pauses.map((pause) => ({
        ...pause,
        used: 0,
      })),
    ];
  }

  buildPrincipalTheroicTimeline() {
    const points = this.flattenPoints();
    this.resetPauses();
    let regulatoryInterval = 30;
    if (this.setup.isDioxOrHap) regulatoryInterval = 15;

    let currentTime = null;
    const timeline = [];
    let previousPoint = null;
    let axeSwapCount = 0;
    this.pauses = this.pauses.filter(({ type }) => type != "theoricAxeSwap");
    const axeSwap = this.pauses.filter(({ type }) => type == "axeSwap");
    for (const [index, point] of points.entries()) {
      // INFO - B.L - 21/06/2023 - The law say that a measure must be made at least every 30 minutes
      let retCurrentTime = currentTime;
      currentTime = this.getNextMeasurementTiming(
        currentTime,
        this.setup.duration.perPoint
      );
      const shouldMakeMeasure = (time) => {
        return time && currentTime.diff(time, "minute") > 30;
      };

      while (shouldMakeMeasure(retCurrentTime)) {
        retCurrentTime = this.getNextMeasurementTiming(
          retCurrentTime,
          regulatoryInterval * 60,
          true
        );
        timeline.push({
          start: retCurrentTime,
          ...previousPoint,
          type: "theoricIntermediateMeasure",
        });
      }
      const shouldSwapAxe =
        index &&
        point.axeNumber != points[index - 1].axeNumber &&
        point.sampleUuid == points[index - 1].sampleUuid;
      if (shouldSwapAxe) {
        axeSwapCount++;
        if (axeSwapCount > axeSwap.length) {
          // INFO - B.L - 14/06/2023 - When the axe is swapped the all system is shut for about 2 minutes
          const endOfSwap = currentTime.add(2, "minute");
          const axeSwap = {
            start: currentTime.toDate(),
            end: endOfSwap.toDate(),
            duration: 120,
            type: "theoricAxeSwap",
          };
          this.pauses.push(axeSwap);
          currentTime = endOfSwap;
        }
      }

      timeline.push({
        start: currentTime.toDate(),
        ...point,
      });
      previousPoint = point;
    }
    return timeline;
  }

  consolidatePrincipalTimeline() {
    if (!this.hasPrincipal) return [];
    const theoricTimeline = this.buildPrincipalTheroicTimeline();
    const sampleUuids = Object.values(this.getPrincipal()).map(
      ({ uuid }) => uuid
    );
    const measurements = _.sortBy(this.measurements, "start").filter(
      ({ sampleUuid }) => sampleUuids.includes(sampleUuid)
    );

    const lastMeasure = _.last(measurements, "start");
    if (!lastMeasure) {
      return _.sortBy(
        this.leakTests.concat(theoricTimeline).concat(this.pauses),
        "start"
      );
    }

    const theoricMeasurements = theoricTimeline.filter(
      (e) => e.start > lastMeasure.start
    );

    const events = measurements
      .concat(theoricMeasurements)
      .concat(this.pauses)
      .concat(this.leakTests);
    return _.sortBy(events, "start");
  }

  buildSecondaryTimeline() {
    // INFO - B.L - 14/06/2023 - Secondary line must have a measurement every 30 minutes / 1800 seconds
    let interval = 30 * 60;
    if (this.setup.isDioxOrHap) interval = 15 * 60;
    // INFO - B.L - 14/06/2023 - adding + 1 for the first measurement at the start
    const measurementsToDo =
      Math.floor(this.setup.duration.total / interval) + 1;
    const timeline = [];
    let time = this.makeDate(this.startingTime);
    this.resetPauses();
    for (let count = 0; count < measurementsToDo; count++) {
      timeline.push({ type: "theoricMeasure", start: time.toDate() });
      try {
        time = this.getNextMeasurementTiming(time, interval);
      } catch (e) {
        console.error(e);
      }
    }
    return timeline;
  }

  consolidateSecondaryTimelines() {
    const timelines = {};
    if (!this.hasSecondaries) return timelines;
    const theoricTimeline = this.buildSecondaryTimeline();
    for (const [lineNumber, line] of Object.entries(this.getSecondaries())) {
      const sampleUuids = Object.values(line).map(({ uuid }) => uuid);
      const measurements = _.sortBy(
        this.measurements.filter(({ sampleUuid }) =>
          sampleUuids.includes(sampleUuid)
        ),
        "start"
      );
      const lastMeasure = _.last(measurements, "start");
      timelines[lineNumber] = {};
      let chunkSize = _.size(theoricTimeline);
      const lineSize = _.size(line);
      if (lineSize > 1) {
        chunkSize = Math.ceil(chunkSize / lineSize);
      }
      const chunkedTimeline = _.chunk(theoricTimeline, chunkSize);
      for (let [index, chunk] of chunkedTimeline.entries()) {
        chunkedTimeline[index] = chunk.filter((event) => {
          // INFO - B.L - 02/07/2023 - Clearing all intermediate theoric measure if a measure has been done on the next sample
          if (index < chunkedTimeline.length - 1) {
            const nextSampleStarted = measurements.filter(
              ({ sampleUuid }) => sampleUuids[index + 1] == sampleUuid
            ).length;
            // INFO - B.L - 02/07/2023 - Moving theoric measure to the next sample to keep the timeline cooerant
            if (nextSampleStarted) {
              chunkedTimeline[index + 1].push(event);
              return false;
            }
          }
          // INFO - B.L - 02/07/2023 - clearing all theoric measur inferior to the last real measure
          return !lastMeasure || event.start > lastMeasure.start;
        });
      }
      let previousMax = null;
      for (const [index, sample] of Object.values(line).entries()) {
        let tmp = []
          .concat(
            chunkedTimeline[index].map((item) => ({
              ...item,
              sampleUuid: sample.uuid,
            }))
          )
          .concat(
            ...measurements.filter(
              ({ sampleUuid }) => sampleUuid == sample.uuid
            )
          );
        const max = _.maxBy(tmp, "start");
        const min = _.minBy(tmp, "start");
        const pauses = this.pauses.filter((p) => {
          // INFO - B.L - 03/07/2023 - Adding pause stuck in nowhere
          if (previousMax && p.start > previousMax && p.start <= min.start)
            return true;
          if (!previousMax && p.start < min.start) return true;
          if (index == Object.values(line).length - 1 && p.start > max.start)
            return true;
          // INFO - B.L - 03/07/2023 - Normal case
          return p.start >= min.start && p.end < max.start;
        });
        tmp = tmp.concat(pauses);
        timelines[lineNumber][sample.uuid] = _.sortBy(tmp, "start");
        previousMax = max.start;
      }
    }
    return timelines;
  }

  getPrincipal() {
    return this.samples[LINES.LIGNE_PRINCIPALE];
  }
  getSecondaries() {
    return this.samples[LINES.LIGNE_SECONDAIRE];
  }

  getSampleNumber(sampleUuid) {
    if (!sampleUuid) sampleUuid = this.currentSampleUuid;
    for (const [key, value] of Object.entries(this.getPrincipal())) {
      if (value.uuid == sampleUuid) return parseInt(key);
    }
  }

  getSampleByNumber(sampleNumber) {
    return this.getPrincipal()[sampleNumber];
  }

  isLastSample(number) {
    if (!number) number = this.getSampleNumber();
    return number == Object.keys(this.getPrincipal()).length;
  }

  isLastAxe() {
    if (_.isEmpty(this.setup.axesBySample)) return true;
    return (
      this.currentAxeIndex ==
      Object.keys(this.setup.axesBySample[this.currentSampleUuid]).length - 1
    );
  }

  allPointsAreValid() {
    if (_.isEmpty(this.setup.axesBySample)) return true;
    let axe =
      this.setup.axesBySample[this.currentSampleUuid][this.currentAxeIndex];
    return Object.values(axe.points).every((point) => {
      return point.valid != undefined && point.valid;
    });
  }

  validateAxe() {
    if (this.hasPrincipal) return this.setup;
    this.setup.axesBySample[this.currentSampleUuid][
      this.currentAxeIndex
    ].valid = true;

    if (!this.isLastAxe()) {
      this.currentAxeIndex += 1;
      return this.setup;
    }
    const sampleNumber = this.getSampleNumber();
    if (!this.isLastSample(sampleNumber)) {
      this.currentSampleUuid = this.getPrincipal()[sampleNumber + 1].uuid;
      this.currentAxeIndex = 0;
    }
    return this.setup;
  }

  isPreviousSampleFinished(sampleUuid) {
    const sampleNumber = this.getSampleNumber(sampleUuid);
    if (sampleNumber == 1) return true;
    const previousSample = this.getSampleByNumber(sampleNumber - 1);
    return Object.values(this.setup.axesBySample[previousSample.uuid]).every(
      (axe) => axe.valid
    );
  }

  filterMeasurementsBySample(samples) {
    if (!Array.isArray(samples)) samples = [samples];
    return this.measurements.filter((measurement) =>
      samples.includes(measurement.sampleUuid)
    );
  }

  get hasPrincipal() {
    return Object.keys(this.getPrincipal()).length;
  }
  get hasSecondaries() {
    return Object.keys(this.getSecondaries()).length;
  }
}

export default MeasurementForm;
