import BaseSchema from "./base";

class AdminInformation extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "adminInformations";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "dateOfficialJournal",
    "version",
    "accreditationNumber",
  ];

  static dateFields = ["createdAt", "updatedAt"];
}

export default AdminInformation;
