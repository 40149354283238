import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.contributors.contributors;
const grpcClient =
  socioGrpcClient.contributors.contributors.RoleControllerPromiseClient;

export const RoleClient = {
  list: async (metadata) => {
    const requestMetadata = buildMetadata(metadata);

    const request = new requests.RoleListRequest();
    const response = await grpcClient.list(request, requestMetadata);

    return response.toObject();
  },
};
