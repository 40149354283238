import BaseSchema from "./base";

class Contributor extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "contributors";
  static fields = [
    "uuid",
    "role",
    "roleId",
    "company",
    "metadata",
    "createdAt",
    "updatedAt",
    "roleAlias",
    "userEmail",
    "userFirstName",
    "userLastName",
    "userPhoneNumber",
    "incumbentCompanyName",
    "invitationByEmail",
    "arrivalDate",
    "plannedEndDate",
    "isMainContact",
    "isMandatoryRecipient",
    "isArchived",
    "isLocked",
    "usermanagementUuid",
    "companyProjectRoleId",
    "companyProjectRole",
  ];

  static relatedFields = {
    role: {
      field: "roleId",
      dataName: "role",
      tableName: "roles",
    },
    companyProjectRole: {
      field: "companyProjectRoleId",
      dataName: "companyProjectRole",
      tableName: "companyProjectRoles",
    },
  };

  static dateFields = [
    "createdAt",
    "updatedAt",
    "arrivalDate",
    "plannedEndDate",
  ];
}

export default Contributor;
