import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations
    .ConfigurationLineControllerPromiseClient;

export const ConfigurationLineClient = {
  list: async (filters) => {
    console.log("Called list of ConfigurationLineClient");
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.ConfigurationLineListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);

    return response;
  },
  create: async (configurationLine) => {
    console.log("Called create of ConfigurationLineClient");
    const request = socioGrpcClient.javascriptToRequest(
      requests.ConfigurationLineRequest,
      configurationLine
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  update: async (configurationLine) => {
    console.log("Called update of ConfigurationLineClient");
    const request = socioGrpcClient.javascriptToRequest(
      requests.ConfigurationLineRequest,
      configurationLine
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
  bulkCreate: async (configurationLines) => {
    console.log("Called bulkCreate of ConfigurationLineClient");
    const request = new requests.ConfigurationLineBulkCreateRequest();

    for (const configurationLine of configurationLines) {
      const configurationLineRequest = socioGrpcClient.javascriptToRequest(
        requests.ConfigurationLineRequest,
        configurationLine
      );

      request.addConfigurationLines(configurationLineRequest);
    }

    const response = await grpcClient.bulkCreate(request, {});

    return response.toObject().resultsList;
  },
  destroy: async (configurationLineUuid) => {
    console.log("Called destroy of ConfigurationLineClient");
    const data = {
      uuid: configurationLineUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.ConfigurationLineDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
};
