import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations
    .InstallationTemplateControllerPromiseClient;

export default {
  list: async () => {
    const request = new requests.InstallationTemplateListRequest();
    const response = await grpcClient.utils.listAllObjects(request, {});

    return response;
  },

  createFromTemplate: async (projectUuid, form) => {
    const request =
      new requests.InstallationTemplateCreateInstallationFromTemplateRequest();
    request.setUuid(form.templateUuid);
    request.setName(form.name);
    request.setProjectUuid(projectUuid);
    request.setDuplicateRecordForm(form.duplicateRecordForm);
    const response = await grpcClient.createInstallationFromTemplate(
      request,
      {}
    );

    return response.toObject();
  },

  createTemplateFromInstallation: async (installation) => {
    const request =
      new requests.InstallationTemplateCreateInstallationTemplateRequest();
    request.setUuid(installation.uuid);
    request.setName(installation.name);
    const response = await grpcClient.createInstallationTemplate(request, {});

    return response.toObject();
  },
};
