import BaseSchema from "./base";

class Constat extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "constats";
  static fields = [
    "uuid",
    "parameterName",
    "familyName",
    "normeName",
    "wording",
    "impactMeasure",
    "impactConformity",
    "uniqueKey",
    "createdAt",
    "updatedAt",
  ];

  static dateFields = ["createdAt", "updatedAt"];
}

export default Constat;
