import BaseSchema from "./base";

class Sum extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "sums";
  static fields = [
    "uuid",
    "installation",
    "parametersList",
    "createdAt",
    "updatedAt",
  ];

  static dateFields = ["createdAt", "updatedAt"];

  static relatedFields = {
    parameters: {
      m2m: true,
      owner: true,
      relatedKey: "parametersList",
      tableName: "parameters",
    },
    vle: {
      reversedKey: "sum",
      tableName: "vles",
      cascade: true,
      blank: true,
    },
  };
}

export default Sum;
