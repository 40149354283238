import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.InstallationControllerPromiseClient;

export const InstallationClient = {
  list: async (filters) => {
    console.log("Called list on Installation Client");
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.InstallationListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);
    return response;
  },
  destroy: async (installationUuid) => {
    const data = {
      uuid: installationUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.InstallationDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
  create: async (installation) => {
    // TODO - PH - 24/11/2022 - Find a way to add it in default in atmo-back.
    if (!installation.resultsExpression)
      installation.resultsExpression = "GAZ_SEC";
    const request = socioGrpcClient.javascriptToRequest(
      requests.InstallationRequest,
      installation
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  update: async (data) => {
    console.log("Called update on InstallationClient");
    const request = socioGrpcClient.javascriptToRequest(
      requests.InstallationRequest,
      data
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
};
