const MeasurementPlan = () =>
  import(
    /* webpackChunkName: "MeasurementPlan" */ "@/components/atmo/projects/project/measurement-plan/MeasurementPlan"
  );

export default [
  {
    path: "",
    name: "project-measurement-plan",
    component: MeasurementPlan,
  },
];
