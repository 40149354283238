import snakecaseKeys from "snakecase-keys";

import { filtersToGet } from "@/utils/metadata";
import { camelizeKeys } from "@/utils/utilsGrpcRest";
import { atmoClient } from "@/setup/atmoClient";

const RecordBookClient = {
  list: async (filters) => {
    let url = "recordbooks/";
    if (Object.keys(filters)) {
      url += filtersToGet(filters);
    }
    const response = await atmoClient.get(url);
    return camelizeKeys(response.data);
  },
  create: async (recordBook) => {
    const response = await atmoClient.post(
      "recordbooks/",
      snakecaseKeys(recordBook)
    );
    return camelizeKeys(response.data);
  },

  update: async (recordBook) => {
    const response = await atmoClient.put(
      `recordbooks/${recordBook.uuid}/`,
      snakecaseKeys(recordBook)
    );
    return camelizeKeys(response.data);
  },

  destroy: async (uuid) => {
    console.log("delete " + uuid);
  },
};

export default RecordBookClient;

// INFO - B.L - 15/03/2023 - If someone is crazy enough to use grpc again for this part
// This code is close to work but still has issue with structs and SchemaField

// const requests = socioGrpcClient.atmo_back.record_books;
// const grpcClient =
//   socioGrpcClient.atmo_back.record_books.RecordBookControllerPromiseClient;

// INFO - B.L - 15/03/2023 - Override because original has issue with nested struct + request
// const javascriptToRequest = (RequestClass, jsData, exclude = []) => {
//   const request = new RequestClass();
//   Object.keys(jsData).forEach((key) => {
//     if (exclude.includes(key)) {
//       return;
//     }
//     // tranform for example name to setName
//     let grpcWebKeyName = "";
//     if (Array.isArray(jsData[key])) {
//       grpcWebKeyName = `set${key.charAt(0).toUpperCase()}${key.slice(1)}${
//         !key.includes("List") ? "List" : ""
//       }`;
//     } else {
//       grpcWebKeyName = `set${key.charAt(0).toUpperCase()}${key.slice(1)}`;
//     }
//     if (!request[grpcWebKeyName]) {
//       // console.warn(
//       //   `Trying to set the property ${grpcWebKeyName} in the request class ${RequestClass} but it's not existing`
//       // );
//       return;
//     }
//     if (
//       // INFO - B.L - 13/02/2023 - Check if it's an object
//       typeof jsData[key] === "object" &&
//       !Array.isArray(jsData[key]) &&
//       jsData[key] !== null &&
//       // INFO - B.L - 13/02/2023 - Check if it's not a pb_message
//       !jsData[key].toObject
//     ) {
//       const struct = new google_protobuf_struct_pb.Struct.fromJavaScript(
//         JSON.parse(JSON.stringify(jsData[key]))
//       );
//       request[grpcWebKeyName](struct);
//     } else {
//       request[grpcWebKeyName](jsData[key]);
//     }
//     console.log(grpcWebKeyName);
//   });
//   return request;
// };

// const _formToRequest = {
//   analyser_general_information_form:
//     requests.AnalyserGeneralInformationFormRequest,
//   analyser_zero_adjustment_form: requests.AnalyserZeroAdjustmentFormRequest,
//   analyser_data_collection_form: requests.AnalyserDataCollectionFormRequest,
//   analyser_control_form: requests.AnalyserControlFormRequest,
//   manual_sampling_general_informations_form:
//     requests.ManualSamplingGeneralInformationsFormRequest,
//   manual_sampling_material_form: requests.ManualSamplingMaterialFormRequest,
//   manual_sampling_notebook_form: requests.ManualSamplingNoteBookFormRequest,
//   nozzle_diameter_calculations_information_form:
//     requests.NozzleDiameterCalculationsInformationFormRequest,
//   nozzle_diameter_calculation_form:
//     requests.NozzleDiameterCalculationFormRequest,
//   speed_general_informations_form: requests.SpeedGeneralInformationsFormRequest,
//   speed_material_form: requests.SpeedMaterialFormRequest,
//   speed_measurement_form: requests.SpeedMeasurementFormRequest,
// };

// const _castRecordFormToRequest = (blockType, recordForm) => {
//   if (!_formToRequest[blockType]) {
//     console.error(
//       `block type: ${blockType} as no mapping in const _formToRequest SKIPPING`
//     );
//     return;
//   }

//   const cameledKey = blockType.replace(/([_][a-z])/g, (match) =>
//     match.toUpperCase().replace("_", "")
//   );

//   return javascriptToRequest(requests.RecordFormRequest, {
//     uuid: recordForm.uuid,
//     createdAt: recordForm.createdAt,
//     isValidated: recordForm.isValidated,
//     [cameledKey]: javascriptToRequest(_formToRequest[blockType], recordForm),
//   });
// };

// const _castRecordBookToRequest = (recordBook) => {
//   const recordBlocks = recordBook.recordBlocks.map((block) => {
//     const recordForm = _castRecordFormToRequest(block.type, block.recordForm);
//     return socioGrpcClient.javascriptToRequest(requests.RecordBlockRequest, {
//       recordForm,
//       uuid: block.uuid,
//       createdAt: block.createdAt,
//       type: block.type,
//     });
//   });
//   return socioGrpcClient.javascriptToRequest(requests.RecordBookRequest, {
//     uuid: recordBook.uuid,
//     type: recordBook.type,
//     label: recordBook.label,
//     project: recordBook.project,
//     installation: recordBook.installation,
//     recordBlocks,
//   });
// };

// const _castResponseToRecordBook = (request) => {
//   // INFO - B.L - 15/02/2023 - Don't ask me why, ask A.M why grpc
//   let recordBook = request.toObject();
//   recordBook.recordBlocks = request.getrecordBlocks().map((blockResponse) => {
//     const block = blockResponse.toObject();
//     let cameledKey = block.type.replace(/([_][a-z])/g, (match) =>
//       match.toUpperCase().replace("_", "")
//     );
//     return {
//       ...block,
//       recordForm: {
//         uuid: block.recordForm.uuid,
//         ...block.recordForm[cameledKey],
//       },
//     };
//   });
//   return recordBook;
// };

// const RecordBookClient = {

//   create: async (recordBook) => {
//    const request = _castRecordBookToRequest(recordBook);
//    const response = await grpcClient.create(request, {});
//    const obj = _castResponseToRecordBook(response);
//    return obj;

//   },

//   update: async (recordBook) => {
//     const request = _castRecordBookToRequest(recordBook);
//     const response = await grpcClient.update(request, {});
//     const obj = _castResponseToRecordBook(response);
//     return obj;
//   },
// };
