import store from "@/store";
import ProjectChildren from "@/router/atmo/projects/project/children";
import i18n from "@/setup/i18n";

const ProjectsList = () =>
  import(
    /* webpackChunkName: "ProjectsList" */ "@/components/atmo/projects/ProjectsList"
  );

const Project = () =>
  import(/* webpackChuckName "Project" */ "@/components/atmo/projects/Project");

// const fetchInstallations = async (to, from, next) => {
//   store.dispatch("installations/fetchInstallations", to.params.projectId);
//   next();
// };

const messages = {
  fr: {
    errorProjectFetch:
      "Une erreur est survenue lors de la récupération du projet",
  },
  en: {
    errorProjectFetch: "An error occurred while fetching the project",
  },
};

i18n.mergeLocaleMessage("fr", messages.fr);
i18n.mergeLocaleMessage("en", messages.en);

const fetchProject = async (to, from, next) => {
  if (to.params.projectId !== from.params.projectId) {
    await store.resetStates();
    try {
      await store.dispatch("projects/syncFullProject", to.params.projectId);
    } catch (error) {
      store.dispatch(
        "notifications/showErrorNotification",
        i18n.t("errorProjectFetch")
      );
      next({ name: "cases" });
      return;
    }
    await store.dispatch("projects/setCurrentProject", to.params.projectId);
    await store.dispatch("contributors/setUserCanChange", to.params.projectId);
  }
  next();
};

export default [
  {
    path: "",
    name: "projects",
    component: ProjectsList,
  },
  {
    path: ":projectId",
    component: Project,
    children: ProjectChildren,
    beforeEnter: fetchProject,
  },
];
