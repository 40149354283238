import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.analysis;
const grpcClient =
  socioGrpcClient.atmo_back.analysis.AnalysisResultControllerPromiseClient;

export const AnalysisResultClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.AnalysisResultListRequest();

    const response = await grpcClient.utils.listAllObjects(request, metadata);
    return response;
  },

  create: async (analysisResult) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.AnalysisResultRequest,
      analysisResult
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },

  update: async (analysisResult) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.AnalysisResultRequest,
      analysisResult
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
};
