import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.user_management.entities;
const grpcClient =
  socioGrpcClient.user_management.entities.AgencyControllerPromiseClient;

export const AgencyClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.AgencyListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);
    return response;
  },

  retrieve: async ({ uuid }) => {
    const request = new requests.AgencyRetrieveRequest();
    request.setUuid(uuid);

    const response = await grpcClient.retrieve(request, {});
    const agency = response.toObject();

    return agency;
  },
  retrieveByUserUuid: async ({ uuid }) => {
    const request = new requests.AgencyRetrieveUserAgencyRequest();

    request.setUuid(uuid);

    const response = await grpcClient.retrieveUserAgency(request, {});
    const agency = response.toObject();

    return agency;
  },
  retrieveByCode: async ({ code }) => {
    const request = new requests.AgencyRetrieveAgencyByCodeRequest();

    request.setCode(code);

    const response = await grpcClient.retrieveAgencyByCode(request, {});
    const agency = response.toObject();

    return agency;
  },
};
