import db from "@/db";
import { groupBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import LINES from "@/db/enums/lines";
import { isEqual } from "lodash";

export class ChainManager {
  constructor(rootSample, phase) {
    this.samples = [];
    this.isChainBuilt = false;
    this.rootSample = rootSample;
    this.phase = phase;
  }

  static async initialize({
    rootSample = null,
    rootSampleUuid = null,
    phaseUuid = null,
  }) {
    let sample = null;
    let phase = null;

    if (rootSample) sample = rootSample;
    else if (rootSampleUuid) sample = await db.samples.get(rootSampleUuid);
    else if (phaseUuid) {
      phase = await db.phases.get(phaseUuid);
      sample =
        (await db.samples
          .where({ phase: phaseUuid })
          .filter(({ previousSample }) => !previousSample)
          .first()) || null;
      if (sample) await sample.setRelationships();
    }
    return new ChainManager(sample, phase);
  }

  async buildChain() {
    if (!this.rootSample) {
      this.samples = [];
      this.isChainBuilt = true;
    }

    if (this.isChainBuilt) return this.samples;

    const samplesList = [this.rootSample];
    let currentSample = this.rootSample;

    while (currentSample.nextSample) {
      let nextSample = await db.samples.get(currentSample.nextSample);
      await nextSample.setRelationships();
      samplesList.push(nextSample);
      currentSample = nextSample;
    }

    this.isChainBuilt = true;
    this.samples = samplesList;
    return samplesList;
  }

  async consolidateChain() {
    await this.buildChain();

    for (const [index, value] of await this.samples.entries()) {
      const currentSample = value;
      let previousSample = null;
      let nextSample = null;
      let updated = false;

      if (index > 0) previousSample = this.samples[index - 1].uuid;

      if (index < this.samples.length - 1) {
        nextSample = this.samples[index + 1].uuid;
      }

      if (index === 0) {
        currentSample.previousSample = null;
        updated = true;
      }

      if (previousSample && previousSample !== currentSample.previousSample) {
        currentSample.previousSample = previousSample;
        updated = true;
      }

      if (nextSample && nextSample !== currentSample.nextSample) {
        currentSample.nextSample = nextSample;
        updated = true;
      }

      if (!nextSample && currentSample.nextSample) {
        currentSample.nextSample = null;
        updated = true;
      }
      if (updated) {
        await db.samples.update(currentSample.uuid, {
          previousSample: currentSample.previousSample,
          nextSample: currentSample.nextSample,
        });
      }

      const sampleNumber = await currentSample.getNumber();
      if (sampleNumber != currentSample.number) {
        currentSample.number = sampleNumber;
        await db.samples.update(currentSample.uuid, {
          number: currentSample.number,
        });
      }
    }
    return this.samples;
  }

  async splitByLine() {
    if (!this.isChainBuilt) await this.buildChain();

    const samples = [];
    for (let sample of this.samples) {
      sample.groupParameters = [];
      for (const parameterUuid of sample.mainParametersList) {
        const parameter = await db.parameters.get(parameterUuid);
        sample.groupParameters.push(parameter.name);
      }
      samples.push(sample);
    }

    return groupBy(samples, ({ groupParameters }) => {
      return groupParameters;
    });
  }

  async groupByLineType() {
    const byLine = await this.splitByLine();
    const byLineType = {
      [LINES.LIGNE_PRINCIPALE]: null,
      [LINES.LIGNE_SECONDAIRE]: {},
    };

    const formatSamples = (samples) => {
      return samples.reduce((result, sample, index) => {
        const count = index + 1;
        result[count] = {
          uuid: sample.uuid,
        };
        return result;
      }, {});
    };

    // const formatSamples = (samples) =>
    //   keyBy(samples, (_) => samples.indexOf(_) + 1);

    let secondaryLineCount = 0;
    await Promise.all(
      Object.values(byLine).map(async (samples) => {
        if ((await samples[0].getLineType()) == LINES.LIGNE_PRINCIPALE) {
          byLineType[LINES.LIGNE_PRINCIPALE] = formatSamples(samples);
          return;
        }
        secondaryLineCount += 1;
        byLineType[LINES.LIGNE_SECONDAIRE][secondaryLineCount] =
          formatSamples(samples);
      })
    );

    return byLineType;
  }

  async getPhaseSignature() {
    if (!this.isChainBuilt) await this.buildChain();
    return this.samples.map((x) => x.uuid).join("_");
  }

  async buildChainFromPhaseConfiguration(isOfflineCreation = false) {
    if (this.rootSample || !this.phase) return;

    const parametersUuids = Object.keys(this.phase.configurationLinesCount);

    const mainLineParameters = await db.parameters
      .filter(
        ({ uuid, line }) =>
          parametersUuids.includes(uuid) && line === LINES.LIGNE_PRINCIPALE
      )
      .toArray();

    const secondaryLineParameters = await db.parameters
      .filter(
        ({ uuid, line }) =>
          parametersUuids.includes(uuid) && line === LINES.LIGNE_SECONDAIRE
      )
      .toArray();

    await this.buildMainLineSamples(mainLineParameters, isOfflineCreation);
    await this.buildSecondaryLineSamples(
      secondaryLineParameters,
      isOfflineCreation
    );

    return this.samples;
  }

  async buildMainLineSamples(parameters, isOfflineCreation) {
    const sampleCount = parseInt(
      this.phase.configurationLinesCount[parameters[0]?.uuid] || 0
    );
    for (let sample of [...Array(sampleCount).keys()]) {
      const sampleUuid = await db.models.Sample.createOrUpdate({
        uuid: uuidv4(),
        phase: this.phase.uuid,
        mainParametersList: parameters.map(({ uuid }) => uuid),
        isCreatedOffline: isOfflineCreation,
      });
      sample = await db.samples.get(sampleUuid);
      await sample.setRelationships();
      await this.addSample(sample);
    }
  }

  async buildSecondaryLineSamples(parameters, isOfflineCreation) {
    for (const parameter of parameters) {
      const sampleCount = parseInt(
        this.phase.configurationLinesCount[parameter.uuid] || 0
      );
      for (let sample of [...Array(sampleCount).keys()]) {
        const sampleUuid = await db.models.Sample.createOrUpdate({
          uuid: uuidv4(),
          phase: this.phase.uuid,
          mainParametersList: [parameter.uuid],
          isCreatedOffline: isOfflineCreation,
        });
        sample = await db.samples.get(sampleUuid);
        await sample.setRelationships();
        await this.addSample(sample);
      }
    }
  }

  async findSampleIndex(sampleUuid) {
    await this.buildChain();

    for (const [index, sample] of this.samples.entries()) {
      if (sample.uuid === sampleUuid) return index;
    }
  }

  async deleteSample(sampleUuid) {
    const index = await this.findSampleIndex(sampleUuid);
    this.samples.splice(index, 1);
    return await this.consolidateChain();
  }

  async moveSampleUp(sampleUuid) {
    const index = await this.findSampleIndex(sampleUuid);
    if (index === 0) return this.samples;
    this.samples.splice(index - 1, 0, this.samples.splice(index, 1)[0]);
    return await this.consolidateChain();
  }

  async moveSampleDown(sampleUuid) {
    const index = await this.findSampleIndex(sampleUuid);
    if (index === 0) return this.samples;

    this.samples.splice(index + 1, 0, this.samples.splice(index, 1)[0]);
    return await this.consolidateChain();
  }

  async addSample(sample) {
    if (!this.isChainBuilt) await this.buildChain();
    this.samples.push(sample);
    if (this.samples.length === 1) {
      this.rootSample = sample;
    }
    return await this.consolidateChain();
  }

  async moveSample(sampleUuid, nextSampleUuid) {
    const index = await this.findSampleIndex(sampleUuid);
    const newIndex = await this.findSampleIndex(nextSampleUuid);

    if (index === newIndex) return this.samples;

    this.samples.splice(newIndex, 0, this.samples.splice(index, 1)[0]);
    return await this.consolidateChain();
  }

  getSampleNumber(sample) {
    if (!sample.previousSample) {
      return 1;
    }
    if (
      !isEqual(
        sample.mainParametersList,
        sample.previousSample.mainParametersList
      )
    ) {
      return 1;
    }
    return sample.previousSample.number + 1;
  }
}
