import LINES from "@/db/enums/lines";
import db from "@/db";
import { v4 as uuidv4 } from "uuid";

const secondsToString = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const buildMeasurementForm = async (recordBook) => {
  if (!recordBook.type == "manual_sampling") {
    console.error("Wrong type of record book cannot create sampling form");
    return;
  }

  return {
    uuid: uuidv4(),
    createdAt: new Date(),
    updatedAt: new Date(),
    recordBook: recordBook.uuid,
    started: false,
    measurements: [],
    samples: { [LINES.LIGNE_PRINCIPALE]: {}, [LINES.LIGNE_SECONDAIRE]: {} },
    pauses: [],
    startingTime: "08:00",
    setup: null,
    leakTests: [],
    project: recordBook.project,
    finished: false,
  };
};

const hydrateMeasurementForm = async (measurementForm) => {
  await measurementForm.buildSetup();
  measurementForm.phase = await db.phases.get(measurementForm.setup.phaseUuid);

  const hydratedSamples = {
    [LINES.LIGNE_PRINCIPALE]: {},
    [LINES.LIGNE_SECONDAIRE]: {},
  };

  if (measurementForm.setup.samples[LINES.LIGNE_PRINCIPALE]) {
    for (const [key, sample] of Object.entries(
      measurementForm.setup.samples[LINES.LIGNE_PRINCIPALE]
    )) {
      const obj = await db.samples.get(sample.uuid);
      await obj.setRelationships();
      hydratedSamples[LINES.LIGNE_PRINCIPALE][key] = obj;
    }
  }
  for (const [key, value] of Object.entries(
    measurementForm.setup.samples[LINES.LIGNE_SECONDAIRE]
  )) {
    hydratedSamples[LINES.LIGNE_SECONDAIRE][key] = {};
    for (const [subKey, sample] of Object.entries(value)) {
      const obj = await db.samples.get(sample.uuid);
      await obj.setRelationships();
      hydratedSamples[LINES.LIGNE_SECONDAIRE][key][subKey] = obj;
    }
  }

  measurementForm.castStringToDatetime();

  measurementForm.samples = hydratedSamples;
  return measurementForm;
};

export { buildMeasurementForm, hydrateMeasurementForm, secondsToString };
