import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";
import { ParameterClient } from "@/api";

const SET_FILTERED_PARAMETERS = "SET_FILTERED_PARAMETERS";
const ADD_PARAMETER = "ADD_PARAMETER";
const UPDATE_PARAMETER = "UPDATE_PARAMETER";
const DELETE_PARAMETER = "DELETE_PARAMETER";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";

const { innerFetch, innerCreate, innerUpdate } = crudGenerator(
  db.models.Parameter
);

const state = {
  parametersIsLoading: false,
  selectedCategory: "",
  filteredParameters: [],
};

const actions = {
  async fetchParameters({ commit, rootGetters }, filters = {}) {
    if (!window.navigator.onLine) return;

    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },

  async setAllParameters({ commit }) {
    commit(SET_IS_LOADING, true);
    const parameters = await db.parameters.toArray();
    commit(SET_FILTERED_PARAMETERS, parameters);
    commit(SET_IS_LOADING, false);
  },

  async createParameter({ commit, rootGetters }, form) {
    const parameter = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(ADD_PARAMETER, parameter);
    return parameter;
  },

  async updateParameter({ commit, rootGetters }, form) {
    const parameter = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(UPDATE_PARAMETER, parameter);
    return parameter;
  },

  async deleteParameter({ commit }, parameter) {
    await ParameterClient.destroy(parameter.uuid);
    await db.parameters.delete(parameter.uuid);

    commit(DELETE_PARAMETER, parameter);
  },

  filterParametersList: async ({ commit }, query) => {
    const { name } = query;
    commit(SET_IS_LOADING, true);

    const parametersList = await db.parameters
      .filter((item) => {
        return item.name.toLowerCase().includes(name.toLowerCase());
      })
      .toArray();
    commit(SET_FILTERED_PARAMETERS, parametersList);
    commit(SET_IS_LOADING, false);
  },
  async filterParametersByCategory(
    { commit, state },
    { category, categoriesToHide = [] }
  ) {
    if (state.selectedCategory === category) return;
    while (state.parametersIsLoading)
      await new Promise((r) => setTimeout(r, 10));
    commit(SET_IS_LOADING, true);
    commit(SET_SELECTED_CATEGORY, category);
    const queryset = db.parameters.orderBy("order").filter((item) => {
      return (
        !item.mutualisationsList.length &&
        !item.categoriesList.some((category) =>
          categoriesToHide.includes(category)
        ) &&
        item.categoriesList.includes(category)
      );
    });
    const parameters = await queryset.toArray();
    commit(SET_FILTERED_PARAMETERS, parameters);
    commit(SET_IS_LOADING, false);
    return parameters;
  },

  async filterParametersByUuid({ state }, uuidList) {
    while (state.parametersIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    const queryset = db.parameters.orderBy("name").filter((item) => {
      return uuidList.includes(item.uuid);
    });
    return await queryset.toArray();
  },
  async filterParametersBySumPreselections({ state }, sumPreselection) {
    while (state.parametersIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    const queryset = db.parameters.orderBy("name").filter((item) => {
      return item.sumPreselectionsList.includes(sumPreselection);
    });
    return await queryset.toArray();
  },

  // TODO - PH - 26/10/2022 - Replace by a setRelationship in dexie when ManyToMany will be implemented
  async retrieveParameterByUuid(context, parameterUuid) {
    return await db.parameters.get(parameterUuid);
  },

  async retrieveParameterByName(context, parameterName) {
    return await db.parameters.get({ name: parameterName });
  },
};

const mutations = {
  [SET_FILTERED_PARAMETERS](state, parameters) {
    state.filteredParameters = parameters;
  },
  [ADD_PARAMETER](parameter) {
    state.filteredParameters.push(parameter);
  },
  [UPDATE_PARAMETER]: (state, parameter) => {
    const parameterIndex = state.filteredParameters.findIndex(
      ({ uuid }) => parameter.uuid == uuid
    );
    if (parameterIndex < 0) return;

    state.filteredParameters[parameterIndex] = parameter;
  },
  [DELETE_PARAMETER]: (state, parameterToDelete) => {
    state.filteredParameters = state.filteredParameters.filter(
      (parameter) => parameter.uuid !== parameterToDelete.uuid
    );
  },
  [SET_IS_LOADING](state, isLoading) {
    state.parametersIsLoading = isLoading;
  },
  [SET_SELECTED_CATEGORY](state, category) {
    state.selectedCategory = category;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
