const CasesList = () =>
  import(
    /* webpackChunkName: "CasesList" */ "@/components/atmo/cases/CasesList"
  );

export default [
  {
    path: "",
    name: "cases",
    component: CasesList,
  },
];
