import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";
import google_protobuf_struct_pb from "google-protobuf/google/protobuf/struct_pb.js";
import { listAllObjects } from "../utils/listUtils";

const requests = socioGrpcClient.materials.material_services;
const grpcClient =
  socioGrpcClient.materials.material_services.MaterialControllerPromiseClient;

export const MaterialClient = {
  list: async (filters) => {
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.MaterialListRequest();
    const response = await listAllObjects(grpcClient, request, metadata);

    return response.map((material) => {
      return {
        ...material.toObject(),
        metrologicalParameters: material
          .getMetrologicalParameters()
          .toJavaScript(),
      };
    });
  },
  retrieve: async (materialUuid) => {
    const data = {
      uuid: materialUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.MaterialRetrieveRequest,
      data
    );
    const response = await grpcClient.retrieve(request, {});
    let materialResponse = response.toObject();
    materialResponse.metrologicalParameters = response
      .getMetrologicalParameters()
      .toJavaScript();
    return materialResponse;
  },
  create: async (material) => {
    const categoryRequest = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.materials.material_services.CategoryRequest,
      {
        name: material.category,
      }
    );
    const metrologicalParameters =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        JSON.parse(JSON.stringify(material.metrologicalParameters))
      );
    material.category = categoryRequest;
    material.metrologicalParameters = metrologicalParameters;

    const request = socioGrpcClient.javascriptToRequest(
      requests.MaterialRequest,
      material,
      ["agency"]
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  update: async (material) => {
    const metrologicalParameters =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        JSON.parse(JSON.stringify(material.metrologicalParameters))
      );
    material.metrologicalParameters = metrologicalParameters;

    const request = socioGrpcClient.javascriptToRequest(
      requests.MaterialRequest,
      material,
      ["agency", "category"]
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
  destroy: async (materialUuid) => {
    const data = {
      uuid: materialUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.MaterialRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
};
