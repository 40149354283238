import RecordBooksChildren from "@/router/atmo/projects/project/recordbooks/recordBookLists/children";

const RecordBook = () =>
  import(
    /* webpackChunkName: "RecordBook" */ "@/components/atmo/projects/project/recordbooks/RecordBook"
  );
const ManualSampling = () =>
  import(
    /* webpackChunkName: "RecordBook" */ "@/components/atmo/projects/project/recordbooks/manual/ManualSampling"
  );
const RecordBooks = () =>
  import(
    /* webpackChunkName: "RecordBook" */ "@/components/atmo/projects/project/recordbooks/RecordBooks"
  );

export default [
  {
    path: "",
    component: RecordBooks,
    children: RecordBooksChildren,
    meta: {
      subNavs: "recordBooks",
    },
  },
  {
    path: ":recordBookUuid",
    name: "record-book-detail",
    component: RecordBook,
  },
  {
    path: ":recordBookUuid/manual",
    name: "record-book-manual",
    component: ManualSampling,
  },
];
