import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";

const requests = socioGrpcClient.atmo_back.analysis;
const grpcClient =
  socioGrpcClient.atmo_back.analysis.OrderFormControllerPromiseClient;

export const OrderFormClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.OrderFormListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);

    return response;
  },
  create: async (orderForm) => {
    console.log("Called create of OrderFormClient");
    const request = socioGrpcClient.javascriptToRequest(
      requests.OrderFormRequest,
      orderForm
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  send: async (orderFormUuid) => {
    console.log("Called send of OrderFormClient");
    const request = socioGrpcClient.javascriptToRequest(
      requests.OrderFormSendRequest,
      { uuid: orderFormUuid }
    );
    const response = await grpcClient.send(request, {});
    return response.toObject();
  },
};
