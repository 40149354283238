import socioGrpcClient from "@/setup/socioGrpcClient";
const requests = socioGrpcClient.atmo_back.projects;
const grpcClient =
  socioGrpcClient.atmo_back.projects.ClientControllerPromiseClient;

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z0-9]/g, (ltr, idx) =>
    idx > 0 ? `_${ltr.toLowerCase()}` : ltr.toLowerCase()
  );

export const ClientClient = {
  update: async (form) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ClientPartialRequest,
      form
    );
    request.setPartialUpdateFieldsList(Object.keys(form).map(camelToSnakeCase));
    const response = await grpcClient.partialUpdate(request, {});
    return response.toObject();
  },
};
