//import store from "@/store";

const buildMetadata = (metadata) => {
  const metadataCopy = { ...metadata };

  Object.keys(metadataCopy).forEach((key) => {
    if (typeof metadataCopy[key] === "object") {
      metadataCopy[key] = JSON.stringify(metadataCopy[key]);
    } else {
      metadataCopy[key] = metadata[key].toString();
    }
  });
  return metadataCopy;
};

const filtersToGet = (filters) => {
  return (
    "?" +
    Object.entries(filters)
      .map((filter) => filter.join("="))
      .join("&")
  );
};

export { buildMetadata, filtersToGet };
