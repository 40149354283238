import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.contributors.contributors;
const grpcClient =
  socioGrpcClient.contributors.contributors.ContributorControllerPromiseClient;

export const ContributorClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });

    const request =
      new requests.ContributorCompanyProjectRoleLightListRequest();
    const response = await grpcClient.list(request, metadata);

    return response.toObject();
  },
  create: async (contributor) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ContributorInputRequest,
      contributor
    );

    const response = await grpcClient.create(request, {});

    return response.toObject();
  },
  update: async (contributor) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ContributorInputRequest,
      contributor
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
  destroy: async (contributorUuid) => {
    const request = new requests.ContributorDestroyRequest();

    request.setUuid(contributorUuid);

    const response = await grpcClient.destroy(request, {});

    return response.toObject();
  },
};
