import BaseSchema from "./base";
import { utils } from "@socotec.io/socio-vue-components";
import _ from "lodash";
import useInstallationVle from "@/composables/installations/vles/useInstallationVle";

const { getDewPoint } = useInstallationVle();

class RecordBook extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "recordBooks";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "type",
    "label",
    "eventTypes",
    "formTypes",
    "recordBlocks",
    "installation",
    "installationObj",
    "project",
    "speed",
    "speedObj",
    "humidity",
    "nozzleDiameter",
    "humidityObj",
    "phase",
    "measurementMethod",
    "formFieldToEvent",
    "isArchived",
    "environmentalCondition",
    "environmentalConditionObj",
    "manual",
    "manualObj",
    "phaseSignature",
    "samplingForm",
    "measurementForm",
    "recordForm",
  ];

  static relatedFields = {
    installationObj: { field: "installation", tableName: "installations" },
    environmentalConditionObj: {
      field: "environmentalCondition",
      tableName: "recordBooks",
    },
    speedObj: {
      field: "speed",
      tableName: "recordBooks",
    },
    phaseObj: {
      field: "phase",
      tableName: "phases",
    },
    measurementFormObj: {
      field: "measurementForm",
      tableName: "measurementForm",
    },
    nozzleDiameterObj: {
      field: "nozzleDiameter",
      tableName: "recordBooks",
    },
    humidityObj: {
      field: "humidity",
      tableName: "recordBooks",
    },
    manualObj: {
      field: "manual",
      tableName: "recordBooks",
    },
  };

  setDate() {
    if (this.type === "environmental_condition")
      this.date = utils.filters.formatDate(
        this.recordBlocks[0]?.recordForm?.interventionDate
      );
    else {
      this.date = utils.filters.formatDate(
        this.environmentalConditionObj?.recordBlocks[0]?.recordForm
          ?.interventionDate
      );
    }
  }

  async setFilterTemperature() {
    this.setRelationships();
    const h2oExpectedContent = this.installationObj?.h2oExpectedContent;
    let dewPoint = 0;
    if (h2oExpectedContent) dewPoint = getDewPoint(h2oExpectedContent);
    const block = this.recordBlocks[0];
    if (this.type != "manual_sampling") return;
    const parameters = await this.findParametersUsed(this.phase);

    const temp = Math.max(
      ...parameters.map((p) =>
        p.getFilteringTemperature(block.recordForm.tubeMaterial, dewPoint)
      )
    );
    block.recordForm.theoricTemperature = temp;
    block.recordForm.realTemperature = temp;
    await this.save();
  }

  async getDurationAsInt() {
    const timeString = this.recordBlocks[0].recordForm.samplingDuration;
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 3600 + minutes;
  }

  async setDurations() {
    if (this.type != "manual_sampling") return;
    const duration = await this.findMaxDuration(this.installation, this.phase);
    const [hours, minutes] = duration.split(":");
    this.recordBlocks[0].recordForm.samplingDuration = `${hours}:${minutes}`;
    await this.save();
  }
  static dateFields = ["createdAt", "updatedAt"];

  async findParametersUsed(phase) {
    const samplesUsed = await this.db.samples
      .where("phase")
      .equals(phase)
      .toArray();
    const parameterUsedUuids = _.flatten(
      samplesUsed.map((p) => [...p.mainParametersList, p.secondParameter])
    );

    return await this.db.parameters
      .where("uuid")
      .anyOf(parameterUsedUuids)
      .toArray();
  }

  async findVleUsed(installation, { phase = null, parametersUsed = null }) {
    if (!parametersUsed) {
      parametersUsed = await this.findParametersUsed(phase);
    }
    const parameterUsedUuids = parametersUsed.map(({ uuid }) => uuid);

    const mtxIsUsed = parametersUsed.some((p) =>
      ["MtxP", "MtxG"].includes(p.sampleShortName)
    );

    const configurationLineUuids = await this.db.configurationLines
      .where("parameter")
      .anyOf(parameterUsedUuids)
      .and((cl) => cl.installation == installation)
      .primaryKeys();

    let vlesQs = this.db.vles
      .where("configurationLine")
      .anyOf(configurationLineUuids);

    if (mtxIsUsed) {
      const sums = await this.db.sums
        .where("installation")
        .equals(installation)
        .primaryKeys();
      vlesQs = vlesQs.or("sum").anyOf(sums);
    }
    return await vlesQs.toArray();
  }

  async findMaxDuration(installation, phase) {
    const parametersUsed = await this.findParametersUsed(phase);
    const vles = await this.findVleUsed(installation, { parametersUsed });
    const vleMaxDuration = _.maxBy(vles, "samplingDuration")?.samplingDuration;
    const parameterMaxDuration = _.maxBy(
      parametersUsed,
      "sampleDuration"
    )?.sampleDuration;

    return vleMaxDuration > parameterMaxDuration
      ? vleMaxDuration
      : parameterMaxDuration;
  }

  getForm(recordBlockType) {
    const block = this.recordBlocks.find(
      (block) => block.type == recordBlockType
    );
    return block?.recordForm;
  }

  async getFullForm() {
    await this.setRelationships();
    return Object.assign({}, ...this.recordBlocks.map((b) => b.recordForm));
  }
}

// async  findParametersUsed (phase) {
//   const samplesUsed = await db.samples.where("phase").equals(phase).toArray();
//   const parameterUsedUuids = _.flatten(
//     samplesUsed.map((p) => [...p.mainParametersList, p.secondParameter])
//   );

//   return await db.parameters.where("uuid").anyOf(parameterUsedUuids).toArray();
// };

// const findVleUsed = async (
//   installation,
//   { phase = null, parametersUsed = null }
// ) => {
//   if (!parametersUsed) {
//     parametersUsed = await findParametersUsed(phase);
//   }
//   const parameterUsedUuids = parametersUsed.map(({ uuid }) => uuid);

//   const mtxIsUsed = parametersUsed.some((p) =>
//     ["MtxP", "MtxG"].includes(p.sampleShortName)
//   );

//   const configurationLineUuids = await db.configurationLines
//     .where("parameter")
//     .anyOf(parameterUsedUuids)
//     .and((cl) => cl.installation == installation)
//     .primaryKeys();

//   let vlesQs = db.vles.where("configurationLine").anyOf(configurationLineUuids);

//   if (mtxIsUsed) {
//     const sums = await db.sums
//       .where("installation")
//       .equals(installation)
//       .primaryKeys();
//     vlesQs = vlesQs.or("sum").anyOf(sums);
//   }
//   return await vlesQs.toArray();
// };

// const findMaxDuration = async (installation, phase) => {
//   const parametersUsed = await findParametersUsed(phase);
//   const vles = await findVleUsed(installation, { parametersUsed });
//   const vleMaxDuration = _.maxBy(vles, "samplingDuration")?.samplingDuration;
//   const parameterMaxDuration = _.maxBy(
//     parametersUsed,
//     "sampleDuration"
//   )?.sampleDuration;

//   return vleMaxDuration > parameterMaxDuration
//     ? vleMaxDuration
//     : parameterMaxDuration;
// };

export default RecordBook;
