import db from "@/db";
import PARAMETERS_CATEGORIES from "@/db/enums/parametersCategories";
import crudGenerator from "@/api/utils/offlineSync";

const { innerFetch, innerCreate, innerUpdate, innerBulkCreate, innerDelete } =
  crudGenerator(db.models.ConfigurationLine);

const state = {
  configurationLines: {},
  configurationLineIsLoading: false,
};

const getters = {};

const actions = {
  async fetchConfigurationLines({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit("SET_IS_LOADING", isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },

  async setCurrentConfigurationLines({ commit, state }, installationUuid) {
    while (state.parametersIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit("SET_IS_LOADING", true);
    const lines = await db.configurationLines
      .where({ installation: installationUuid })
      .toArray();

    const configLines = {};
    for (let line of lines) {
      await line.setRelationships();
      configLines[line.parameterObject.uuid] = line;
    }
    commit("SET_CONFIGURATION_LINES", configLines);
    commit("SET_IS_LOADING", false);
  },

  async createConfigurationLine(
    { commit, rootGetters, dispatch },
    { installationUuid, parameter }
  ) {
    const form = {
      installation: installationUuid,
      parameter: parameter.uuid,
      underAccreditation: parameter.underAccreditation,
      sampleCount: parameter.sampleCount,
    };

    const configLine = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    dispatch("installations/refreshLastUpdated", {}, { root: true });

    commit("ADD_CONFIGURATION_LINE", configLine);

    return configLine;
  },

  async fetchSumSelectionParameter(
    { state },
    { installationUuid, parameterUuidList }
  ) {
    const uuids = Object.keys(state.configurationLines);
    const params = await db.parameters
      .filter((parameter) => {
        return (
          parameterUuidList.includes(parameter.uuid) &&
          !uuids.includes(parameter.uuid)
        );
      })
      .toArray();
    const form = params.map((parameter) => {
      return {
        installation: installationUuid,
        parameter: parameter.uuid,
        underAccreditation: parameter.underAccreditation,
        sampleCount: parameter.sampleCount,
        name: parameter.name,
        categoriesList: parameter.categoriesList,
      };
    });
    return form;
  },
  async fetchPreselectionParameter(
    { state },
    { installationUuid, preselection }
  ) {
    const uuids = Object.keys(state.configurationLines);
    const params = await db.parameters
      .filter((parameter) => {
        return (
          parameter.preselectionsList.includes(preselection) &&
          !parameter.mutualisationsList.length &&
          !parameter.categoriesList.includes(PARAMETERS_CATEGORIES.MTX) &&
          !uuids.includes(parameter.uuid)
        );
      })
      .toArray();

    const form = params.map((parameter) => {
      return {
        installation: installationUuid,
        parameter: parameter.uuid,
        underAccreditation: parameter.underAccreditation,
        sampleCount: parameter.sampleCount,
        name: parameter.name,
        categoriesList: parameter.categoriesList,
      };
    });

    return form;
  },

  async bulkCreateConfigurationLine(
    { commit, rootGetters, dispatch },
    { form }
  ) {
    const configurationLines = await innerBulkCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit("BULK_ADD_CONFIGURATION_LINES", configurationLines);
    dispatch("installations/refreshLastUpdated", {}, { root: true });
    return configurationLines;
  },

  async updateConfigurationLine({ commit, rootGetters, dispatch }, form) {
    const configLine = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    dispatch("installations/refreshLastUpdated", {}, { root: true });
    commit("UPDATE_CONFIGURATION_LINE", configLine);
    return configLine;
  },

  async deleteConfigurationLine(
    { commit, rootGetters, dispatch },
    configurationLine
  ) {
    await innerDelete(
      configurationLine.uuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    commit("DELETE_CONFIGURATION_LINE", configurationLine);
    dispatch("installations/refreshLastUpdated", {}, { root: true });
  },

  async getConfigurationLinesParametersIds({ state }, installationUuid) {
    while (state.parametersIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    const lines = await db.configurationLines
      .where({ installation: installationUuid })
      .toArray();

    const parametersIds = lines.map((item) => item.parameter);

    return parametersIds;
  },
};

const mutations = {
  SET_CONFIGURATION_LINES: (state, configurationLines) => {
    state.configurationLines = configurationLines;
  },
  SET_IS_LOADING: (state, configurationLineIsLoading) => {
    state.configurationLineIsLoading = configurationLineIsLoading;
  },
  ADD_CONFIGURATION_LINE: (state, configurationLine) => {
    state.configurationLines[configurationLine.parameterObject.uuid] =
      configurationLine;
    state.configurationLines = {
      ...state.configurationLines,
    };
  },
  UPDATE_CONFIGURATION_LINE: (state, configurationLine) => {
    state.configurationLines[configurationLine.parameterObject.uuid] =
      configurationLine;
    state.configurationLines = {
      ...state.configurationLines,
    };
  },
  BULK_ADD_CONFIGURATION_LINES: (state, configLines) => {
    for (const line of configLines) {
      state.configurationLines[line.parameterObject.uuid] = line;
    }
    state.configurationLines = {
      ...state.configurationLines,
    };
  },
  DELETE_CONFIGURATION_LINE: (state, configurationLine) => {
    delete state.configurationLines[configurationLine.parameterObject.uuid];
    state.configurationLines = {
      ...state.configurationLines,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
