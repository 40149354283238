import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.projects;
const grpcClient =
  socioGrpcClient.atmo_back.projects.ProjectControllerPromiseClient;

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (ltr, idx) =>
    idx > 0 ? `_${ltr.toLowerCase()}` : ltr.toLowerCase()
  );

export const ProjectClient = {
  retrieve: async (uuid) => {
    const request = new requests.ProjectRetrieveRequest();
    request.setUuid(uuid);

    const response = await grpcClient.retrieve(request, {});
    return response.toObject();
  },

  update: async (form) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ProjectPartialRequest,
      form
    );
    request.setPartialUpdateFieldsList(Object.keys(form).map(camelToSnakeCase));
    const response = await grpcClient.partialUpdate(request, {});
    return response.toObject();
  },
  archiveOrUnarchiveProject: async (project) => {
    const form = {
      chronorapso: project.chronorapso,
      isArchived: !project.isArchived,
    };

    const request = socioGrpcClient.javascriptToRequest(
      requests.ProjectArchiveRequest,
      form
    );
    const response = await grpcClient.archiveOrUnarchiveProject(request, {});
    return response.toObject();
  },
  sendInvitationRequestEmail: async (projectId) => {
    const form = {
      projectUuid: projectId,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.EmailRequest,
      form
    );
    const response = await grpcClient.sendInvitationRequestEmail(request, {});
    return response.toObject();
  },
};
