import store from "@/store";

import { multiGuard } from "@/utils/router";

import AtmoChildrens from "@/router/atmo/children";
import { VUE_APP_ATMO_SERVICE_ID } from "@/env";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");
import { isLoggedOrOffline, isOffline } from "@/offline/guards";

const Atmo = () => import(/* webpackChunkName: "Atmo" */ "@/components/Atmo");
const UnauthorizedAccessOfflineMode = () =>
  import(
    /* webpackChunkName: "UnauthorizedAccessOfflineMode" */ "@/offline/UnauthorizedAccessOfflineMode"
  );

const fetchMaterials = async (to, from, next) => {
  store.dispatch("materials/fetchMaterials");
  next();
};
const fetchAnalysis = async (to, from, next) => {
  store.dispatch("analysis/fetchAnalysis");
  next();
};

const fetchParameters = async (to, from, next) => {
  store.dispatch("parameters/fetchParameters");
  next();
};

const fetchRoles = async (to, from, next) => {
  store.dispatch("roles/fetchRoles", {
    filters: {
      service_id: VUE_APP_ATMO_SERVICE_ID,
    },
  });
  next();
};

const fetchAgencies = async (to, from, next) => {
  store.dispatch("agencies/fetchAgencies");
  next();
};

const fetchConstats = async (to, from, next) => {
  store.dispatch("constats/fetchConstats");
  next();
};

export default [
  {
    path: "/",
    component: Atmo,
    children: AtmoChildrens,
    beforeEnter: multiGuard([
      isLoggedOrOffline,
      fetchMaterials,
      fetchAnalysis,
      fetchRoles,
      fetchParameters,
      fetchAgencies,
      fetchConstats,
    ]),
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: "/unauthorized-access-offline-mode",
    name: "unauthorized-access-offline-mode",
    component: UnauthorizedAccessOfflineMode,
    beforeEnter: isOffline,
  },
];
