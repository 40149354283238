import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.ParameterControllerPromiseClient;

export const ParameterClient = {
  list: async (filters) => {
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.ParameterListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);
    return response;
  },
  create: async (parameter) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ParameterRequest,
      parameter
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  update: async (parameter) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ParameterRequest,
      parameter
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
  destroy: async (parameterUuid) => {
    const data = {
      uuid: parameterUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.ParameterDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
};
