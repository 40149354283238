import BaseSchema from "./base";
import { isEqual } from "lodash";
import LINES from "@/db/enums/lines";

class Sample extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "samples";
  static fields = [
    "uuid",
    "mainParametersList",
    "mainParametersObjectsList",
    "secondParameter",
    "secondParameterObject",
    "createdAt",
    "updatedAt",
    "previousSample",
    "nextSample",
    "phase",
    "number",
  ];

  static relatedFields = {
    mainParametersObjectsList: {
      m2m: true,
      owner: true,
      relatedKey: "mainParametersList",
      tableName: "parameters",
    },
    secondParameterObject: {
      field: "secondParameter",
      tableName: "parameters",
      blank: true,
    },
  };

  static dateFields = ["createdAt", "updatedAt"];

  async getNumber() {
    if (!this.previousSample) {
      return 1;
    }
    const previousSample = await this.db.samples.get(this.previousSample);
    if (!isEqual(this.mainParametersList, previousSample.mainParametersList)) {
      return 1;
    }
    const nb = await previousSample.getNumber();
    return nb + 1;
  }

  async getLineType() {
    await this.setRelationships();
    if (this.mainParametersObjectsList[0].line == LINES.LIGNE_SECONDAIRE)
      return LINES.LIGNE_SECONDAIRE;
    return LINES.LIGNE_PRINCIPALE;
  }

  async hasSecondParameter() {
    return !!this.secondParameter;
  }

  formatParameters(delemiter = " + ") {
    if (!this.mainParametersObjectsList) return "";
    return this.mainParametersObjectsList
      .map(({ name }) => name)
      .join(delemiter);
  }

  async isDioxOrHap() {
    await this.setRelationships();
    return this.mainParametersObjectsList.some((p) =>
      p.categoriesList.includes("DIOX/HAP")
    );
  }

  isMainLine() {
    if (!this.mainParametersObjectsList) {
      throw new Error("Main parameters not set");
    }
    return this.mainParametersObjectsList.some(
      (p) => p.line == LINES.LIGNE_PRINCIPALE
    );
  }
}

export default Sample;
