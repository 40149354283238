import db from "@/db";
import { SampleClient } from "@/api";
import { ChainManager } from "@/store/utils/sampleUtils";
import store from "@/store";

import crudGenerator from "@/api/utils/offlineSync";

const { innerFetch, innerCreate, innerUpdate, innerDelete } = crudGenerator(
  db.models.Sample
);

const state = {
  sampleList: [],
  sampleIsLoading: false,
};
const getters = {};

const actions = {
  async fetchSamples({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit("SET_IS_LOADING", isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },
  async filterSamplesByPhasesUuids({ commit }, phasesUuids) {
    while (state.sampleIsLoading) await new Promise((r) => setTimeout(r, 200));

    commit("SET_IS_LOADING", true);

    const samples = await db.samples
      .where("phase")
      .anyOf(phasesUuids)
      .toArray();

    for (const sample of samples) {
      await sample.setRelationships();
    }

    commit("SET_SAMPLE_LIST", samples);
    commit("SET_IS_LOADING", false);

    return samples;
  },
  async buildSampleChain({ commit, rootGetters }, phaseUuid) {
    const chain = await ChainManager.initialize({
      phaseUuid: phaseUuid,
    });
    const samples = await chain.buildChainFromPhaseConfiguration(
      rootGetters["projects/isOfflineState"]
    );

    if (!rootGetters["projects/isOfflineState"]) {
      await SampleClient.bulkCreate(samples);
      await SampleClient.bulkUpdate(samples);
    }
    await store.dispatch("installations/refreshLastUpdated");

    commit("UPDATE_SAMPLE_LIST_BY_PHASE", { samples, phaseUuid });

    return samples;
  },
  async createSample({ commit, rootGetters }, { form, previousSample }) {
    // INFO - PH - 15/11/2022 - Create sample in front.
    const chain = await ChainManager.initialize({
      phaseUuid: form.phase,
    });

    const sample = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    let samples = await chain.addSample(sample);
    if (previousSample.nextSample) {
      samples = await chain.moveSample(sample.uuid, previousSample.nextSample);
    }
    await store.dispatch("installations/refreshLastUpdated");
    commit("UPDATE_SAMPLE_LIST_BY_PHASE", { samples, phaseUuid: sample.phase });

    return sample;
  },
  async updateSample({ commit, rootGetters }, form) {
    const sample = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    await store.dispatch("installations/refreshLastUpdated");

    commit("UPDATE_SAMPLE", sample);

    return sample;
  },
  async deleteSample({ commit, rootGetters }, sample) {
    const chain = await ChainManager.initialize({
      phaseUuid: sample.phase,
    });
    await chain.deleteSample(sample.uuid);

    await innerDelete(
      sample.uuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );

    await store.dispatch("installations/refreshLastUpdated");

    commit("DELETE_SAMPLE", sample.uuid);
  },
  async removeSampleByPhase({ commit }, phaseUuid) {
    await db.samples.where({ phase: phaseUuid }).delete();
    commit("REMOVE_SAMPLES_FROM_LIST_BY_PHASE", phaseUuid);
  },
  async removeSampleByPhases({ commit }, phasesUuids) {
    await db.samples.where("phase").anyOf(phasesUuids).delete();
    commit("RESET_SAMPLES");
  },
};

const mutations = {
  SET_SAMPLE_LIST: (state, samples) => {
    state.sampleList = [...samples];
  },
  REMOVE_SAMPLES_FROM_LIST_BY_PHASE: (state, phaseUuid) => {
    state.sampleList = [
      ...state.sampleList.filter(({ phase }) => phase !== phaseUuid),
    ];
  },
  UPDATE_SAMPLE_LIST_BY_PHASE: (state, { samples, phaseUuid }) => {
    const filteredSampleList = state.sampleList.filter(
      ({ phase }) => phase !== phaseUuid
    );
    state.sampleList = [...filteredSampleList, ...samples];
  },
  SET_IS_LOADING: (state, sampleIsLoading) => {
    state.sampleIsLoading = sampleIsLoading;
  },
  UPDATE_SAMPLE: (state, newSample) => {
    const sampleIndex = state.sampleList.findIndex(
      ({ uuid }) => newSample.uuid == uuid
    );

    if (sampleIndex < 0) return;

    state.sampleList[sampleIndex] = newSample;
    state.sampleList = [...state.sampleList];
  },
  DELETE_SAMPLE: (state, sampleUuid) => {
    state.sampleList = state.sampleList.filter(
      ({ uuid }) => uuid !== sampleUuid
    );
  },
  RESET_SAMPLES: (state) => {
    state.sampleList = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
