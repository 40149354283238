import BaseSchema from "./base";

class SampleFlask extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "sampleFlasks";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "installation",
    "installationObject",
    "name",
    "samplesList",
    "tryNumber",
    "reference",
    "analysisCodesList",
    "priority",
    "orderForm",
    "type",
    "laboratory",
  ];

  static dateFields = ["createdAt", "updatedAt"];

  static relatedFields = {
    samples: {
      m2m: true,
      owner: true,
      relatedKey: "samplesList",
      tableName: "samples",
    },
    installationObject: {
      field: "installation",
      tableName: "installations",
    },
  };
}

export default SampleFlask;
