import dayjs from "dayjs";
import _ from "underscore";

class BaseSchema {
  static primaryKey = "";
  static tableName = "";
  static fields = [];
  static relatedFields = {};
  static dateFields = [];
  isBuilt = false;

  static getFields() {
    return this.fields.concat([
      "isStoredOffline",
      "isUpdatedOffline",
      "isCreatedOffline",
      "isDeletedOffline",
    ]);
  }

  static formatFields() {
    let fields = this.getFields();
    const findDuplicates = (array) =>
      array.filter((item, index) => array.indexOf(item) !== index);
    const duplicates = findDuplicates(fields);
    if (duplicates) {
      duplicates.forEach((duplicate) =>
        console.warn(
          `Found duplicated field ${duplicate} in table ${this.tableName}`
        )
      );

      fields = fields.filter((element, index) => {
        return fields.indexOf(element) === index;
      });
    }
    return this.fields.join();
  }

  static async createOrUpdate(data) {
    const obj = await this.formatData(data);
    try {
      return await this._createOrUpdate(obj);
    } catch (e) {
      console.log("Error while creating or updating in Dexie");
      console.log("Table: ", this.tableName);
      console.log("Data: ", obj);
      console.log(e);
    }
  }

  static async formatData(data) {
    let obj = {};

    // INFO - B.L - 02/09/2022 - mapping fields
    Object.entries(data)
      .filter(([key]) => {
        return this.getFields().includes(key);
      })
      .forEach(([key, value]) => {
        if (value && this.dateFields.includes(key)) {
          value = dayjs(value).toDate();
        }
        obj[key] = value;
      });

    Object.entries(this.relatedFields).forEach((mapper) => {
      if (mapper[1].many || mapper[1].m2m || !mapper[1].field) {
        return;
      }

      try {
        obj[mapper[1].field] = data[mapper[1].dataName].uuid;
      } catch (err) {
        if (mapper[1].blank) return;
        if (!data[mapper[1].dataName]?.uuid) return;
        console.log(
          `Could not link uuid to ${mapper[1].field} value ${
            data[mapper[1].dataName]?.uuid
          } in table ${this.tableName}`
        );
      }
    });

    return obj;
  }

  static async _createOrUpdate(data) {
    const uuid = data[this.primaryKey];
    const table = this.prototype.db[this.tableName];

    // INFO - B.L - We are not using put as we want to avoid overwriting the data, put replace and delete all data
    const obj = await table.get(uuid);
    if (!obj) {
      return await table.add(data).catch((error) => {
        if (error.name === "ConstraintError") {
          console.error(
            `Constraint error: A record could not be added on table: ${this.tableName}, with data:`,
            data
          );
        } else {
          console.error("An unexpected error occurred:", error);
        }
      });
    }

    if (await !obj.canBeUpdated()) return uuid;
    await table.update(uuid, data);
    return uuid;
  }

  async save() {
    return this.db[this.tableName].put(this);
  }

  async canBeUpdated() {
    return true;
  }
  async canBeDeleted() {
    return true;
  }

  async getRelatedToDelete() {
    if (await !this.canBeDeleted()) return [];
    const toDelete = {};
    const cascadeRelated = Object.entries(this.relatedFields).filter(
      ([key, value]) => value.cascade === true && key
    );

    for (const [key, value] of cascadeRelated) {
      toDelete[value.tableName] = await this.getRelateds(key, value);
    }
    return toDelete;
  }

  async refresh(api, refreshRelated) {
    // INFO - B.L - 28/08/2022 - WIP
    console.log(api);
    if (refreshRelated) console.log("refreshing related");
    // INFO - B.L - 26/08/2022 - update data
    return this.save({ dispatchSave: false });
  }

  async setisUpdatedOffline(hasChanged = true) {
    this.isUpdatedOffline = hasChanged;
    this.save();
  }

  async asForm(excluded = []) {
    excluded = excluded.concat([
      "createdAt",
      "updatedAt",
      "isStoredOffline",
      "isUpdatedOffline",
      "isCreatedOffline",
      "isDeletedOffline",
    ]);
    excluded = excluded.concat(Object.keys(this.relatedFields));
    excluded = excluded.concat(Object.keys(this.__proto__));

    // INFO - P.H - 20/07/2O23 - Date fields that have been modified offline must be formatted as strings in order to be synchronized with the back.
    for (const dateField of this.dateFields) {
      if (!this[dateField]) continue;
      this[dateField] = dayjs(this[dateField]).format("YYYY-MM-DD");
    }

    return _.omit(this, excluded);
  }

  async setRelationships() {
    for (const [key, value] of Object.entries(this.relatedFields)) {
      this[key] = await this.getRelateds(key, value);
      if (
        !this[key] &&
        !value.blank &&
        this[value.field] !== null &&
        this[value.field] !== undefined
      ) {
        console.warn(
          `Could not find uuid ${this[value.field]} in table ${value.tableName}`
        );
      }
    }
    this.isBuilt = true;
  }

  async getRelateds(key, value) {
    value = value || this.relatedFields[key];

    if (!value) {
      throw new Error(
        `No value found for key ${key} in table ${this.tableName}`
      );
    }
    if (value.many) {
      return await this.getManyToOne(value);
    } else if (value.m2m) {
      return await this.getManyToMany(value);
    } else {
      return await this.getOneToMany(value);
    }
  }

  async getManyToOne(value) {
    const reversedKey = `${value.reversedKey}`;
    let filter = {};
    filter[reversedKey] = this.uuid;
    return await this.db[value.tableName].where(filter).toArray();
  }

  async getOneToMany(value) {
    try {
      if (!this[value.field] && !value.reversedKey) return;
      if (this[value.field])
        return await this.db[value.tableName].get(this[value.field]);
      if (value.reversedKey) {
        const reversedKey = `${value.reversedKey}`;
        return await this.db[value.tableName].get({ [reversedKey]: this.uuid });
      }
    } catch (e) {
      return null;
    }
  }

  async getManyToMany(value) {
    if (!value.owner) {
      const reversedKey = `${value.reversedKey}`;
      const uuid = this.uuid;
      return await this.db[value.tableName]
        .filter((item) => {
          return item[reversedKey].includes(uuid);
        })
        .toArray();
    }

    const relatedKey = `${value.relatedKey}`;
    const uuids = this[relatedKey] || [];
    if (!uuids.length) {
      return [];
    }
    return await this.db[value.tableName]
      .filter((item) => uuids.includes(item.uuid))
      .toArray();
  }
}

export default BaseSchema;
