import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations
    .AdminInformationControllerPromiseClient;

export const AdminInformationClient = {
  list: async () => {
    const request = new requests.AdminInformationListRequest();
    const response = await grpcClient.utils.listAllObjects(request, {});
    return response;
  },
  create: async (adminInformation) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.AdminInformationRequest,
      adminInformation
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  update: async (adminInformation) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.AdminInformationRequest,
      adminInformation
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
};
