import BaseSchema from "./base";

class Agency extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "agencies";
  static fields = [
    "uuid",
    "code",
    "name",
    "manager",
    "managerCode",
    "isActive",
    "telephoneNumber",
    "city",
    "postalCode",
    "address",
    "country",
    "agencyGroup",
    "email",
  ];

  static relatedFields = {};

  static dateFields = [];
}

export default Agency;
