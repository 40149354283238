import schema from "./schemas";

import Dexie from "dexie";

const makeDb = () => {
  async function clearData() {
    await this.projects.toCollection().delete();
    await this.sums.toCollection().delete();
    await this.installations.toCollection().delete();
    await this.phases.toCollection().delete();
    await this.samples.toCollection().delete();
    await this.vles.toCollection().delete();
  }
  const db = new Dexie(process.env.VUE_APP_DEXIE_DB_NAME);
  const stores = new Map(
    Object.values(schema.models).map((model) => [
      model.tableName,
      model.formatFields(),
    ])
  );
  db.version(schema.VERSION).stores(Object.fromEntries(stores));
  db.clearData = clearData;
  Object.values(schema.models).forEach((model) => {
    model.prototype.db = db;
    model.prototype.relatedFields = model.relatedFields;
    model.prototype.fields = model.fields;
    model.prototype.tableName = model.tableName;
    model.prototype.dateFields = model.dateFields;
    db[model.tableName].mapToClass(model);
  });
  db.models = schema.models;
  return db;
};

const db = makeDb();
export default db;
