import socioGrpcClient from "@/setup/socioGrpcClient";

import { Case, Client, Project } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async archiveCase(context, metadata) {
    console.warn("[TODO] archiveCase", context, metadata);
  },
  async fetchCaseByNumber(context, { caseNumber }) {
    console.warn(
      "[TODO] fetchCaseByNumber",
      "context",
      context,
      "caseNumber",
      caseNumber
    );
  },

  async fetchCases(_, { query, ordering }) {
    Client.deleteAll();
    Case.deleteAll();
    Project.deleteAll();

    const metadata = {
      filters: JSON.stringify({
        model: "case",
        ...query,
        case_ordering: ordering,
      }),
    };
    const request =
      new socioGrpcClient.production_unit_lister.lister.FullProjectListRequest();
    const response =
      await socioGrpcClient.production_unit_lister.lister.FullProjectControllerPromiseClient.list(
        request,
        metadata
      );
    const responseObj = response.toObject();
    const results = responseObj.resultsList;
    results.forEach((result) => {
      Client.createFromListerData(result);
      Case.createFromListerData(result);
      Project.createFromListerData(result);
    });
    return responseObj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
