import Project from "./project";
import Case from "./case";
import Client from "./client";
import Document from "./document";
import Material from "./material";
import Contributor from "./contributor";
import Role from "./role";
import User from "./user";
import Agency from "./agency";
import Report from "./report";
import Installation from "./installation";
import Parameter from "./parameter";
import ConfigurationLine from "./configurationLine";
import Sample from "./sample";
import Phase from "./phase";
import SampleFlask from "./sampleFlask";
import Sum from "./sum";
import Analysis from "./analysis";
import Vle from "./vle";
import Flow from "./flow";
import Garbage from "./garbage";
import RecordBook from "./recordBook";
import OrderForm from "./orderForm";
import AnalysisResult from "./analysisResult";
import Constat from "./constat";
import AdminInformation from "./adminInformation";
import AcquisitionRecordBook from "./acquisitionRecordBook";
import MeasurementForm from "./measurementForm";
import CompanyProjectRole from "./companyProjectRole";

const VERSION = 64;

const models = {
  Project,
  Client,
  Case,
  Document,
  Contributor,
  Role,
  User,
  Material,
  Agency,
  Report,
  Installation,
  Parameter,
  ConfigurationLine,
  Sample,
  Phase,
  Sum,
  Vle,
  Analysis,
  SampleFlask,
  Flow,
  Garbage,
  RecordBook,
  OrderForm,
  AnalysisResult,
  Constat,
  AdminInformation,
  AcquisitionRecordBook,
  MeasurementForm,
  CompanyProjectRole,
};

export default { models, VERSION };

// CHANGELOG - B.L - 26/08/2022 - VERSION 1 adds Project
// CHANGELOG - B.L - 26/08/2022 - VERSION 2 adds Case and Client
// CHANGELOG - P.H - 26/08/2022 - VERSION 3 add Document
// CHANGELOG - P.H - 26/08/2022 - VERSION 4 add Document tableName in Project relatedFields
// CHANGELOG - B.L - 07/09/2022 - VERSION 5-7 Adds PUL related data
// CHANGELOG - P.H - 07/09/2022 - VERSION 8 Add Material
// CHANGELOG - L.L - 06/09/2022 - VERSION 8 add Contributor
// CHANGELOG - L.L - 09/09/2022 - VERSION 9 add User
// CHANGELOG - L.L - 19/09/2022 - VERSION 10 add Report
// CHANGELOG - P.H - 29/09/2022 - VERSION 11 add Installation
// CHANGELOG - P.H - 04/10/2022 - VERSION 12 add Parameter
// CHANGELOG - P.H - 05/10/2022 - VERSION 13 add ConfigurationLine
// CHANGELOG - L.L - 10/10/2022 - VERSION 14 add Sample
// CHANGELOG - P.H - 17/10/2022 - VERSION 15 update Parameter
// CHANGELOG - P.H - 20/10/2022 - VERSION 16 add Phase
// CHANGELOG - Q.C - 07/11/2022 - VERSION 17 update Installation fields
// CHANGELOG - P.H - 10/11/2022 - VERSION 18 update Parameter
// CHANGELOG - A.C - 03/11/2022 - VERSION 19 add Sum
// CHANGELOG - Q.C - 14/11/2022 - VERSION 20 add installation characteristics
// CHANGELOG - A.C - 17/11/2022 - VERSION 21 add Analysis
// CHANGELOG - P.H - 18/11/2022 - VERSION 22 update samples
// CHANGELOG - P.H - 22/11/2022 - VERSION 23 add Vle
// CHANGELOG - P.H - 02/12/2022 - VERSION 24 update Project
// CHANGELOG - B.L - 28/11/2022 - VERSION 25 adds default isUpdatedOffline and isStoredOffline to base model
// CHANGELOG - P.H - 09/12/2022 - VERSION 26 update Installation and VLE
// CHANGELOG - P.H - 14/12/2022 - VERSION 27 update Installation and Flow
// CHANGELOG - A.C - 18/11/2022 - VERSION 28 add SampleFlask
// CHANGELOG - B.L - 18/11/2022 - VERSION 29 minor update
// CHANGELOG - B.L - 04/01/2023 - VERSION 30 adds Garbage collextor model
// CHANGELOG - B.L - 11/01/2023 - VERSION 31 adds RecordBook
// CHANGELOG - P.H - 16/01/2023 - VERSION 32 update VLE model
// CHANGELOG - A.C - 10/02/2023 - VERSION 33 update SampleFlask
// CHANGELOG - P.H - 14/02/2023 - VERSION 34 update RecordBook model
// CHANGELOG - A.C - 10/02/2023 - VERSION 35 update SampleFlask
// CHANGELOG - P.H - 27/02/2023 - VERSION 36 update SampleFlask model
// CHANGELOG - P.H - 27/02/2023 - VERSION 37 add OrderForm model
// CHANGELOG - P.H - 06/03/2023 - VERSION 38 update RecordBook model
// CHANGELOG - P.H - 06/03/2023 - VERSION 39 update RecordBook model
// CHANGELOG - P.H - 17/05/2023 - VERSION 40 update Vle model
// CHANGELOG - G.P - 23/05/2023 - VERSION 41 update installation model
// CHANGELOG - G.P - 23/05/2023 - VERSION 42 update installation model
// CHANGELOG - P.H - 26/05/2023 - VERSION 43 update Parameter model
// CHANGELOG - Q.C - 16/05/2023 - VERSION 44 add AdminInformation model
// CHANGELOG - G.P - 23/05/2023 - VERSION 45 Add Constat model
// CHANGELOG - G.P - 30/05/2023 - VERSION 46 added AnalysisResult model
// CHANGELOG - G.P - 27/06/2023 - VERSION 47 added usermanagementUuid for contributor model
// CHANGELOG - B.L - 27/05/2023 - VERSION 48 adds phase signature to record book
// CHANGELOG - B.L - 07/06/2023 - VERSION 49 adds MeasurementForm
// CHANGELOG - G.P - 30/06/2023 - VERSION 50 added AcquisitionRecordBook model
// CHANGELOG - P.H - 19/07/2023 - VERSION 51 update SampleFlask model
// CHANGELOG - B.L - 26/07/2023 - VERSION 52 adds humidity on recordBook
// CHANGELOG - P.H - 08/09/2023 - VERSION 53 update AcquisitionRecordBook model
// CHANGELOG - G.P - 13/09/2023 - VERSION 54 update report model
// CHANGELOG - P.H - 15/09/2023 - VERSION 55 update AcquisitionRecordBook model
// CHANGELOG - G.P - 26/09/2023 - VERSION 56 update AnalysisResult model
// CHANGELOG - P.H - 29/09/2023 - VERSION 57 update Analysis model
// CHANGELOG - P.H - 04/10/2023 - VERSION 58 update MeasurementForm model
// CHANGELOG - P.H - 05/10/2023 - VERSION 59 update AcquisitionBook model
// CHANGELOG - G.P - 16/10/2023 - VERSION 60 update parameter model with qal2_ast boolean field
// CHANGELOG - B.L - 01/07/2024 - VERSION 61 Adds field to installation
// CHANGELOG - B.L - 01/07/2024 - VERSION 62 Adds field to VLE
// CHANGELOG - B.L - 01/07/2024 - VERSION 63 Adds recordForm to recordBook
// CHANGELOG - B.L - 05/11/2024 - VERSION 64 Adds mimetype to Document
