import BaseSchema from "./base";

class ConfigurationLine extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "configurationLines";
  static fields = [
    "uuid",
    "installation",
    "parameter",
    "parameterObject",
    "installationObject",
    "createdAt",
    "updatedAt",
    "underAccreditation",
    "sampleCount",
  ];

  static relatedFields = {
    parameterObject: {
      field: "parameter",
      tableName: "parameters",
    },
    installationObject: {
      field: "installation",
      tableName: "installations",
    },
    vle: {
      reversedKey: "configurationLine",
      tableName: "vles",
      cascade: true,
      blank: true,
    },
  };

  static dateFields = ["createdAt", "updatedAt"];
}

export default ConfigurationLine;
