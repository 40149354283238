import { modelToApiMapping } from "../../api";
import { v4 as uuidv4 } from "uuid";
import db from "@/db";

const innerFuncs = (model, client = null) => {
  if (!client) client = modelToApiMapping[model.tableName];
  const dexieTable = db[model.tableName];

  const innerCreate = async (form, isOfflineState) => {
    let data = null;
    if (!isOfflineState) {
      data = await client.create(form);
    } else {
      data = {
        uuid: uuidv4(),
        createdAt: new Date(),
        updatedAt: new Date(),
        isCreatedOffline: true,
        ...form,
      };
    }
    const dexieUuid = await model.createOrUpdate(data);
    const object = await dexieTable.get(dexieUuid);
    await object.setRelationships();
    return object;
  };

  const innerBulkCreate = async (form, isOfflineState) => {
    let data = null;
    if (!isOfflineState) {
      data = await client.bulkCreate(form);
    } else {
      data = form.map((item) => ({
        uuid: uuidv4(),
        createdAt: new Date(),
        updatedAt: new Date(),
        isCreatedOffline: true,
        ...item,
      }));
    }
    let uuids = [];

    for (const vle of data) {
      const dexieUuid = await model.createOrUpdate(vle);
      uuids.push(dexieUuid);
    }

    const objects = await dexieTable.where("uuid").anyOf(uuids).toArray();

    for (const object of objects) {
      await object.setRelationships();
    }

    return objects;
  };
  const innerUpdate = async (form, isOfflineState) => {
    let data = null;
    if (!isOfflineState) {
      data = await client.update(form);
    } else {
      data = {
        isUpdatedOffline: true,
        ...form,
      };
    }
    const dexieUuid = await model.createOrUpdate(data);
    const object = await dexieTable.get(dexieUuid);
    await object.setRelationships();
    return object;
  };
  const innerBulkUpdate = async (form, isOfflineState) => {
    let data = null;
    if (!isOfflineState) {
      data = await client.bulkUpdate(form);
    } else {
      data = form.map((item) => ({
        uuid: uuidv4(),
        createdAt: new Date(),
        updatedAt: new Date(),
        isCreatedOffline: true,
        ...item,
      }));
    }

    let uuids = [];

    for (const vle of data) {
      const dexieUuid = await model.createOrUpdate(vle);
      uuids.push(dexieUuid);
    }

    const objects = await dexieTable.where("uuid").anyOf(uuids).toArray();

    for (const object of objects) {
      await object.setRelationships();
    }

    return objects;
  };
  const innerFetch = async (filters, isOfflineState, setIsLoading = null) => {
    if (isOfflineState || !window.navigator.onLine) return;

    let resultsList;
    if (setIsLoading) setIsLoading(true);
    try {
      resultsList = await client.list(filters);
    } catch (err) {
      console.log("Error while fetching data");
      return { success: false, error: err };
    }

    try {
      await db.transaction("rw", dexieTable, async () => {
        for (const object of resultsList) {
          model.createOrUpdate(object);
        }
      });
      if (setIsLoading) setIsLoading(false);
      return { success: true, error: null, results: resultsList };
    } catch (err) {
      console.error(err);
      if (setIsLoading) setIsLoading(false);
      return { success: false, error: err };
    }
  };

  const innerDelete = async (
    uuid,
    isOfflineState,
    installationUuid,
    depth = 0
  ) => {
    const obj = await dexieTable.get(uuid);
    const relateds = await obj.getRelatedToDelete();
    dexieTable.delete(uuid);

    if (isOfflineState && !obj.isCreatedOffline) {
      db.garbages.add({
        uuid,
        installationUuid,
        table: model.tableName,
        backup: obj,
      });
      // INFO - B.L - 03/01/2023 - If depth > 0 it means we are in a recusive
      // we don't need to call delete on client as postgres will already cascade delete
    } else if (!depth && !obj.isCreatedOffline) {
      await client.destroy(uuid);
    }
    // INFO - B.L - 05/01/2023 - Cascade deleting all foreign key
    for (const value of Object.values(relateds)) {
      if (Array.isArray(value) && value.length) {
        const subModel = value[0].constructor;
        const subFuncs = innerFuncs(subModel);
        for (const obj of value) {
          subFuncs.innerDelete(
            obj.uuid,
            isOfflineState,
            installationUuid,
            depth + 1
          );
        }
      }
      if (Array.isArray(value) || !value) continue;
      const subModel = value.constructor;
      const subFuncs = innerFuncs(subModel);
      subFuncs.innerDelete(
        value.uuid,
        isOfflineState,
        installationUuid,
        depth + 1
      );
    }
  };

  const innerBulkDelete = async (uuids, isOfflineState, installationUuid) => {
    if (!isOfflineState) {
      await client.bulkDestroy(uuids);
      await dexieTable.where("uuid").anyOf(uuids).delete();
      return;
    }

    for (const uuid of uuids) {
      await innerDelete(uuid, isOfflineState, installationUuid);
    }
  };

  const resyncToBack = async (objects) => {
    let isArray = true;
    if (!Array.isArray(objects)) {
      objects = [objects];
      isArray = false;
    }
    for (const obj of objects) {
      if (obj.isCreatedOffline) {
        const form = await obj.asForm();
        try {
          const data = await client.create(form);
          await dexieTable.update(data.uuid, {
            isStoredOffline: false,
            isUpdatedOffline: false,
            isCreatedOffline: false,
            ...data,
          });
        } catch (err) {
          console.error(err);
        }
      } else if (obj.isUpdatedOffline) {
        const form = await obj.asForm();
        const data = await client.update(form);
        await dexieTable.update(data.uuid, {
          isStoredOffline: false,
          isUpdatedOffline: false,
          ...data,
        });
      } else if (obj.isStoredOffline) {
        dexieTable.update(obj.uuid, { isStoredOffline: false });
      }
    }
    return isArray ? objects : objects;
  };

  const bulkResyncToBack = async (objects) => {
    let createForm = [];
    let updateForm = [];
    for (const obj of objects) {
      if (obj.isCreatedOffline) {
        const form = await obj.asForm();
        createForm.push(form);
      } else if (obj.isUpdatedOffline) {
        const form = await obj.asForm();
        updateForm.push(form);
      }
    }
    const createData = await client.bulkCreate(createForm);
    const updateData = await client.bulkCreate(updateForm);
    for (const data of [...createData, ...updateData]) {
      await dexieTable.update(data.uuid, {
        isStoredOffline: false,
        isUpdatedOffline: false,
        isCreatedOffline: false,
        ...data,
      });
    }
  };

  return {
    innerCreate,
    innerBulkCreate,
    innerUpdate,
    innerBulkUpdate,
    innerFetch,
    innerDelete,
    innerBulkDelete,
    resyncToBack,
    bulkResyncToBack,
  };
};

export default innerFuncs;
