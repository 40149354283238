import BaseSchema from "./base";

class Material extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "materials";
  static fields = [
    "uuid",
    "brand",
    "type",
    "createdAt",
    "updatedAt",
    "name",
    "reference",
    "serialNumber",
    "agencyUuid",
    "businessCategory",
    "matrix",
    "agencyCode",
    "category",
    "expirationDate",
    "calibrationDate",
    "calibrationCertificateNumber",
    "metrologicalParameters",
    "subCategory",
    "analyzerPerformance",
    // INFO - PH - 30/08/2022 - Front specific fields
    "syncUpdatedAt",
    "isLoaded",
    "gamme1",
    "gamme2",
    "gamme3",
    "gamme4",
    "gamme5",
    "gamme6",
    "gamme7",
    "gamme8",
    "gamme9",
    "gamme10",
    "gamme11",
    "gamme12",
    "gamme13",
    "gamme14",
    "gamme15",
    "gamme16",
    "gamme17",
    "gamme18",
    "gamme19",
    "gamme20",
    "gamme21",
    "gamme22",
    "gamme23",
    "gamme24",
    "gamme25",
    "typology",
  ];

  static dateFields = [
    "createdAt",
    "updatedAt",
    "expirationDate",
    "calibrationDate",
  ];

  static async createOrUpdate(data) {
    const obj = await this.formatData(data);
    obj["syncUpdatedAt"] = data.updatedAt;
    return this._createOrUpdate({
      ...obj,
      ...obj.metrologicalParameters,
    });
  }
}

export default Material;
