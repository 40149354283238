import BaseSchema from "./base";

class Client extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "clients";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "code",
    "label",
    "postcode",
    "city",
    "address",
    "telephone",
    "mobile",
    "email",
    "contactCompany",
    "contactFirstName",
    "contactLastName",
    "contactRole",
    "contactPostcode",
    "contactCity",
    "contactAddress",
    "contactTelephone",
    "contactMobile",
    "contactEmail",

    // INFO - B.L - 28/08/2022 - Front specific fields
    "isLoaded",
  ];
  static dateFields = ["createdAt", "updatedAt"];

  static async createOrUpdateFromLister(data) {
    const extractedData = {
      uuid: data.clientUuid,
      code: data.clientCode,
      label: data.rapsoClientLabel || data.clientLabel,
    };
    return this._createOrUpdate(extractedData);
  }
}

export default Client;
