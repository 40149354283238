import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.report_generator.reports;
const grpcClient =
  socioGrpcClient.report_generator.reports
    .ReportTemplateControllerPromiseClient;

export const ReportTemplateClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });

    const request = new requests.ReportTemplateListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);

    return response;
  },
};
