import BaseSchema from "./base";

class Report extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "reports";
  static fields = [
    "uuid",
    "serviceId",
    "objectId",
    "reportName",
    "createdAt",
    "author",
    "template",
    "status",
    "recipients",
    "contributors",
    "chronoNumber",
    "documentUuid",
    "sentDate",
    "version",
    "sheetNumber",
    "hasSentValidation",
    "validators",
    "updatedAt",
    "reportDate",
    "dmsId",
    "documentsGivenToExaminer",
    "authorUuid",
    "batches",
    "oldReportId",
    "installations",
    "revision",
  ];

  static relatedFields = {};

  static dateFields = ["createdAt", "sentDate", "updatedAt", "reportDate"];
}

export default Report;
