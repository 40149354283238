const AdminParameters = () =>
  import(
    /* webpackChunkName: "InstallationParameters" */ "@/components/atmo/admin/partials/AdminParameters.vue"
  );
const AdminInformations = () =>
  import(
    /* webpackChunkName: "InstallationInformations" */ "@/components/atmo/admin/partials/AdminInformations.vue"
  );

const AdminEcarts = () =>
  import(
    /* webpackChunkName: "InstallationParameters" */ "@/components/atmo/admin/partials/AdminEcart.vue"
  );

export default [
  {
    path: "",
    name: "admin",
    redirect: { name: "admin-parameters" },
  },
  {
    path: "parameters",
    name: "admin-parameters",
    component: AdminParameters,
  },
  {
    path: "gaps",
    name: "admin-ecart",
    component: AdminEcarts,
  },
  {
    path: "informations",
    name: "admin-informations",
    component: AdminInformations,
  },
];
