import db from "@/db";
import store from "@/store";

function useinstallationPhotos() {
  const removePhotoFromInstallation = async (installation, photoUuid) => {
    await store.dispatch("installations/updateInstallation", {
      form: {
        ...installation,
        installationPicturesList: installation.installationPicturesList.filter(
          (image) => image !== photoUuid
        ),
      },
      setAsCurrent: true,
    });
  };
  const addPhotoToInstallation = async (installation, photo) => {
    let pictureList = [];
    if (Array.isArray(installation.installationPicturesList)) {
      pictureList = [...installation.installationPicturesList, photo];
    } else {
      pictureList.push(photo);
    }
    await store.dispatch("installations/updateInstallation", {
      form: {
        ...installation,
        installationPicturesList: pictureList,
      },
      setAsCurrent: true,
    });
  };

  const getInstallationIfDocumentIsImage = async (
    documentUuid,
    projectUuid
  ) => {
    const installationsList = await db.installations
      .where({ project: projectUuid })
      .toArray();
    const targetInstallation = installationsList.find((installation) =>
      installation.installationPicturesList.includes(documentUuid)
    );

    return targetInstallation;
  };

  return {
    removePhotoFromInstallation,
    addPhotoToInstallation,
    getInstallationIfDocumentIsImage,
  };
}

export { useinstallationPhotos };
