const ProjectConfigDuplication = () =>
  import(
    /* webpackChunkName: "ProjectConfigDuplication" */ "@/components/atmo/projects/project/config/ProjectConfigDuplication"
  );

export default [
  {
    path: "",
    name: "project-config",
    redirect: { name: "project-config-duplication" },
  },
  {
    path: "duplication",
    name: "project-config-duplication",
    component: ProjectConfigDuplication,
  },
];
