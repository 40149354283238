const IdentityCase = () =>
  import(
    /* webpackChunkName: "IdentityCase" */ "@/components/atmo/projects/project/identity/IdentityCase"
  );

const IdentityRelation = () =>
  import(
    /* webpackChunkName: "IdentityRelation" */ "@/components/atmo/projects/project/identity/IdentityRelation"
  );

export default [
  {
    path: "",
    name: "project-identity",
    redirect: { name: "identity-case" },
  },
  {
    path: "case",
    name: "identity-case",
    component: IdentityCase,
  },
  {
    path: "relation",
    name: "identity-relation",
    component: IdentityRelation,
  },
];
