import db from "@/db";
import { UserClient, UserManagementClient } from "@/api";

const state = {
  currentUser: null,
};

const getters = {
  currentUserEmail: (state) => {
    return state.currentUser?.email;
  },
  currentUser: (state) => {
    return state.currentUser;
  },
  currentUserUuid: (state) => {
    return state.currentUser?.uuid;
  },
  isCurrentUserDeveloper: (state) => {
    return state.currentUser?.isDev();
  },
  isCurrentUserProjectManager: (state, getter, rootState, rootGetters) => {
    return (
      rootGetters["projects/currentProjectManagerEmail"] ===
      state.currentUser?.email
    );
  },
  isCurrentUserSupervisor: (/*state*/) => {
    // INFO - L.L - 14/09/2022 - TODO match with user groups and role
    return true;
  },
  isCurrentUserProjectManagerOrDeveloper: (state, getter) => {
    return (
      getter["isCurrentUserProjectManager"] || getter["isCurrentUserDeveloper"]
    );
  },
};

const actions = {
  async fetchCurrentUser({ commit, rootGetters }) {
    if (!window.navigator.onLine) return;

    const { email } = rootGetters["oidc/oidcUser"];
    const userResponse = await UserClient.retrieveByEmail({
      email,
    });

    const dexieUuid = await db.models.User.createOrUpdate(userResponse);
    const user = await db.users.get(dexieUuid);

    commit("SET_CURRENT_USER", user);

    return user;
  },
  async initializeCurrentUser({ dispatch }) {
    await dispatch("fetchCurrentUser");
    // const agencyResult = await dispatch(
    //   "agencies/fetchAgencyByUserUuid",
    //   user.uuid
    // );
  },
  async retrieveUserByMail(context, email) {
    const user = await UserClient.retrieveByEmail({
      email,
    });

    return user;
  },
  async searchUsers(context, filters) {
    const { resultsList } = await UserManagementClient.list({ filters });

    return resultsList;
  },
  async updateUserProfile(context, { uuid, signature }) {
    const user = await UserClient.updateProfile({ uuid, signature });

    await db.users.update(user.uuid, user);

    return user;
  },
};

const mutations = {
  SET_CURRENT_USER: (state, user) => {
    state.currentUser = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
