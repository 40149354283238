import axios from "axios";

let restDocumentClient = axios.create({
  baseURL: process.env.VUE_APP_U_DOCUMENT_BACK_URL,
  headers: {},
});

const initDocumentAuth = (accessToken) => {
  restDocumentClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default restDocumentClient;
export { restDocumentClient, initDocumentAuth };
