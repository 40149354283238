import { AnalysisClient } from "@/api";
import db from "@/db";

const Analysis = db.models.Analysis;

const SET_IS_LOADING = "SET_IS_LOADING";
const SET_FILTERED_ANALYSIS = "SET_FILTERED_ANALYSIS";
const UPDATE_ANALYSIS = "UPDATE_ANALYSIS";

const state = {
  filteredAnalysis: [],
  analysisIsLoading: false,
};

const getters = {};

const actions = {
  fetchAnalysis: async ({ commit }, filters = {}) => {
    if (!window.navigator.onLine) return;

    commit("SET_IS_LOADING", true);

    const resultsList = await AnalysisClient.list(filters);
    await db.transaction("rw", db.analysis, async () => {
      for (const analysisResponse of resultsList) {
        let indicativeUncertainty = "";
        if (analysisResponse.indicativeUncertainty !== "") {
          if (isNaN(parseFloat(analysisResponse.indicativeUncertainty)))
            indicativeUncertainty = analysisResponse.indicativeUncertainty;
          else
            indicativeUncertainty = (
              parseFloat(
                analysisResponse.indicativeUncertainty.replace(",", ".")
              ) * 100
            ).toFixed();
        }
        Analysis.createOrUpdate({
          ...analysisResponse,
          indicativeUncertainty: indicativeUncertainty,
        });
      }
    });
    commit("SET_IS_LOADING", false);
  },
  setAllAnalysis: async ({ commit }) => {
    const analysisList = await db.analysis.toArray();
    for (let analysis of analysisList) {
      await analysis.setRelationships();
    }
    commit("SET_FILTERED_ANALYSIS", analysisList);
  },
  getParametersAnalyzes: async (_, parametersIds) => {
    const queryset = await db.analysis.filter((item) => {
      const intersectionArray = item.parametersList.filter((paramId) =>
        parametersIds.includes(paramId)
      );
      return intersectionArray.length > 0;
    });

    return queryset.toArray();
  },
  filterAnalysis: async ({ commit }, query) => {
    const { code, name } = query;
    commit("SET_IS_LOADING", true);

    const analysisList = await db.analysis
      .filter((item) => {
        return (
          item.code.toLowerCase().includes(code.toLowerCase()) &&
          item.name.toLowerCase().includes(name.toLowerCase())
        );
      })
      .toArray();
    for (let analysis of analysisList) {
      await analysis.setRelationships();
    }
    commit("SET_FILTERED_ANALYSIS", analysisList);
    commit("SET_IS_LOADING", false);
  },
  async updateAnalysis({ commit }, analysis) {
    const response = await AnalysisClient.update(analysis);
    const dexieUuid = await Analysis.createOrUpdate(response);

    const updatedAnalysis = await db.analysis.get(dexieUuid);
    await updatedAnalysis.setRelationships();
    commit(UPDATE_ANALYSIS, updatedAnalysis);
    return updatedAnalysis;
  },
};

const mutations = {
  [SET_FILTERED_ANALYSIS](state, analysis) {
    state.filteredAnalysis = analysis;
  },
  [SET_IS_LOADING](state, isLoading) {
    state.analysisIsLoading = isLoading;
  },
  [UPDATE_ANALYSIS]: (state, analysis) => {
    const analysisIndex = state.filteredAnalysis.findIndex(
      ({ uuid }) => analysis.uuid === uuid
    );

    if (analysisIndex < 0) return;

    state.filteredAnalysis[analysisIndex] = analysis;
    state.filteredAnalysis = [...state.filteredAnalysis];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
