import BaseSchema from "./base";

class Vle extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "vles";
  static fields = [
    "uuid",
    "configurationLine",
    "sum",
    "createdAt",
    "updatedAt",
    "concentration",
    "concentrationParameter",
    "concentrationExpected",
    "fluxThreshold",
    "flow1",
    "flowUnit1",
    "flowTonProduced1",
    "flow2",
    "flowUnit2",
    "flowTonProduced2",
    "flow3",
    "flowUnit3",
    "flowTonProduced3",
    "flow4",
    "flowUnit4",
    "flowTonProduced4",
    "flowExpected",
    "specificCorrection",
    "samplingDuration",
    "complementary",
    "complementaryUnit",
  ];

  static dateFields = ["createdAt", "updatedAt"];

  static relatedFields = {
    configurationLineObject: {
      field: "configurationLine",
      tableName: "configurationLines",
      blank: true,
    },
    sumObject: {
      field: "sum",
      tableName: "sums",
      blank: true,
    },
    flows: {
      many: true,
      reversedKey: "vle",
      tableName: "flows",
      cascade: true,
    },
  };
}

export default Vle;
