export const SOCOTEC_GROUPS = {
  SOCOTEC_USER: "SocotecUser",
  AGENCY_MANAGER: "AgencyManager",
  AGENCY_GROUP_MANAGER: "AgencyGroupManager",
  NATIONAL_MANAGER: "NationalManager",
  ACTIVITY_DIRECTOR: "ActivityDirector",
  AG_KEY_USER: "AGKeyUser",
  KEY_USER: "KeyUser",
  EXTERNAL_USER: "ExternalUser",
  DEVELOPER: "Developer",
  SOCIO_DEV: "SocioDev",
  SUPER_USER: "SuperUser",
  TECHNICAL_DIRECTION: "TechnicalDirection",
  MATERIAL_MANAGER: "MaterialManager",
};

export const SOCOTEC_EMPLOYEE_GROUPS = [
  SOCOTEC_GROUPS.SOCOTEC_USER,
  SOCOTEC_GROUPS.AGENCY_MANAGER,
  SOCOTEC_GROUPS.AGENCY_GROUP_MANAGER,
  SOCOTEC_GROUPS.NATIONAL_MANAGER,
  SOCOTEC_GROUPS.AG_KEY_USER,
  SOCOTEC_GROUPS.ACTIVITY_DIRECTOR,
  SOCOTEC_GROUPS.TECHNICAL_DIRECTION,
  SOCOTEC_GROUPS.KEY_USER,
  SOCOTEC_GROUPS.SOCIO_DEV,
];

export const MANAGER_GROUPS = [
  SOCOTEC_GROUPS.AGENCY_MANAGER,
  SOCOTEC_GROUPS.AGENCY_GROUP_MANAGER,
  SOCOTEC_GROUPS.NATIONAL_MANAGER,
];

export const EXTRA_MANAGER_GROUPS = [
  SOCOTEC_GROUPS.AG_KEY_USER,
  SOCOTEC_GROUPS.ACTIVITY_DIRECTOR,
  SOCOTEC_GROUPS.KEY_USER,
  SOCOTEC_GROUPS.TECHNICAL_DIRECTION,
];

export const DEVELOPERS_GROUPS = [
  SOCOTEC_GROUPS.DEVELOPER,
  SOCOTEC_GROUPS.SOCIO_DEV,
];

export const NO_ACCESS_GROUPS = [SOCOTEC_GROUPS.EXTERNAL_USER];

export const CI_FRANCE = {
  CT: "CT",
  SPS: "SPS",
  DIAG: "DIAG",
  APB: "APB",
};

export const APP_GROUPS = {
  RAPSOTEC: "RAPSOTEC",
  RAPSO_SPS: "RAPSO_SPS",
  CARCHECK: "CARCHECK",
};
