const analyserRecipe = {
  type: "analyser",
  recordForm: {
    // STEP 1
    parameters: {
      no: null,
      nox: null,
      co: null,
      co2: null,
      o2: null,
      so2: null,
      c3H8: null,
      ch4: null,
    },
    dataAcquisition: null,
    step1Validated: false,

    // STEP 2
    zeroAdjustementParameters: {
      no: null,
      nox: null,
      co: null,
      co2: null,
      o2: null,
      so2: null,
      c3H8: null,
      ch4: null,
    },
    step2Validated: false,
    step1IsLocked: false,

    // STEP 3
    dataCollectionParameters: {
      no: null,
      nox: null,
      co: null,
      co2: null,
      o2: null,
      so2: null,
      c3H8: null,
      ch4: null,
    },
    ratioNo2Nox: null,
    lineCheckDatetime: null,
    step3Validated: false,

    // STEP 4
    samplingStartDatetime: null,
    samplingEndDatetime: null,
    controls: [],

    isValidated: false,
  },
  recordBlocks: [
    {
      label: "INITIALISATION",
      recordEvents: [],
      type: "analyser_general_information_form",
      recordForm: {
        parameters: {
          no: null,
          nox: null,
          co: null,
          co2: null,
          o2: null,
          so2: null,
          c3H8: null,
          ch4: null,
        },
        dataAcquisition: null,
        isValidated: false,
      },
    },
    {
      label: "CONTROL_BEFORE_SAMPLING",
      recordEvents: [],
      type: "analyser_zero_adjustment_form",
      recordForm: {
        zeroAdjustementParameters: {
          no: null,
          nox: null,
          co: null,
          co2: null,
          o2: null,
          so2: null,
          c3H8: null,
          ch4: null,
        },
        isValidated: false,
      },
    },
    {
      label: "LINE_CHECKING_BEFORE_SAMPLING",
      recordEvents: [],
      type: "analyser_data_collection_form",
      recordForm: {
        dataCollectionParameters: {
          no: null,
          nox: null,
          co: null,
          co2: null,
          o2: null,
          so2: null,
          c3H8: null,
          ch4: null,
        },
        ratioNo2Nox: null,
        lineCheckDatetime: null,
        isValidated: false,
      },
    },
    {
      label: "SAMPLING",
      recordEvents: [],
      type: "analyser_control_form",
      recordForm: {
        samplingStartDatetime: null,
        samplingEndDatetime: null,
        controls: [],
      },
    },
  ],
  premix: [
    "recordForm.parameters.no",
    "recordForm.parameters.nox",
    "recordForm.parameters.co",
    "recordForm.parameters.co2",
    "recordForm.parameters.o2",
    "recordForm.parameters.so2",
    "recordForm.parameters.c3H8",
    "recordForm.parameters.ch4",
    "recordForm.dataAcquisition",
    "recordForm.isValidated",
  ],
};

const analyserRecipeMessages = {
  fr: {
    "analyser.block.analyser_general_information_form": "Initialisation",
    "analyser.analyser_general_information_form": "Informations générales",

    "analyser.block.analyser_zero_adjustment_form":
      "Ajustage et contrôle avant échantillonnage",
    "analyser.analyser_zero_adjustment_form": "Ajustage du zéro",

    "analyser.block.analyser_data_collection_form":
      "Vérification de la ligne avant échantillonnage",
    "analyser.analyser_data_collection_form": "Recueil des données",

    "analyser.block.analyser_control_form": "Échantillonnage",
    "analyser.analyser_control_form": "Analyseur",
  },
  en: {
    "analyser.block.analyser_general_information_form": "Initialization",
    "analyser.analyser_general_information_form": "General information",

    "analyser.block.analyser_zero_adjustment_form":
      "Adjustment and control before sampling",
    "analyser.analyser_zero_adjustment_form": "Zero adjustment",

    "analyser.block.analyser_data_collection_form":
      "Line verification before sampling",
    "analyser.analyser_data_collection_form": "Data collection",

    "analyser.block.analyser_control_form": "Sampling",
    "analyser.analyser_control_form": "Analyzer",
  },
};

export { analyserRecipe, analyserRecipeMessages };
