import { ClientClient } from "@/api";
import db from "@/db";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchClientById(context, { clientId }) {
    console.warn(
      "[TODO] fetchProjectById",
      "context",
      context,
      "clientId",
      clientId
    );
    return true;
  },

  async updateContact({ rootGetters }, contact) {
    const { uuid } = contact;
    let data = {};
    if (!rootGetters["projects/isOfflineState"]) {
      data = await ClientClient.update(contact);
    } else {
      data = { isUpdatedOffline: true, ...contact };
    }
    await db.clients.update(uuid, data);
    // dispatch("projects/reloadProject", null, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
