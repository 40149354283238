import { ProjectClient } from "./clients/projectClient";
import { ClientClient } from "./clients/clientClient";
import { ListerClient } from "./clients/listerClient";
import { DocumentClient } from "./clients/documentClient";
import { MaterialClient } from "./clients/materialClient";
import { ContributorClient } from "./clients/contributorClient";
import { RoleClient } from "./clients/roleClient";
import { UserClient } from "./clients/userClient";
import { UserManagementClient } from "./clients/userManagementClient";
import { AgencyClient } from "./clients/agencyClient";
import { PhaseClient } from "./clients/phaseClient";
import { ParameterClient } from "./clients/parameterClient";
import { InstallationClient } from "./clients/installationClient";
import { ConfigurationLineClient } from "./clients/configurationLineClient";
import { SampleClient } from "./clients/sampleClient";
import { SampleFlaskClient } from "./clients/sampleFlaskClient";
import { SumClient } from "./clients/sumClient";
import { ReportClient } from "./clients/reportClient";
import { ReportTemplateClient } from "./clients/reportTemplateClient";
import { VleClient } from "./clients/vleClient";
import { AnalysisClient } from "./clients/analysisClient";
import { FlowClient } from "./clients/flowClient";
import RecordBookClient from "./clients/recordBookClient";
import { OrderFormClient } from "./clients/orderFormClient";
import { AnalysisResultClient } from "./clients/analysisResultClient";
import { ConstatClient } from "./clients/constatClient";
import { AcquisitionRecordBookClient } from "./clients/acquisitionRecordBooksClient";
import MeasurementFormClient from "./clients/measurementFormClient";
import TemplateClient from "./clients/templateClient";

import schemas from "@/db/schemas";
import { AdminInformationClient } from "./clients/adminInformationClient";

const models = schemas.models;
const modelToApiMapping = {
  [models.Project.tableName]: ProjectClient,
  [models.Client.tableName]: ClientClient,
  [models.Document.tableName]: DocumentClient,
  [models.Material.tableName]: MaterialClient,
  [models.Contributor.tableName]: ContributorClient,
  [models.Role.tableName]: RoleClient,
  [models.User.tableName]: UserClient,
  [models.Agency.tableName]: AgencyClient,
  [models.Phase.tableName]: PhaseClient,
  [models.Parameter.tableName]: ParameterClient,
  [models.Installation.tableName]: InstallationClient,
  [models.ConfigurationLine.tableName]: ConfigurationLineClient,
  [models.Sample.tableName]: SampleClient,
  [models.Sum.tableName]: SumClient,
  [models.Report.tableName]: ReportClient,
  [models.Vle.tableName]: VleClient,
  [models.Flow.tableName]: FlowClient,
  [models.SampleFlask.tableName]: SampleFlaskClient,
  [models.RecordBook.tableName]: RecordBookClient,
  [models.OrderForm.tableName]: OrderFormClient,
  [models.AnalysisResult.tableName]: AnalysisResultClient,
  [models.Constat.tableName]: ConstatClient,
  [models.AdminInformation.tableName]: AdminInformationClient,
  [models.AcquisitionRecordBook.tableName]: AcquisitionRecordBookClient,
  [models.MeasurementForm.tableName]: MeasurementFormClient,
};

export {
  ProjectClient,
  ClientClient,
  ListerClient,
  DocumentClient,
  MaterialClient,
  ConstatClient,
  ContributorClient,
  RoleClient,
  UserClient,
  UserManagementClient,
  AgencyClient,
  InstallationClient,
  ConfigurationLineClient,
  SampleClient,
  SampleFlaskClient,
  SumClient,
  PhaseClient,
  ParameterClient,
  ReportClient,
  ReportTemplateClient,
  VleClient,
  FlowClient,
  AnalysisClient,
  modelToApiMapping,
  RecordBookClient,
  OrderFormClient,
  AnalysisResultClient,
  AdminInformationClient,
  AcquisitionRecordBookClient,
  MeasurementFormClient,
  TemplateClient,
};
