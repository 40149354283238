import store from "@/store";
import { VUE_APP_ATMO_SERVICE_ID } from "@/env";
import { multiGuard } from "@/utils/router";

const ReportsList = () =>
  import(
    /* webpackChunkName: "ReportsList" */ "@/components/atmo/projects/project/reports/ReportsList"
  );

const ReportsForm = () =>
  import(
    /* webpackChunkName: "ReportsForm" */ "@/components/atmo/projects/project/reports/ReportsForm"
  );

const fetchReports = async (to, from, next) => {
  store.dispatch("reports/fetchReports", {
    serviceId: VUE_APP_ATMO_SERVICE_ID,
    projectUuid: to.params.projectId,
  });
  next();
};

const fetchDocuments = async (to, from, next) => {
  store.dispatch("documents/fetchDocuments", {
    projectUuid: to.params.projectId,
    query: {},
  });
  next();
};

const retrieveReportGenerationData = async (to, from, next) => {
  store.dispatch("reports/retrieveReportGenerationData", to.params.reportUuid);
  next();
};

export default [
  {
    path: "",
    name: "project-reports",
    component: ReportsList,
    beforeEnter: multiGuard([fetchReports]),
  },
  {
    path: "form/:templateUuid/",
    name: "reports-form",
    component: ReportsForm,
    beforeEnter: multiGuard([fetchDocuments]),
  },
  {
    path: "form/:templateUuid/report/:reportUuid",
    name: "reports-form-edit",
    component: ReportsForm,
    beforeEnter: multiGuard([fetchDocuments, retrieveReportGenerationData]),
  },
];
