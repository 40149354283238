const ContributorsList = () =>
  import(
    /* webpackChunkName: "ContributorsList" */ "@/components/atmo/projects/project/contributors/ContributorsList"
  );

// const fetchRoles = async (to, from, next) => {
//   store.dispatch("roles/fetchRoles", {
//     filters: {
//       service_id: VUE_APP_ATMO_SERVICE_ID,
//     },
//   });
//   next();
// };

// const fetchContributors = async (to, from, next) => {
//   store.dispatch("contributors/fetchContributors", to.params.projectId);
//   next();
// };

// const filterContributors = async (to, from, next) => {
//   store.dispatch("contributors/filterContributors");
//   next();
// };

export default [
  {
    path: "",
    name: "project-contributors",
    component: ContributorsList,
  },
];
