import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import {
  manualSamplingRecipe,
  manualSamplingRecipeMessages,
  getFilterTemperature,
  findFlows,
} from "./manualSamplingRecipe";

import { speedRecipe, speedRecipeMessages } from "./speedRecipe";
import { analyserRecipe, analyserRecipeMessages } from "./analyserRecipe";
import {
  nozzleDiameterRecipe,
  nozzleDiameterRecipeMessages,
} from "./nozzleDiameterRecipe";

import {
  environmentalConditionRecipe,
  environmentalConditionRecipeMessages,
} from "./environmentalConditionRecipe";

import { humidityRecipe, humidityRecipeMessages } from "./humidityRecipe";
import {
  buildMeasurementForm,
  hydrateMeasurementForm,
} from "./measurementForm";
import { flowRecipe, flowRecipeMessages } from "./flowRecipe";

const recordBookTypeToRecipe = {
  manual_sampling: manualSamplingRecipe,
  speed: speedRecipe,
  flow: flowRecipe,
  analyser: analyserRecipe,
  nozzle_diameter: nozzleDiameterRecipe,
  environmental_condition: environmentalConditionRecipe,
  humidity: humidityRecipe,
};

const recordBookTypeToMessages = {
  manual_sampling: manualSamplingRecipeMessages,
  speed: speedRecipeMessages,
  flow: flowRecipeMessages,
  analyser: analyserRecipeMessages,
  nozzle_diameter: nozzleDiameterRecipeMessages,
  environmental_condition: environmentalConditionRecipeMessages,
  humidity: humidityRecipeMessages,
};

const fieldToResetMapping = {
  manual_sampling: {},
  speed: {},
  flow: {},
  analyser: {},
  nozzle_diameter: {},
  environmental_condition: {},
  humidity: {
    isMeasurementStarted: false,
    isMeasurementEnded: false,
    isValidated: false,
  },
};

// INFO - B.L - Used for duplication, reset only values that shouldbn't be duplicated
const cleanForms = (recordBook) => {
  for (const [key, value] of Object.entries(
    fieldToResetMapping[recordBook.type]
  )) {
    _.has(recordBook, `recordForm.${key}`) &&
      _.set(recordBook, `recordForm.${key}`, value);
  }
  return recordBook;
};

const cookRecordBookRecipe = (recordBookType, sourceToDuplicate = null) => {
  let recordBook = null;
  try {
    recordBook = cloneDeep(recordBookTypeToRecipe[recordBookType]);
  } catch (e) {
    throw Error(
      `RecordBook type: ${recordBookType}, is not registered cannot load recipe`
    );
  }
  recordBook.uuid = uuidv4();
  recordBook.createdAt = new Date();
  recordBook.updatedAt = new Date();
  recordBook.recordForm.uuid = uuidv4();
  recordBook.recordForm.createdAt = new Date();
  recordBook.recordForm.updatedAt = new Date();
  recordBook.recordBlocks = recordBook.recordBlocks.map((block) => {
    block.uuid = uuidv4();
    block.createdAt = new Date();
    block.updatedAt = new Date();
    block.recordForm.uuid = uuidv4();
    block.recordForm.createdAt = new Date();
    block.recordForm.updatedAt = new Date();
    return block;
  });
  if (sourceToDuplicate) {
    recordBook = cookPremix(sourceToDuplicate, recordBook);
    recordBook = cleanForms(recordBook);
  }
  return recordBook;
};

const cookPremix = (sourceToDuplicate, recordBook) => {
  recordBook.label = sourceToDuplicate.label;
  recordBook.recordForm.uuid = uuidv4();
  recordBook.premix.forEach((key) => {
    const source = _.get(sourceToDuplicate, key, null);
    if (!source) return;
    if (typeof source === "object") _.set(recordBook, key, cloneDeep(source));
    else _.set(recordBook, key, source);
  });
  return recordBook;
};

const getRecordBookMessages = (recordBookType) => {
  try {
    return recordBookTypeToMessages[recordBookType];
  } catch (e) {
    throw Error(
      `RecordBook type: ${recordBookType}, is not registered cannot load messages`
    );
  }
};

export {
  cookRecordBookRecipe,
  getRecordBookMessages,
  buildMeasurementForm,
  hydrateMeasurementForm,
  getFilterTemperature,
  findFlows,
  cleanForms,
};
