import BaseSchema from "./base";

class Analysis extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "analysis";
  static fields = [
    "uuid",
    "code",
    "name",
    "quantizationLimit",
    "indicativeUncertainty",
    "parametersList",
    "catalogReference",
    "support",
  ];

  static relatedFields = {
    parameters: {
      m2m: true,
      owner: true,
      relatedKey: "parametersList",
      tableName: "parameters",
    },
  };

  static dateFields = [];
}

export default Analysis;
