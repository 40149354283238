import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.SumControllerPromiseClient;

export const SumClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.SumListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);

    return response;
  },
  create: async (sum) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.SumRequest,
      sum
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  update: async (sum) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.SumRequest,
      sum
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
  destroy: async (sumUuid) => {
    const data = {
      uuid: sumUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.SumDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
};
