const speedRecipe = {
  type: "speed",
  recordForm: {
    measuringSide: "length",
    samplingLines: [],
    notAccessibleAxes: [],
    step1Locked: false,
    step1Validated: false,
    temperatureProbe: null,
    pitotTube: null,
    pressureGauge: null,
    step2Validated: false,
    trials: [],
    lackOfGyration: false,
    isValidated: false,
  },
  recordBlocks: [
    {
      label: "INIT",
      recordEvents: [],
      type: "speed_general_informations_form",
      recordForm: {
        measuringSide: "length",
        samplingLines: [],
        isValidated: false,
      },
    },
    {
      label: "MATERIAL",
      recordEvents: [],
      type: "speed_material_form",
      recordForm: {
        temperatureProbe: null,
        pitotTube: null,
        pressureGauge: null,
        isValidated: false,
      },
    },
    {
      label: "SPEED_MEASURE",
      recordEvents: [],
      type: "speed_measurement_form",
      recordForm: {
        trials: [],
        lackOfGyration: false,
        isValidated: false,
      },
    },
  ],
  premix: [
    "recordForm.samplingLines",
    "recordForm.isValidated",
    "recordForm.temperatureProbe",
    "recordForm.pitotTube",
    "recordForm.pressureGauge",
    "recordForm.isValidated",
  ],
};

const speedRecipeMessages = {
  fr: {
    "speed.speed_general_informations_form": "Informations générales",
    "speed.speed_material_form": "Matériel",
    "speed.speed_measurement_form": "Recueil des essais",
    "speed.block.speed_general_informations_form": "Initalisation",
    "speed.block.speed_material_form": "Choix du matériel",
    "speed.block.speed_measurement_form": "Mesure de vitesse",
  },
  en: {
    "speed.speed_general_informations_form": "General information",
    "speed.speed_material_form": "Material",
    "speed.speed_measurement_form": "Test book",
    "speed.block.speed_general_informations_form": "Initialization",
    "speed.block.speed_material_form": "Material choice",
    "speed.block.speed_measurement_form": "Speed measurement",
  },
};

export { speedRecipe, speedRecipeMessages };
