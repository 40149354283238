import { AdminInformationClient } from "@/api";
import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";

const SET_CURRENT_ADMIN_INFORMATIONS = "SET_CURRENT_ADMIN_INFORMATIONS";

const state = {
  currentAdminInformations: null,
};

const { innerCreate, innerUpdate, innerFetch } = crudGenerator(
  db.models.AdminInformation,
  AdminInformationClient
);

const actions = {
  async createAdminInformation({ commit }, form) {
    const adminInformation = await innerCreate(form, !window.navigator.onLine);
    commit(SET_CURRENT_ADMIN_INFORMATIONS, adminInformation);
    return adminInformation;
  },

  async updateAdminInformation({ commit }, form) {
    const adminInformation = await innerUpdate(form, !window.navigator.onLine);
    commit(SET_CURRENT_ADMIN_INFORMATIONS, adminInformation);
    return adminInformation;
  },

  async fetchAdminInformation({ commit }) {
    const filters = {};
    await innerFetch(filters, !window.navigator.onLine);
    const data = await db.adminInformations.toCollection().first();
    commit(SET_CURRENT_ADMIN_INFORMATIONS, data);
  },
};

const mutations = {
  [SET_CURRENT_ADMIN_INFORMATIONS]: (state, adminInformations) => {
    state.currentAdminInformations = adminInformations;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
