import db from "@/db";
import store from "@/store";
import crudGenerator from "@/api/utils/offlineSync";

const {
  innerFetch,
  innerCreate,
  innerUpdate,
  innerBulkCreate,
  innerBulkUpdate,
  innerBulkDelete,
} = crudGenerator(db.models.Flow);

const SET_FLOW_LIST = "SET_FLOW_LIST";
const BULK_ADD_FLOW_LIST = "BULK_ADD_FLOW_LIST";
const BULK_UPDATE_FLOW_LIST = "BULK_UPDATE_FLOW_LIST";
const UPDATE_FLOW_LIST = "UPDATE_FLOW_LIST";
const SET_IS_LOADING = "SET_IS_LOADING";
const BULK_DESTROY_FLOW_LIST = "BULK_DESTROY_FLOW_LIST";

const state = {
  flowList: [],
  flowIsLoading: false,
};

const getters = {};

const actions = {
  async createFlow({ commit, rootGetters }, form) {
    const flow = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    await store.dispatch("installations/refreshLastUpdated");
    commit(UPDATE_FLOW_LIST, flow);
    return flow;
  },

  async bulkCreateFlow({ commit, rootGetters }, form) {
    const flows = await innerBulkCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(BULK_ADD_FLOW_LIST, flows);
    await store.dispatch("installations/refreshLastUpdated");
    return flows;
  },

  async updateFlow({ commit, rootGetters }, form) {
    const flow = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    await store.dispatch("installations/refreshLastUpdated");
    commit(UPDATE_FLOW_LIST, flow);

    return flow;
  },

  async bulkUpdateFlows({ commit, rootGetters }, form) {
    const flows = await innerBulkUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(BULK_UPDATE_FLOW_LIST, flows);
    await store.dispatch("installations/refreshLastUpdated");
    return flows;
  },

  async bulkDestroyFlows({ commit, rootGetters }, flowsUuids) {
    await innerBulkDelete(
      flowsUuids,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    await store.dispatch("installations/refreshLastUpdated");
    commit(BULK_DESTROY_FLOW_LIST, flowsUuids);
  },

  async fetchFlows({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },

  async filterFlow({ state, commit }, vlesUuids) {
    while (state.flowIsLoading) await new Promise((r) => setTimeout(r, 200));
    commit(SET_IS_LOADING, true);

    const flows = await db.flows
      .filter(({ vle }) => vlesUuids.includes(vle))
      .toArray();

    for (const flow of flows) {
      await flow.setRelationships();
    }

    commit(SET_FLOW_LIST, flows);
    commit(SET_IS_LOADING, false);
  },
};

const mutations = {
  [SET_FLOW_LIST]: (state, flows) => {
    state.flowList = [...flows];
  },
  [BULK_ADD_FLOW_LIST]: (state, flows) => {
    state.flowList = [...state.flowList, ...flows];
  },
  [BULK_UPDATE_FLOW_LIST]: (state, flows) => {
    for (const flow of flows) {
      const flowIndex = state.flowList.findIndex(
        ({ uuid }) => flow.uuid === uuid
      );
      state.flowList[flowIndex] = flow;
    }
    state.flowList = [...state.flowList];
  },
  [BULK_DESTROY_FLOW_LIST]: (state, flowsUuids) => {
    const filteredFlowList = state.flowList.filter(
      ({ uuid }) => !flowsUuids.includes(uuid)
    );
    state.flowList = [...filteredFlowList];
  },
  [UPDATE_FLOW_LIST]: (state, flow) => {
    const flowIndex = state.flowList.findIndex(
      ({ uuid }) => flow.uuid === uuid
    );

    if (flowIndex < 0) {
      state.flowList.push(flow);
    } else {
      state.flowList[flowIndex] = flow;
      state.flowList = [...state.flowList];
    }
  },
  [SET_IS_LOADING]: (state, loading) => (state.flowIsLoading = loading),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
