import { ReportTemplateClient } from "@/api";

const SET_REPORT_TEMPLATE_LIST = "SET_REPORT_TEMPLATE_LIST";
const SET_REPORT_TEMPLATE_IS_LOADING = "SET_REPORT_TEMPLATE_IS_LOADING";

const state = {
  reportTemplateList: [],
  reportTemplateIsLoading: false,
};

const getters = {};

const actions = {
  async fetchReportTemplates({ commit }, serviceId) {
    if (!window.navigator.onLine) return;

    commit(SET_REPORT_TEMPLATE_IS_LOADING, true);

    const filters = {
      service_id: serviceId,
    };

    const response = await ReportTemplateClient.list(filters);

    // Info G.P this is for local dev only, remove this later
    commit(SET_REPORT_TEMPLATE_LIST, response);
    commit(SET_REPORT_TEMPLATE_IS_LOADING, false);
  },
};

const mutations = {
  [SET_REPORT_TEMPLATE_LIST]: (state, reportTemplates) => {
    state.reportTemplateList = reportTemplates;
  },
  [SET_REPORT_TEMPLATE_IS_LOADING]: (state, isLoading) => {
    state.reportTemplateIsLoading = isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
