import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import createPersistedState from "vuex-persistedstate";

// Vuex OIDC
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";

// Client
import { initTestRestAuth } from "@/setup/axios";
import { initDocumentAuth } from "@/setup/restDocumentClient";
import { initAtmoAuth } from "@/setup/atmoClient";
import socioGrpcClient from "@/setup/socioGrpcClient";

import {
  Contributor,
  User,
  Agency,
  Role,
  Company,
  AgencyGroup,
  Client,
  Project,
  Case,
  ReportTemplate,
  DocumentType,
  Report,
  Document,
} from "@socotec.io/socio-vue-components";

/************************************ Modules ************************************************/

import { notifications as NotificationsModule } from "@socotec.io/socio-vue-components";
import UsersModule from "@/store/modules/users";
import ProjectsModule from "@/store/modules/projects";
import CasesModule from "@/store/modules/cases";
import ClientsModule from "@/store/modules/clients";
import DocumentsModule from "@/store/modules/documents";
import MaterialModule from "@/store/modules/material";
import ContributorsModule from "@/store/modules/contributors";
import RolesModule from "@/store/modules/roles";
import AgenciesModule from "@/store/modules/agencies";
import ReportsModule from "@/store/modules/reports";
import ReportTemplatesModule from "@/store/modules/reportTemplates";
import InstallationsModule from "@/store/modules/installations";
import ParametersModule from "@/store/modules/parameters";
import ConfigurationLinesModule from "@/store/modules/configurationLines";
import SamplesModule from "@/store/modules/samples";
import SampleFlasksModule from "@/store/modules/sampleFlasks";
import SumModule from "@/store/modules/sum";
import PhasesModule from "@/store/modules/phases";
import AnalysisModule from "@/store/modules/analysis";
import OfflineModule from "@/offline/store";
import VlesModule from "@/store/modules/vles";
import FlowsModule from "@/store/modules/flows";
import RecordBooksModule from "@/store/modules/recordBooks";
import OrderFormModule from "@/store/modules/orderForms";
import AnalysisResultModule from "@/store/modules/analysisResult";
import ConstatsModule from "@/store/modules/constats";
import AdminInformationModule from "@/store/modules/adminInformations";
import AcquisitionRecordBookModule from "@/store/modules/acquisitionRecordBooks";
// import { setTelemetryUser } from "@socotec.io/socio-vue-components";

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(Contributor);
database.register(Client);
database.register(Case);
database.register(Project);
database.register(User);
database.register(Agency);
database.register(AgencyGroup);
database.register(Role);
database.register(Company);
database.register(Report);
database.register(Document);
database.register(ReportTemplate);
database.register(DocumentType);

const modules = [
  "projects",
  "clients",
  "cases",
  "documents",
  "contributors",
  "roles",
  "agencies",
  "reports",
  "installations",
  "configurationLines",
  "samples",
  "sampleFlasks",
  "sum",
  "phases",
  "vles",
  "flows",
  "recordBooks",
  "orderForms",
];

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => {
          initTestRestAuth(user.access_token);
          initDocumentAuth(user.access_token);
          initAtmoAuth(user.access_token);
          socioGrpcClient.setAccessToken(user.access_token);
          // setTelemetryUser(user);
        },
      }
    ),
    users: UsersModule,
    projects: ProjectsModule,
    clients: ClientsModule,
    cases: CasesModule,
    notifications: NotificationsModule,
    documents: DocumentsModule,
    materials: MaterialModule,
    contributors: ContributorsModule,
    roles: RolesModule,
    agencies: AgenciesModule,
    reports: ReportsModule,
    reportTemplates: ReportTemplatesModule,
    installations: InstallationsModule,
    parameters: ParametersModule,
    configurationLines: ConfigurationLinesModule,
    samples: SamplesModule,
    sampleFlasks: SampleFlasksModule,
    sum: SumModule,
    phases: PhasesModule,
    analysis: AnalysisModule,
    offline: OfflineModule,
    vles: VlesModule,
    flows: FlowsModule,
    recordBooks: RecordBooksModule,
    orderForms: OrderFormModule,
    analysisResult: AnalysisResultModule,
    constats: ConstatsModule,
    adminInformations: AdminInformationModule,
    acquisitionRecordBooks: AcquisitionRecordBookModule,
  },
  plugins: [
    VuexORM.install(database),
    createPersistedState({
      paths: ["offlineReports"],
    }),
  ],
});
store.initialStates = {};
(store.setInitialStates = () => {
  modules.map(
    (module_) => (store.initialStates[module_] = { ...store.state[module_] })
  );
}),
  (store.resetStates = async () => {
    modules.map(
      (module_) => (store.state[module_] = { ...store.initialStates[module_] })
    );
  });
export default store;
