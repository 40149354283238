import BaseSchema from "./base";

class Phase extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "phases";
  static fields = [
    "uuid",
    "installation",
    "createdAt",
    "updatedAt",
    "name",
    "order",
    "configurationLinesCount",
  ];

  static relatedFields = {
    samples: {
      many: true,
      reversedKey: "phase",
      tableName: "samples",
      cascade: true,
    },
  };

  static dateFields = ["createdAt", "updatedAt"];
}

export default Phase;
