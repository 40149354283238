import AdministrationChildren from "@/router/atmo/admin/administration/children";
const Administration = () =>
  import(
    /* webpackChunkName: "MaterialsList" */ "@/components/atmo/admin/Administration"
  );

export default [
  {
    path: "",
    component: Administration,
    children: AdministrationChildren,
  },
];
