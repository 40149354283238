import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";
import { OrderFormClient } from "@/api";

const ADD_ORDER_FORM = "ADD_ORDER_FORM";
const UPDATE_TOTAL_ORDER_FORMS = "UPDATE_TOTAL_ORDER_FORMS";
const UPDATE_ORDER_FORM = "UPDATE_ORDER_FORM";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_ORDER_FORMS = "SET_ORDER_FORMS";

const state = {
  orderFormList: [],
  totalOrderForms: 0,
  orderFormIsLoading: false,
};

const getters = {};

const { innerCreate, innerFetch } = crudGenerator(db.models.OrderForm);

const actions = {
  async fetchOrderForms({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },

  async createOrderForm({ commit, rootGetters }, form) {
    const orderForm = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(ADD_ORDER_FORM, orderForm);
    commit(UPDATE_TOTAL_ORDER_FORMS, 1);
    return orderForm;
  },

  async sendOrderForm({ commit }, orderFormUuid) {
    const orderForm = await OrderFormClient.send(orderFormUuid);
    const dexieUuid = await db.models.OrderForm.createOrUpdate(orderForm);
    const orderFormInstance = await db.orderForms.get(dexieUuid);
    await orderFormInstance.setRelationships();
    commit(UPDATE_ORDER_FORM, orderFormInstance);
    return orderFormInstance;
  },

  async setOrderForm({ commit }, projectUuid) {
    commit(SET_IS_LOADING, true);
    const orderForms = await db.orderForms
      .where({ project: projectUuid })
      .toArray();
    commit(SET_ORDER_FORMS, orderForms);
    commit(UPDATE_TOTAL_ORDER_FORMS, orderForms.length);
    commit(SET_IS_LOADING, false);
  },
};

const mutations = {
  [UPDATE_TOTAL_ORDER_FORMS]: (state, amount) => {
    state.totalOrderForms += amount;
  },
  [ADD_ORDER_FORM]: (state, newOrderForm) => {
    state.orderFormList.push(newOrderForm);
  },
  [UPDATE_ORDER_FORM]: (state, orderForm) => {
    const orderFormIndex = state.orderFormList.findIndex(
      ({ uuid }) => orderForm.uuid === uuid
    );

    if (orderFormIndex < 0) {
      state.orderFormList.push(orderForm);
    } else {
      state.orderFormList[orderFormIndex] = orderForm;
      state.orderFormList = [...state.orderFormList];
    }
  },
  [SET_IS_LOADING]: (state, loading) => (state.orderFormIsLoading = loading),
  [SET_ORDER_FORMS]: (state, orderForms) => (state.orderFormList = orderForms),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
