const DocumentsList = () =>
  import(
    /* webpackChunkName: "DocumentsList" */ "@/components/atmo/projects/project/documents/DocumentsList"
  );

export default [
  {
    path: "",
    name: "project-documents",
    component: DocumentsList,
  },
];
