import db from "@/db";
import REPORT_STATUS from "@/db/enums/reportStatus";
import { ReportClient } from "@/api";
import i18n from "@/setup/i18n";
import { snakeToCamel } from "@/utils/utilsGrpcRest";
import { buildQueryset } from "@/utils/queryset";
import dayjs from "dayjs";

const messages = {
  en: {
    reportGenerationFailed:
      "An error occurred while generating the report, please try again",
    reportGenerationSuccessfully: "The report has been successfully generated",
    reportValidationSuccess: "Report '{0}' has been validated successfully",
    reportValidationError: "Error when validate report '{0}'",
  },
  fr: {
    reportGenerationFailed:
      "Une erreur s'est produite pendant la génération du report veuillez réssayer",
    reportGenerationSuccessfully: "Le rapport a été généré",
    reportValidationSuccess: "Le rapport '{0}' a été validé",
    reportValidationError: "Erreur pendant la validation du rapport '{0}'",
  },
};

i18n.mergeLocaleMessage("fr", messages.fr);
i18n.mergeLocaleMessage("en", messages.en);

const Report = db.models.Report;

const SET_REPORT_IS_LOADING = "SET_REPORT_IS_LOADING";
const SET_REPORT_TOTAL = "SET_REPORT_TOTAL";
const SET_REPORT_LIST = "SET_REPORT_LIST";
const UPDATE_REPORT_LIST = "UPDATE_REPORT_LIST";
const UPDATE_REPORT_TOTAL = "UPDATE_REPORT_TOTAL";
const DELETE_REPORT = "DELETE_REPORT";
const SET_CURRENT_GENERATION_DATA = "SET_CURRENT_GENERATION_DATA";
const LOADING_GENERATION_DATA = "LOADING_GENERATION_DATA";
const LOADING_PARAMETER_PREVIEW_DATA = "LOADING_PARAMETER_PREVIEW_DATA";
const PARAMETER_PREVIEW_DATA = "PARAMETER_PREVIEW_DATA";

const state = {
  reportList: [],
  totalReports: 0,
  reportIsLoading: false,
  currentGenerationData: null,
  loadingGenerationData: false,
  loadingPreviewParameter: false,
  parameterPreviewData: {},
};

const getters = {};

const actions = {
  async fetchReports({ commit }, { serviceId, projectUuid }) {
    if (!window.navigator.onLine) return;
    commit(SET_REPORT_IS_LOADING, true);

    let filters = {
      service_id: serviceId,
      object_id: projectUuid,
    };

    const lastReport = await db.reports.orderBy("updatedAt").reverse().first();
    if (lastReport) {
      filters.updated_after = dayjs
        .utc(lastReport.updatedAt)
        .format("YYYY-MM-DD HH:mm:ss.SSS");
    }

    const response = await ReportClient.fullReportList(filters);
    db.transaction("rw", db.reports, async () => {
      for (const report of response) {
        Report.createOrUpdate({
          ...report,
          revision: report.reportGenerationData.report
            ? report.reportGenerationData.report.revision_reason
            : "",
        });
      }
    })
      .then(() => {
        commit(UPDATE_REPORT_TOTAL, response.length);
        commit(SET_REPORT_IS_LOADING, false);
      })
      .catch((error) => {
        commit(SET_REPORT_IS_LOADING, false);
        console.error(error.stack);
      });
  },

  async filterReports(
    { state, commit },
    { queryAsObject, ordering, filterMapping, projectUuid }
  ) {
    while (state.reportIsLoading) await new Promise((r) => setTimeout(r, 200));

    commit(SET_REPORT_IS_LOADING, true);

    const { queryset, count } = await buildQueryset(
      db.reports,
      queryAsObject,
      ordering,
      filterMapping
    );

    const reports = await queryset.toArray();
    const response = reports.filter((report) => report.objectId == projectUuid);

    commit(SET_REPORT_LIST, response);
    commit(SET_REPORT_TOTAL, count);
    commit(SET_REPORT_IS_LOADING, false);
  },

  async createReport({ commit }, report) {
    const stream = await ReportClient.create(report);
    let lastResponse = null;
    let error = null;
    stream.on("error", (res) => {
      error = res.message;
    });
    stream.on("data", async (response) => {
      const report = snakeToCamel(response.getData().toJavaScript());

      const save = async (updateReportTotal = false) => {
        const reportInstanceUuid = await Report.createOrUpdate(report);
        const reportInstance = await db.reports.get(reportInstanceUuid);
        commit(UPDATE_REPORT_LIST, reportInstance);
        if (updateReportTotal) commit(UPDATE_REPORT_TOTAL, 1);
        return reportInstance;
      };

      const actions = {
        [REPORT_STATUS.SUCCESS]: async () => {
          lastResponse = await save();
        },
        [REPORT_STATUS.INVALID_DATA]: () => {
          throw REPORT_STATUS.INVALID_DATA;
        },
        [REPORT_STATUS.UPLOAD_FAILED]: async () => {
          await save();
          throw REPORT_STATUS.UPLOAD_FAILED;
        },
        [REPORT_STATUS.ERROR]: async () => {
          await save();
        },
        [REPORT_STATUS.GENERATING]: async () => {
          await save(true);
        },
      };

      actions[response.getCode()]();
    });

    while (!lastResponse && error === null)
      await new Promise((r) => setTimeout(r, 200));
    if (error !== null) throw new Error(error);

    return lastResponse;
  },
  retrieveReportGenerationData: async ({ commit }, reportUuid) => {
    commit(LOADING_GENERATION_DATA, true);
    const generationData = await ReportClient.retrieveReportGenerationData(
      reportUuid
    );

    commit(SET_CURRENT_GENERATION_DATA, generationData);
    commit(LOADING_GENERATION_DATA, false);
  },
  resetReportGenerationData: ({ commit }) => {
    commit(LOADING_GENERATION_DATA, true);
    commit(SET_CURRENT_GENERATION_DATA, null);
    commit(LOADING_GENERATION_DATA, false);
  },

  destroyReport: async ({ commit }, reportUuid) => {
    await ReportClient.destroy(reportUuid);
    await db.reports.delete(reportUuid);

    commit(DELETE_REPORT, reportUuid);
    commit(UPDATE_REPORT_TOTAL, -1);
  },

  async validateReport({ commit }, report) {
    commit(SET_REPORT_IS_LOADING, true);
    const response = await ReportClient.updateStatus(
      report.uuid,
      REPORT_STATUS.READY
    );
    await Report.createOrUpdate(response);
    commit(UPDATE_REPORT_LIST, response);
    commit(SET_REPORT_IS_LOADING, false);
  },

  async invalidateReport({ commit }, report) {
    commit(SET_REPORT_IS_LOADING, true);
    const response = await ReportClient.updateStatus(
      report.uuid,
      REPORT_STATUS.NOT_VALIDATED
    );
    await Report.createOrUpdate(response);
    commit(UPDATE_REPORT_LIST, response);
    commit(SET_REPORT_IS_LOADING, false);
  },

  async sendReport({ commit }, report) {
    const response = await ReportClient.updateStatus(
      report.uuid,
      REPORT_STATUS.SENT
    );
    await Report.createOrUpdate(response);
    commit(UPDATE_REPORT_LIST, response);
    commit(SET_REPORT_IS_LOADING, false);
  },
  async getFullReport({ commit }, { serviceId, projectUuid, templateId }) {
    if (!window.navigator.onLine) return;
    commit(SET_REPORT_IS_LOADING, true);

    const filters = {
      service_id: serviceId,
      object_id: projectUuid,
      template: templateId,
      page_size: 100,
    };

    const response = await ReportClient.fullReportList(filters);

    commit(SET_REPORT_IS_LOADING, false);
    return response;
  },

  async listReportTemplate({ commit }, { serviceId }) {
    if (!window.navigator.onLine) return;
    commit(SET_REPORT_IS_LOADING, true);

    const filters = {
      service_id: serviceId,
    };

    const response = await ReportClient.listTemplateId(filters);

    commit(SET_REPORT_IS_LOADING, false);
    return response;
  },

  async previewParameter({ commit }, dataToDisplayForm) {
    if (!window.navigator.onLine) return;
    commit(LOADING_PARAMETER_PREVIEW_DATA, true);

    try {
      const response = await ReportClient.queryParameterPreviewData(
        dataToDisplayForm
      );
      commit(PARAMETER_PREVIEW_DATA, response);
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    } finally {
      commit(LOADING_PARAMETER_PREVIEW_DATA, false);
    }
  },
};

const mutations = {
  [SET_REPORT_IS_LOADING]: (state, isLoading) => {
    state.reportIsLoading = isLoading;
  },
  [SET_REPORT_TOTAL]: (state, count) => {
    state.totalReports = count;
  },
  [SET_REPORT_LIST]: (state, reports) => {
    state.reportList = [...reports];
  },
  [UPDATE_REPORT_LIST]: (state, report) => {
    const reportIndex = state.reportList.findIndex(
      ({ uuid }) => report.uuid === uuid
    );

    if (reportIndex < 0) {
      state.reportList.unshift(report);
    } else {
      state.reportList.splice(reportIndex, 1, report);
    }
  },
  [UPDATE_REPORT_TOTAL]: (state, amount) => {
    state.totalReports += amount;
  },
  [DELETE_REPORT](state, reportUuid) {
    state.reportList = state.reportList.filter(
      ({ uuid }) => uuid !== reportUuid
    );
  },
  [SET_CURRENT_GENERATION_DATA](state, data) {
    state.currentGenerationData = data;
  },
  [LOADING_GENERATION_DATA](state, value) {
    state.loadingGenerationData = value;
  },
  [LOADING_PARAMETER_PREVIEW_DATA](state, value) {
    state.loadingPreviewParameter = value;
  },
  [PARAMETER_PREVIEW_DATA](state, value) {
    state.parameterPreviewData = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
