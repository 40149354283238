import Vue from "vue";
import VueI18n from "vue-i18n";
import { utils } from "@socotec.io/socio-vue-components";

Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: utils.globalVuetify.enGlobalVuetify,
  },
  fr: {
    $vuetify: utils.globalVuetify.frGlobalVuetify,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});
