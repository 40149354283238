import BaseSchema from "./base";

class Case extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "cases";
  static fields = [
    "uuid",
    "cloud",
    "createdAt",
    "updatedAt",
    "caseName",
    "caseManagerEmail",
    "caseNumber",
    "caseStatus",
    "caseCity",
    "clientUuid",

    // INFO - B.L - 28/08/2022 - Front specific fields
    "isLoaded",
  ];

  static dateFields = ["createdAt", "updatedAt"];
  static relatedFields = {
    client: { field: "clientUuid", dataName: "client", tableName: "clients" },
  };

  static async createOrUpdateFromLister(data) {
    const extractedData = {
      uuid: data.caseUuid,
      updatedAt: data.caseUpdatedAt,
      caseNumber: data.caseNumber,
      caseName: data.rapsoCaseLabel || data.caseLabel,
      caseStatus: data.caseStatus,
      caseCity: data.RapsoCaseCity || data.caseCity,
      clientUuid: data.clientUuid,
    };
    return this._createOrUpdate(extractedData);
  }

  get status() {
    return this.caseStatus;
  }
  get label() {
    return this.caseName;
  }
}

export default Case;
