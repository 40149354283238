import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.AnalysisControllerPromiseClient;

export const AnalysisClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.AnalysisListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);

    return response;
  },
  update: async (analysis) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.AnalysisRequest,
      analysis
    );
    const response = await grpcClient.update(request, {});

    return response.toObject();
  },
};
