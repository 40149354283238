import BaseSchema from "./base";

class AcquisitionRecordBook extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "acquisitionRecordBooks";
  static fields = [
    "uuid",
    "project",
    "createdAt",
    "updatedAt",
    "label",
    "acquisitionBookCsvUuid",
    "ch4ResponseFactor",
    "analyserBooksList",
    "trialsHours",
    "noGraphUuid",
    "noxGraphUuid",
    "coGraphUuid",
    "co2GraphUuid",
    "o2GraphUuid",
    "so2GraphUuid",
    "c3H8GraphUuid",
    "ch4GraphUuid",
    "temperatureGraphUuid",
    "noSamplesGraphsUuidsList",
    "noxSamplesGraphsUuidsList",
    "coSamplesGraphsUuidsList",
    "co2SamplesGraphsUuidsList",
    "o2SamplesGraphsUuidsList",
    "so2SamplesGraphsUuidsList",
    "c3H8SamplesGraphsUuidsList",
    "ch4SamplesGraphsUuidsList",
    "temperatureSamplesGraphsUuidsList",
    "noSamplesConcentrationsList",
    "noxSamplesConcentrationsList",
    "coSamplesConcentrationsList",
    "co2SamplesConcentrationsList",
    "o2SamplesConcentrationsList",
    "so2SamplesConcentrationsList",
    "c3H8SamplesConcentrationsList",
    "ch4SamplesConcentrationsList",
    "noxConcentration",
    "coConcentration",
    "co2Concentration",
    "o2Concentration",
    "so2Concentration",
    "c3H8Concentration",
    "ch4Concentration",
    "noConcentration",
  ];

  static relatedFields = {
    analyserBooks: {
      m2m: true,
      tableName: "recordBooks",
      relatedKey: "analyserBooksList",
      owner: true,
    },
  };
}

export default AcquisitionRecordBook;
