import { AnalysisResultClient } from "@/api";
import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";

const { innerCreate, innerUpdate } = crudGenerator(db.models.AnalysisResult);

const SET_IS_LOADING = "SET_IS_LOADING";
const SET_ANALYSIS_RESULT = "SET_FILTERED_ANALYSIS";
const UPDATE_ANALYSIS_RESULT = "UPDATE_ANALYSIS";
const ADD_ANALYSIS_RESULT = "ADD_ANALYSIS_RESULT";

const state = {
  analysisResultIsLoading: false,
  analysisResultList: [],
};

const getters = {};

const actions = {
  fetchAnalysisResult: async ({ commit }, projectUuid) => {
    if (!window.navigator.onLine) return;
    const filters = {
      project: projectUuid,
    };
    commit(SET_IS_LOADING, true);
    const resultsList = await AnalysisResultClient.list(filters);
    await db.transaction("rw", db.analysisResult, async () => {
      for (const analysisResultResponse of resultsList) {
        db.models.AnalysisResult.createOrUpdate(analysisResultResponse);
      }
    });
    commit(SET_IS_LOADING, false);
  },

  setAnalysisResult: async ({ commit }, sampleFlaskUuid) => {
    const analysisResults = await db.analysisResult
      .where("sampleFlask")
      .anyOf(sampleFlaskUuid)
      .toArray();
    commit(SET_ANALYSIS_RESULT, analysisResults);
  },

  async createAnalysisResult({ commit, rootGetters }, form) {
    commit(SET_IS_LOADING, true);
    const analysisResult = await innerCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(ADD_ANALYSIS_RESULT, analysisResult);
    commit(SET_IS_LOADING, false);
  },
  updateAnalysisResult: async ({ commit, rootGetters }, form) => {
    commit(SET_IS_LOADING, true);
    const analysisResult = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(UPDATE_ANALYSIS_RESULT, analysisResult);
    commit(SET_IS_LOADING, false);
  },
};

const mutations = {
  [SET_ANALYSIS_RESULT](state, analysisResult) {
    state.analysisResultList = analysisResult;
  },

  [SET_IS_LOADING](state, isLoading) {
    state.analysisResultIsLoading = isLoading;
  },
  [ADD_ANALYSIS_RESULT](state, analysisResult) {
    state.analysisResultList.push(analysisResult);
  },
  [UPDATE_ANALYSIS_RESULT](state, analysisResult) {
    const analysisResultIndex = state.analysisResultList.findIndex(
      ({ uuid }) => analysisResult.uuid === uuid
    );

    if (analysisResultIndex < 0) return;

    state.analysisResultList[analysisResultIndex] = analysisResult;
    state.analysisResultList = [...state.analysisResultList];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
