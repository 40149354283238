import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";
import google_protobuf_struct_pb from "google-protobuf/google/protobuf/struct_pb.js";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.PhaseControllerPromiseClient;

export const PhaseClient = {
  list: async (filters) => {
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.PhaseListRequest();
    const response = await grpcClient.list(request, metadata);

    return response.getResultsList().map((phase) => {
      return {
        ...phase.toObject(),
        configurationLinesCount: phase
          .getConfigurationLinesCount()
          .toJavaScript(),
      };
    });
  },

  create: async (phase) => {
    const configurationLinesCount =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        JSON.parse(JSON.stringify(phase.configurationLinesCount))
      );
    phase.configurationLinesCount = configurationLinesCount;

    const request = socioGrpcClient.javascriptToRequest(
      requests.PhaseRequest,
      phase
    );
    const response = await grpcClient.create(request, {});

    let phaseResponse = response.toObject();
    phaseResponse.configurationLinesCount = response
      .getConfigurationLinesCount()
      .toJavaScript();

    return phaseResponse;
  },

  update: async (phase) => {
    const configurationLinesCount =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        JSON.parse(JSON.stringify(phase.configurationLinesCount))
      );
    phase.configurationLinesCount = configurationLinesCount;

    const request = socioGrpcClient.javascriptToRequest(
      requests.PhaseRequest,
      phase
    );
    const response = await grpcClient.update(request, {});

    let phaseResponse = response.toObject();
    phaseResponse.configurationLinesCount = response
      .getConfigurationLinesCount()
      .toJavaScript();

    return phaseResponse;
  },

  destroy: async (phaseUuid) => {
    const data = {
      uuid: phaseUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.PhaseDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },

  bulkDestroy: async (phasesUuids) => {
    const data = {
      phasesUuids,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.PhaseBulkDestroyRequest,
      data
    );
    const response = await grpcClient.bulkDestroy(request, {});
    return response.toObject();
  },
};
