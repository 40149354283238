import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";
import google_protobuf_struct_pb from "google-protobuf/google/protobuf/struct_pb.js";
import { listAllObjects } from "../utils/listUtils";

const reportGeneratorGrpcClientTemplate =
  socioGrpcClient.report_generator.reports
    .ReportTemplateControllerPromiseClient;

const reportGeneratorRequests = socioGrpcClient.report_generator.reports;
const reportGeneratorGrpcClient =
  socioGrpcClient.report_generator.reports.ReportControllerPromiseClient;

const atmoRequests = socioGrpcClient.atmo_back.reports;
const atmoGrpcClient =
  socioGrpcClient.atmo_back.reports.ReportControllerPromiseClient;

export const ReportClient = {
  list: async (filters) => {
    const metadata = buildMetadata({ filters });
    const request = new reportGeneratorRequests.ReportListRequest();
    const response = await reportGeneratorGrpcClient.utils.listAllObjects(
      request,
      metadata
    );
    return response;
  },
  fullReportList: async (filters) => {
    const metadata = buildMetadata({ filters });
    const request = new reportGeneratorRequests.ReportListRequest();
    const response = await listAllObjects(
      reportGeneratorGrpcClient,
      request,
      metadata,
      "listWithFullData"
    );

    return response.map((res) => {
      return {
        ...res.toObject(),
        reportGenerationData: res.getReportGenerationData().toJavaScript(),
      };
    });
  },

  listTemplateId: async (filters) => {
    const metadata = buildMetadata({ filters });
    const request = new reportGeneratorRequests.ReportTemplateListRequest();
    const reponse =
      await reportGeneratorGrpcClientTemplate.utils.listAllObjects(
        request,
        metadata
      );
    return reponse;
  },
  create: async (report) => {
    if (report.reportData) {
      const reportData = new google_protobuf_struct_pb.Struct.fromJavaScript(
        JSON.parse(JSON.stringify(report.reportData))
      );
      report.reportData = reportData;
    }
    const request = socioGrpcClient.javascriptToRequest(
      atmoRequests.ReportGenerationRequest,
      report
    );
    const stream = await atmoGrpcClient.generate(request, {});
    return stream;
  },
  destroy: async (reportUuid) => {
    const request = new reportGeneratorRequests.ReportDestroyRequest();
    request.setUuid(reportUuid);

    const response = await reportGeneratorGrpcClient.destroy(request, {});
    return response.toObject();
  },

  update: async (report) => {
    const request = socioGrpcClient.javascriptToRequest(
      reportGeneratorRequests.Report,
      report
    );
    const response = await reportGeneratorGrpcClient.update(request, {});

    return response.toObject();
  },
  updateStatus: async (uuid, status) => {
    const request = socioGrpcClient.javascriptToRequest(
      reportGeneratorRequests.ReportUpdateStatusRequest,
      { uuid, status }
    );
    const response = await reportGeneratorGrpcClient.updateStatus(request, {});
    const obj = response.toObject();
    return obj.data;
  },
  retrieveReportGenerationData: async (uuid) => {
    const request = socioGrpcClient.javascriptToRequest(
      reportGeneratorRequests.ReportRetrieveRequest,
      { uuid: uuid }
    );

    const response = await reportGeneratorGrpcClient.retrieve(request, {});
    const struct = new google_protobuf_struct_pb.Struct(
      response.getReportGenerationData()
    );
    return struct.array.toJavaScript();
  },

  queryParameterPreviewData: async (dataToDisplayForm) => {
    const dataToDislayStruct =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        JSON.parse(JSON.stringify(dataToDisplayForm))
      );

    const request = socioGrpcClient.javascriptToRequest(
      atmoRequests.PreviewParameterRequest,
      {
        dataToDisplayForm: dataToDislayStruct,
      }
    );
    const response =
      await socioGrpcClient.atmo_back.reports.PreviewParametersControllerPromiseClient.generate_parameters_prevew(
        request,
        {}
      );
    const struct = new google_protobuf_struct_pb.Struct(response.getResult());

    return struct.array.toJavaScript();
  },
};
