import { choices } from "@/composables/choices/installationChoices";

export default function usePipeSamplingPoints() {
  const diameterFactorsByNumberOfSamplingPointsPerLine = {
    1: [0.5],
    2: [0.146, 0.854],
    4: [0.067, 0.25, 0.75, 0.933],
    6: [0.044, 0.146, 0.296, 0.704, 0.854, 0.956],
    8: [0.033, 0.105, 0.194, 0.323, 0.677, 0.806, 0.895, 0.967],
  };

  const computeSamplingPointsDistanceToWallForCircularPipe = (
    numberOfSamplingPointsPerLine,
    internalDiameter
  ) => {
    // Rounded to 0.000
    return diameterFactorsByNumberOfSamplingPointsPerLine[
      numberOfSamplingPointsPerLine
    ].map((factor) => Math.round(1000 * factor * internalDiameter) / 1000);
  };
  const computeCircularSamplingPoints = (internalDiameter) => {
    const d = internalDiameter;
    let numberOfSamplingPointsPerLine;
    if (d < 0.35) {
      numberOfSamplingPointsPerLine = 1;
      return {
        numberOfSamplingLines: 1,
        distancesBetweenLines: null,
        distancesBetweenPoints: [
          computeSamplingPointsDistanceToWallForCircularPipe(
            numberOfSamplingPointsPerLine,
            d
          ),
        ],
      };
    } else if (d <= 1.1) {
      numberOfSamplingPointsPerLine = 2;
    } else if (d <= 1.6) {
      numberOfSamplingPointsPerLine = 4;
    } else {
      const pipeArea = Math.PI * Math.pow(d / 2, 2);
      if (pipeArea <= 2) {
        numberOfSamplingPointsPerLine = 6;
      } else {
        numberOfSamplingPointsPerLine = 8;
      }
    }

    return {
      numberOfSamplingLines: 2,
      distancesBetweenPoints:
        computeSamplingPointsDistanceToWallForCircularPipe(
          numberOfSamplingPointsPerLine,
          d
        ),
    };
  };

  const computeDistancesForRectangularPipe = (
    numberOfDivisions,
    reference_size
  ) => {
    let distances = [];
    let currentDistance = reference_size / (2 * numberOfDivisions);
    while (currentDistance < reference_size) {
      distances.push(Math.round(1000 * currentDistance) / 1000);
      currentDistance += reference_size / numberOfDivisions;
    }
    return distances;
  };

  const computeMinimumsForRectangularPipe = (pipeArea) => {
    let minimumNumberOfSamplingPoints;
    let minimumNumberOfDivisions;

    if (pipeArea <= 1) {
      minimumNumberOfSamplingPoints = 4;
      minimumNumberOfDivisions = 2;
    } else if (pipeArea <= 2) {
      minimumNumberOfSamplingPoints = 9;
      minimumNumberOfDivisions = 3;
    } else {
      minimumNumberOfSamplingPoints = Math.max(12, Math.ceil(4 * pipeArea));
      minimumNumberOfDivisions = 3;
    }
    return {
      minimumNumberOfSamplingPoints,
      minimumNumberOfDivisions,
    };
  };
  const computeRectangularSamplingPoints = (
    width,
    length,
    measuringSide = "length"
  ) => {
    if (width > length) {
      if (measuringSide == "length") {
        return computeRectangularSamplingPoints(length, width, "width");
      } else {
        return computeRectangularSamplingPoints(length, width, "length");
      }
    }
    const pipeArea = length * width;
    if (pipeArea < 0.1) {
      // Sampling Point is the center of the surface Area
      if (measuringSide == "width") {
        return {
          numberOfSamplingLines: 1,
          distancesBetweenLines: [[0.5 * width]],
          distancesBetweenPoints: [[0.5 * length]],
        };
      } else {
        return {
          numberOfSamplingLines: 1,
          distancesBetweenLines: [[0.5 * length]],
          distancesBetweenPoints: [[0.5 * width]],
        };
      }
    }

    let { minimumNumberOfSamplingPoints, minimumNumberOfDivisions } =
      computeMinimumsForRectangularPipe(pipeArea);
    let numberOfLengthDivisions;
    let numberOfWidthDivisions;
    numberOfLengthDivisions = Math.ceil(
      Math.sqrt(minimumNumberOfSamplingPoints)
    );
    if (length / width <= 2) {
      numberOfWidthDivisions = numberOfLengthDivisions;
    } else {
      if (numberOfLengthDivisions > minimumNumberOfDivisions) {
        numberOfWidthDivisions = numberOfLengthDivisions - 1;
      } else {
        numberOfWidthDivisions = numberOfLengthDivisions;
      }

      let partialSectionLength = length / numberOfLengthDivisions;
      let partialSectionWidth = width / numberOfWidthDivisions;
      while (
        numberOfWidthDivisions * numberOfLengthDivisions <
          minimumNumberOfSamplingPoints ||
        partialSectionLength / partialSectionWidth > 2
      ) {
        if (
          numberOfWidthDivisions * numberOfLengthDivisions <
          minimumNumberOfSamplingPoints
        ) {
          numberOfLengthDivisions++;
        } else if (partialSectionLength / partialSectionWidth > 2) {
          if (numberOfWidthDivisions > minimumNumberOfDivisions) {
            numberOfWidthDivisions--;
          } else {
            numberOfLengthDivisions++;
          }
        }
        partialSectionLength = length / numberOfLengthDivisions;
        partialSectionWidth = width / numberOfWidthDivisions;
      }
    }
    if (measuringSide == "length") {
      return {
        distancesBetweenLines: computeDistancesForRectangularPipe(
          numberOfLengthDivisions,
          length
        ),
        distancesBetweenPoints: computeDistancesForRectangularPipe(
          numberOfWidthDivisions,
          width
        ),
      };
    } else if (measuringSide == "width") {
      return {
        distancesBetweenLines: computeDistancesForRectangularPipe(
          numberOfWidthDivisions,
          width
        ),
        distancesBetweenPoints: computeDistancesForRectangularPipe(
          numberOfLengthDivisions,
          length
        ),
      };
    }
  };

  const computeSamplingPoints = (pipeFeatures, measuringSide = "length") => {
    let samplingLines = [];
    if (pipeFeatures.samplingPointShape === choices.circular) {
      let { numberOfSamplingLines, distancesBetweenPoints } =
        computeCircularSamplingPoints(
          pipeFeatures.samplingPointInternalDiameter
        );
      let nb_iterations = 0;
      while (samplingLines.length < numberOfSamplingLines) {
        nb_iterations++;
        let samplingLine = {};
        samplingLine.name = `Axe n°${nb_iterations}`;
        samplingLine.number = nb_iterations;
        let pointNumber = 1;
        for (let index in distancesBetweenPoints) {
          // Converting to centimeters and rounding to XXX.X cm.
          samplingLine[pointNumber] =
            Math.round(1000 * distancesBetweenPoints[index]) / 10;
          pointNumber++;
        }
        samplingLines.push(samplingLine);
      }
    } else if (pipeFeatures.samplingPointShape === choices.rectangular) {
      let { distancesBetweenLines, distancesBetweenPoints } =
        computeRectangularSamplingPoints(
          pipeFeatures.samplingPointWidth,
          pipeFeatures.samplingPointHeight,
          measuringSide
        );
      for (let lineIndex in distancesBetweenLines) {
        let samplingLine = {};
        let lineNumber = parseInt(lineIndex) + 1;
        // Converting to centimeters and rounding to XXX.X cm.
        samplingLine.name = `Axe n°${lineNumber} (${
          Math.round(1000 * distancesBetweenLines[lineIndex]) / 10
        }) `;
        samplingLine.number = lineNumber;
        let pointNumber = 1;
        for (let index in distancesBetweenPoints) {
          samplingLine[pointNumber] =
            Math.round(1000 * distancesBetweenPoints[index]) / 10;
          pointNumber++;
        }
        samplingLines.push(samplingLine);
      }
    }
    return samplingLines;
  };

  return {
    computeSamplingPoints,
  };
}
