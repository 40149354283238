const InstallationParameters = () =>
  import(
    /* webpackChunkName: "InstallationParameters" */ "@/components/atmo/projects/project/installations/installation/InstallationParameters"
  );

const InstallationSums = () =>
  import(
    /* webpackChunkName: "InstallationSums" */ "@/components/atmo/projects/project/installations/installation/InstallationSums"
  );

const InstallationFlows = () =>
  import(
    /* webpackChunkName: "InstallationFlows" */ "@/components/atmo/projects/project/installations/installation/InstallationFlows"
  );

const InstallationVle = () =>
  import(
    /* webpackChunkName: "InstallationVle" */ "@/components/atmo/projects/project/installations/installation/InstallationVle"
  );

const InstallationPhases = () =>
  import(
    /* webpackChunkName: "InstallationSamples" */ "@/components/atmo/projects/project/installations/installation/InstallationPhases"
  );

export default [
  {
    path: "",
    name: "installation",
    redirect: { name: "installation-parameters" },
  },
  {
    path: "parameters",
    name: "installation-parameters",
    component: InstallationParameters,
  },
  {
    path: "sums",
    name: "installation-sums",
    component: InstallationSums,
  },
  {
    path: "flows",
    name: "installation-flows",
    component: InstallationFlows,
  },
  {
    path: "vle",
    name: "installation-vle",
    component: InstallationVle,
  },
  {
    path: "configuration",
    name: "installation-phase",
    component: InstallationPhases,
  },
];
