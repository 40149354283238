import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.SampleControllerPromiseClient;

export const SampleClient = {
  list: async (filters) => {
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.SampleListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);

    return response;
  },
  retrieve: async (sampleUuid) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleRetrieveRequest,
      { uuid: sampleUuid }
    );
    const response = await grpcClient.retrieve(request, {});

    return response.toObject();
  },
  create: async (sample) => {
    console.log("Called create on Sample Client");
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleRequest,
      sample
    );
    const response = await grpcClient.create(request, {});

    return response.toObject();
  },
  bulkCreate: async (samples) => {
    console.log("Called bulk create on Sample Client");

    samples = samples.map(
      ({ uuid, phase, mainParametersList, secondParameter }) => ({
        uuid,
        phase,
        mainParametersList,
        secondParameter,
      })
    );
    const request = new requests.SampleBulkCreateRequest();

    for (const sample of samples) {
      const sampleRequest = socioGrpcClient.javascriptToRequest(
        requests.SampleRequest,
        sample
      );

      request.addSamples(sampleRequest);
    }

    const response = await grpcClient.bulkCreate(request, {});

    return response.toObject().resultsList;
  },
  bulkUpdate: async (samples) => {
    console.log("Called bulk update on Sample Client");
    const request = new requests.SampleBulkUpdateRequest();

    for (const sample of samples) {
      const sampleRequest = socioGrpcClient.javascriptToRequest(
        requests.SampleRequest,
        sample
      );

      request.addSamples(sampleRequest);
    }

    const response = await grpcClient.bulkUpdate(request, {});

    return response.toObject().resultsList;
  },
  swap: async (sampleUuid) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleSwapRequest,
      { uuid: sampleUuid }
    );
    const response = await grpcClient.swap(request, {});

    return response.toObject();
  },
  update: async (sample) => {
    console.log("Called update on Sample Client");
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleRequest,
      sample
    );
    const response = await grpcClient.update(request, {});

    return response.toObject();
  },
  destroy: async (sampleUuid) => {
    const data = {
      uuid: sampleUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.SampleDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});

    return response.toObject();
  },
};
