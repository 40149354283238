import axios from "axios";

let atmoClient = null;

atmoClient = axios.create({
  baseURL: process.env.VUE_APP_BACK_URL,
  headers: {},
});

const initAtmoAuth = (accessToken) => {
  atmoClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default atmoClient;
export { atmoClient, initAtmoAuth };
