const MaterialsList = () =>
  import(
    /* webpackChunkName: "MaterialsList" */ "@/components/atmo/materials/MaterialsList"
  );

const MaterialsCreate = () =>
  import(
    /* webpackChunkName: "MaterialsForm" */ "@/components/atmo/materials/MaterialsForm"
  );

export default [
  {
    path: "",
    name: "materials",
    component: MaterialsList,
  },
  {
    path: "/create-material/:materialCategory/",
    name: "materials-create",
    component: MaterialsCreate,
  },
  {
    path: "/update-material/:materialUuid/",
    name: "materials-update",
    component: MaterialsCreate,
  },
];
