import socioGrpcClient from "@/setup/socioGrpcClient";
import { buildMetadata } from "@/utils/metadata";
import google_protobuf_struct_pb from "google-protobuf/google/protobuf/struct_pb.js";
import { listAllObjects } from "../utils/listUtils";

const requests = socioGrpcClient.atmo_back.record_books;
const grpcClient =
  socioGrpcClient.atmo_back.record_books.AcquisitionBookControllerPromiseClient;

export const AcquisitionRecordBookClient = {
  list: async (filters) => {
    console.log("Called List on Acquisition record books");
    const metadata = {
      ...buildMetadata({
        filters,
      }),
    };
    const request = new requests.AcquisitionBookListRequest();
    const response = await listAllObjects(grpcClient, request, metadata);

    return response.map((book) => {
      return {
        ...book.toObject(),
        trialsHours: book.getTrialsHours().toJavaScript(),
      };
    });
  },
  create: async (form) => {
    console.log("Called create on Acquisition record books");
    const analyserList = [...form.analysers];
    const trialHoursList = [...form.trialsHours];
    const settings = { ...form.settings };
    form.analysers = [];
    form.trialsHours = [];
    form.settings = null;

    const request = socioGrpcClient.javascriptToRequest(
      requests.AcquisitionBookCreateRequest,
      form
    );

    for (let analyser of analyserList) {
      const analyserRequest = socioGrpcClient.javascriptToRequest(
        requests.AcquisitionBookAnalyserRequest,
        analyser
      );
      request.addAnalysers(analyserRequest);
    }
    const data = new google_protobuf_struct_pb.Struct.fromJavaScript(
      JSON.parse(JSON.stringify(trialHoursList))
    );
    request.setTrialsHours(data);
    const dataSettings = new google_protobuf_struct_pb.Struct.fromJavaScript(
      JSON.parse(JSON.stringify(settings))
    );
    request.setSettings(dataSettings);
    const response = await grpcClient.create(request, {});
    return {
      ...response.toObject(),
      trialsHours: response.getTrialsHours().toJavaScript(),
    };
  },
  destroy: async (uuid) => {
    const data = { uuid };
    const request = socioGrpcClient.javascriptToRequest(
      requests.AcquisitionBookDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
};
