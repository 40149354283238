const humidityRecipe = {
  type: "humidity",
  recordForm: {
    // sonde capacitive
    measurementHour: null,
    humidity: null,
    temperature: null,
    staticPressure: null,
    sondeCapacitive: null,
    // gravimetrie
    balance: null,
    masseEtalon: null,
    // gravimetrie sur paramètre
    manualSampling: null,
    parameter: null,
    sample: null,
    verificationMass: null,
    // gravimetrie sur humidité
    isMeasurementStarted: false,
    isMeasurementEnded: false,
    measurements: [],
    compteur: null,
    // global
    effluentTemperature: 0,
    volumetricContent: null,
    isEffluentWaterSaturated: null,
    isValidated: false,
  },
  recordBlocks: [
    {
      label: "HUMIDITY_MEASUREMENT",
      type: "humidity_measurement_form",
      recordForm: {
        // sonde capacitive
        measurementHour: null,
        humidity: null,
        temperature: null,
        staticPressure: null,
        sondeCapacitive: null,
        // gravimetrie
        balance: null,
        masseEtalon: null,
        // gravimetrie sur paramètre
        manualSampling: null,
        parameter: null,
        sample: null,
        verificationMass: null,
        // gravimetrie sur humidité
        isMeasurementStarted: false,
        isMeasurementEnded: false,
        measurements: [],
        compteur: null,
        // global
        effluentTemperature: 0,
        volumetricContent: null,
        isEffluentWaterSaturated: null,
        isValidated: false,
      },
      recordEvents: [],
    },
  ],
  premix: [
    // sonde capacitive
    "recordForm.measurementHour",
    "recordForm.humidity",
    "recordForm.temperature",
    "recordForm.staticPressure",
    "recordForm.sondeCapacitive",
    // gravimetrie
    "recordForm.balance",
    "recordForm.masseEtalon",
    // gravimetrie sur paramètre
    "recordForm.phase",
    "recordForm.parameter",
    "recordForm.sample",
    "recordForm.verificationMass",
    // gravimetrie sur humidité
    "recordForm.isMeasurementStarted",
    "recordForm.isMeasurementEnded",
    "recordForm.compteur",
    // global
    "recordForm.volumetricContent",
    "recordForm.isEffluentWaterSaturated",
    "recordForm.isValidated",
  ],
};

const humidityRecipeMessages = {
  fr: {
    "humidity.humidity_measurement_form": "Humidité",
    "humidity.block.humidity_measurement_form": "Relevé",
  },
  en: {
    "humidity.humidity_measurement_form": "Humidity",
    "humidity.block.humidity_measurement_form": "Measurment",
  },
};

export { humidityRecipe, humidityRecipeMessages };
