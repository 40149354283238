import { renameKeys } from "@/utils/utilsGrpcRest";

import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.user;
const grpcClient = socioGrpcClient.atmo_back.user.UserControllerPromiseClient;

export const UserClient = {
  retrieve: async ({ uuid }) => {
    const request = new requests.UserRetrieveRequest();
    request.setUuid(uuid);

    const response = await grpcClient.retrieve(request, {});
    if (!response.groupsList) response.groupsList = [];

    const keysMap = { groupsList: "groups" };
    const user = renameKeys(keysMap, response.toObject());

    return user;
  },
  retrieveByEmail: async ({ email }) => {
    const request = new requests.UserRetrieveByEmailRequest();
    request.setEmail(email);

    const response = await grpcClient.retrieveByEmail(request, {});

    const keysMap = { groupsList: "groups" };
    const user = renameKeys(keysMap, response.toObject());

    //INFO - L.L - 13/09/2022 - TODO maybe use atmo-back user.proto ?
    if (!user.groups) user.groups = [];

    return user;
  },
  updateProfile: async ({ uuid, signature }) => {
    const request = new requests.UserPartialUpdateRequest();

    request.setUuid(uuid);
    request.setSignature(signature);
    request.setPartialUpdateFieldsList(["signature"]);

    const response = await grpcClient.partialUpdate(request, {});
    const user = response.toObject();

    return user;
  },
};
