import InstallationChildren from "@/router/atmo/projects/project/installations/installation/children";

const InstallationsList = () =>
  import(
    /* webpackChunkName: "InstallationsList" */ "@/components/atmo/projects/project/installations/InstallationsList"
  );

const Installation = () =>
  import(
    /* webpackChunkName: "Installation" */ "@/components/atmo/projects/project/installations/Installation"
  );

export default [
  {
    path: "",
    name: "project-installations",
    component: InstallationsList,
  },
  {
    path: ":installationUuid",
    component: Installation,
    children: InstallationChildren,
    meta: { subNavs: "installation" },
  },
];
