import BaseSchema from "./base";

class Garbage extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "garbages";
  static fields = ["uuid", "table", "backup", "installationUuid"];

  static getFields() {
    return this.fields;
  }
}

export default Garbage;
