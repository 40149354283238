import db from "@/db";
import { InstallationClient, TemplateClient } from "@/api";
import { buildQueryset } from "@/utils/queryset";
import crudGenerator from "@/api/utils/offlineSync";

const state = {
  installationList: [],
  totalInstallations: 0,
  installationIsLoading: false,
  currentInstallation: null,
  lastUpdated: new Date(),
};

const getters = {
  currentInstallationUuid: (state) => {
    return state.currentInstallation?.uuid;
  },
};

const { innerFetch, innerCreate, innerUpdate, innerDelete } = crudGenerator(
  db.models.Installation
);

const actions = {
  async refreshLastUpdated({ commit }) {
    commit("REFRESH_LAST_UPDATED");
  },
  async setCurrentInstallation({ commit }, installationUuid) {
    let installation = null;
    if (installationUuid) {
      installation = await db.installations.get(installationUuid);
    }
    commit("SET_CURRENT_INSTALLATION", installation);
    return installation;
  },

  async updateInstallation({ commit, rootGetters }, { form, setAsCurrent }) {
    const installation = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit("UPDATE_INSTALLATION", installation);

    if (setAsCurrent) commit("SET_CURRENT_INSTALLATION", installation);
  },

  async fetchInstallations({ commit, rootGetters }, projectUuid) {
    const setLoading = (isLoading) => commit("SET_IS_LOADING", isLoading);
    const filters = {
      project_uuid: projectUuid,
    };
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setLoading
    );
  },

  async filterInstallations(
    { commit, state },
    { queryAsObject, ordering, filterMapping }
  ) {
    while (state.installationIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit("SET_IS_LOADING", true);
    const { queryset, count } = await buildQueryset(
      db.installations,
      queryAsObject,
      ordering,
      filterMapping
    );
    commit("SET_INSTALLATION_LIST", await queryset.toArray());
    commit("SET_TOTAL_INSTALLATIONS", count);
    commit("SET_IS_LOADING", false);
  },

  async createInstallation({ commit, rootGetters }, form) {
    const installation = await innerCreate(
      {
        ...form,
        operatingHoursPerDay: 24,
        operatingDaysPerYear: 365,
      },
      rootGetters["projects/isOfflineState"]
    );
    commit("ADD_INSTALLATION", installation);
    commit("UPDATE_TOTAL_INSTALLATIONS", 1);
    return installation;
  },
  async createInstallationFromTemplate({ commit, rootGetters }, form) {
    const response = await TemplateClient.createFromTemplate(
      rootGetters["projects/currentProjectUuid"],
      form
    );
    const uuid = await db.installations.add(response);
    const installation = await db.installations.get(uuid);
    commit("ADD_INSTALLATION", installation);
    commit("UPDATE_TOTAL_INSTALLATIONS", 1);
    return installation;
  },

  async createTemplateFromInstallation(_, installation) {
    await TemplateClient.createTemplateFromInstallation(installation);
  },

  async setProjectInstallations({ commit, state }, projectId) {
    while (state.installationIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit("SET_IS_LOADING", true);
    const installationList = await db.installations
      .where("project")
      .equals(projectId)
      .toArray();
    commit("SET_INSTALLATION_LIST", installationList);
    commit("SET_TOTAL_INSTALLATIONS", installationList.length);
    commit("SET_IS_LOADING", false);
  },

  async deleteInstallation({ commit, rootGetters }, uuid) {
    await innerDelete(
      uuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    commit("REMOVE_INSTALLATION", uuid);
    commit("UPDATE_TOTAL_INSTALLATIONS", -1);
  },

  async updateFullInstallation(_, installation) {
    if (installation.isUpdatedOffline) {
      const form = await installation.asForm();
      const data = await InstallationClient.update(form);
      const success = await db.installations.update(installation.uuid, data);
      if (success) {
        console.log(`Installation ${installation.uuid} full updated`);
        installation = await db.installations.get(installation.uuid);
        installation.setisUpdatedOffline;
      } else
        console.log(`Installation ${installation.uuid} could not be updated`);
    }
  },
};

const mutations = {
  SET_INSTALLATION_LIST(state, installations) {
    state.installationList = installations;
  },
  SET_IS_LOADING(state, isLoading) {
    state.installationIsLoading = isLoading;
  },
  SET_TOTAL_INSTALLATIONS(state, totalInstallations) {
    state.totalInstallations = totalInstallations;
  },
  UPDATE_TOTAL_INSTALLATIONS(state, amount) {
    state.totalInstallations += amount;
  },
  REMOVE_INSTALLATION: (state, installationUuid) => {
    state.installationList = state.installationList.filter(
      (installation) => installation.uuid !== installationUuid
    );
  },
  ADD_INSTALLATION(state, newInstallation) {
    state.installationList.push(newInstallation);
  },
  UPDATE_INSTALLATION(state, installation) {
    const installationIndex = state.installationList.findIndex(
      ({ uuid }) => uuid === installation.uuid
    );

    if (installationIndex < 0) return;

    state.installationList[installationIndex] = installation;
    state.installationList = [...state.installationList];
  },
  SET_CURRENT_INSTALLATION(state, installation) {
    state.currentInstallation = installation;
  },
  REFRESH_LAST_UPDATED(state) {
    state.lastUpdated = new Date();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
