import { SOCOTEC_GROUPS, DEVELOPERS_GROUPS } from "@/db/enums/userRoles";
import BaseSchema from "./base";

class User extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "users";
  static fields = [
    "uuid",
    "lastLogin",
    "username",
    "firstName",
    "lastName",
    "email",
    "dateJoined",
    "createdAt",
    "updatedAt",
    "lastSsoSync",
    "signature",
    "usermanagementUuid",
    "groups",
  ];

  static relatedFields = {};

  static dateFields = [
    "createdAt",
    "updatedAt",
    "lastLogin",
    "dateJoined",
    "lastSsoSync",
  ];

  isInGroup(groupToCheck) {
    return this.groups.includes(groupToCheck);
  }

  isInGroups(groupsToCheck) {
    return this.groups.some((group) => groupsToCheck.includes(group));
  }

  isDev() {
    return this.isInGroups(DEVELOPERS_GROUPS);
  }

  isTechnicalDirection() {
    return this.isInGroup(SOCOTEC_GROUPS.TECHNICAL_DIRECTION);
  }

  isNationalManager() {
    return this.isInGroup(SOCOTEC_GROUPS.NATIONAL_MANAGER);
  }

  isMaterialManager() {
    return this.isInGroup(SOCOTEC_GROUPS.MATERIAL_MANAGER);
  }

  hasOfflinePermission() {
    return true;
  }
}

export default User;
