const environmentalConditionRecipe = {
  type: "environmental_condition",
  recordForm: {
    interventionDate: null,
    minTemperature: 0,
    maxTemperature: 0,
    atmosphericPressure: 0,
    isValidated: false,
  },
  recordBlocks: [
    {
      label: "ENVIRONMENTAL_CONDITION",
      type: "environmental_condition_form",
      recordForm: {
        interventionDate: null,
        minTemperature: 0,
        maxTemperature: 0,
        atmosphericPressure: 0,
        isValidated: false,
      },
      recordEvents: [],
    },
  ],
  premix: [
    "recordForm.interventionDate",
    "recordForm.minTemperature",
    "recordForm.maxTemperature",
    "recordForm.atmosphericPressure",
    "recordForm.isValidated",
  ],
};

const environmentalConditionRecipeMessages = {
  fr: {
    "environmental_condition.environmental_condition_form":
      "Conditions ambiantes",
    "environmental_condition.block.environmental_condition_form":
      "Informations",
  },
  en: {
    "environmental_condition.environmental_condition_form":
      "Environmental condition",
    "environmental_condition.block.environmental_condition_form":
      "Informations",
  },
};

export { environmentalConditionRecipe, environmentalConditionRecipeMessages };
