import store from "@/store";

import AtmoChildrens from "@/router/atmo/children";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");

const AtmoMobile = () =>
  import(/* webpackChunkName: "AtmoFrontMobile" */ "@/components/AtmoMobile");

const fetchCurrentUser = (from, to, next) => {
  store.dispatch("users/fetchCurrentUser");
  next();
};

export default [
  {
    path: "/",
    component: AtmoMobile,
    children: AtmoChildrens,
    beforeEnter: fetchCurrentUser,
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
];
