import BaseSchema from "./base";
import dayjs from "dayjs";

class Project extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "projects";
  static fields = [
    "uuid",
    // INFO - B.L - 02/03/2023 - NOT THE REAL ID only used for sampleFlask name
    "id",
    "clientUuid",
    "caseUuid",
    "contributorUuid",
    "mission",
    "createdAt",
    "updatedAt",
    "chronorapso",
    "originId",
    "agencyCode",
    "agencyName",
    "workDescription",
    "projectName",
    "postcode",
    "city",
    "address",
    "managerUuid",
    "managerEmail",
    "managerSocotecCode",
    "manager",
    "estimatedDuration",
    "opeStartDate",
    "phase",
    "description",
    "isArchived",
    "vleText",
    "controlTypesList",
    "controlTypes",
    "cofracType",
    "controlObjective",
    "controlStart",
    "controlEnd",
    "isUpdatedByAtmo",
    "measurementPlanValidationDate",
    // INFO - B.L - 28/08/2022 - Front specific fields
    "listerUpdatedAt",
    "isLoaded",
    "clientName",
    "vleComments",
    "complementaryInformation",
    "drealContactName",
    "drealContactFirstName",
    "numS3ic",
    "unannouncedComments",
    "contractNumber",
  ];

  async getManyToOne(value) {
    if (value.reversedKey == "contributors") {
      return await this.db["contributors"]
        .filter((c) => {
          return c.companyProjectRole.projectId === this.uuid;
        })
        .toArray();
    }
    return super.getManyToOne(value);
  }

  static relatedFields = {
    case: { field: "caseUuid", dataName: "pb_case", tableName: "cases" },
    client: { field: "clientUuid", dataName: "client", tableName: "clients" },
    documents: {
      m2m: true,
      reversedKey: "relatedObjectsList",
      tableName: "documents",
    },
    contributors: {
      many: true,
      reversedKey: "contributors",
      tableName: "companyProjectRoles",
    },
    installations: {
      many: true,
      reversedKey: "project",
      tableName: "installations",
    },
    recordBooks: {
      many: true,
      reversedKey: "project",
      tableName: "recordBooks",
    },
    measurementForms: {
      many: true,
      reversedKey: "project",
      tableName: "measurementForms",
    },
  };

  static dateFields = [
    "createdAt",
    "updatedAt",
    "controlStart",
    "controlEnd",
    "measurementPlanValidationDate",
  ];

  static async formatListerData(data) {
    const extractedData = {
      uuid: data.projectUuid,
      projectName: data.rapsoProjectName || data.projectName,
      chronorapso: data.projectChronorapso,
      updatedAt: dayjs(data.projectUpdatedAt).toDate(),
      listerUpdatedAt: dayjs(data.projectUpdatedAt).toDate(),
      isArchived: data.projectIsArchived,
      agencyCode: data.caseAgencyCode,
      clientUuid: data.clientUuid,
      caseUuid: data.caseUuid,
      manager: data.projectManager,
      managerEmail: data.projectManager?.email,
      city: data.rapsoCaseCity || data.caseCity,
      clientName: data.clientLabel,
    };
    return extractedData;
  }
  static async createOrUpdateFromLister(data) {
    const extractedData = await this.formatListerData(data);
    return this._createOrUpdate(extractedData);
  }

  async canBeUpdated() {
    return this?.isStoredOffline || false;
  }

  get caseNumber() {
    if (!this.case) return "";
    return this.case.caseNumber;
  }
}

export default Project;
