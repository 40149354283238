const RecordBookList = () =>
  import(
    /* webpackChunkName: "RecordBook" */ "@/components/atmo/projects/project/recordbooks/RecordBookList"
  );

const AcquisitionList = () =>
  import(
    /* webpackChunkName: "recordBook" */ "@/components/atmo/projects/project/recordbooks/AcquisitionList"
  );

export default [
  {
    path: "",
    name: "project-recordbooks",
    redirect: { name: "recordBookList" },
  },
  {
    path: "recordBookList",
    name: "recordBookList",
    component: RecordBookList,
  },
  {
    path: "acquisitionList",
    name: "acquisitionList",
    component: AcquisitionList,
  },
];
